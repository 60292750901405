import React from "react";
import * as styled from "./styled";
import Button from "../Button";
import { navigate } from "@reach/router";
import useIsMobile from "../../hooks/useIsMobile";

export default ({
  buttonText,
  navigationLink,
}: {
  buttonText: string;
  navigationLink: string;
}) => {
  const isMobile = useIsMobile();

  return (
    <styled.SsoLinkWrapper>
      <styled.SsoLinkDividerWrapper>
        <styled.SsoLinkDividerLine />
        <styled.SsoLinkDividerText>Or</styled.SsoLinkDividerText>
        <styled.SsoLinkDividerLine />
      </styled.SsoLinkDividerWrapper>
      <styled.SsoLoginButtonWrapper isMobile={isMobile}>
        <Button onClick={() => navigate(navigationLink)}>{buttonText}</Button>
      </styled.SsoLoginButtonWrapper>
    </styled.SsoLinkWrapper>
  );
};
