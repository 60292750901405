import React, { ReactNode } from "react";
import * as styled from "./styled";
import { getGroupColor } from "../../services/orderGrouping";

export default ({
  groupName,
  groupNumber,
  children,
}: {
  groupName: string;
  groupNumber: number;
  children: ReactNode;
}) => {
  const color = getGroupColor(groupNumber);
  return (
    <styled.OrderGroupWrapper color={color}>
      <styled.GroupNameBackground>
        <styled.GroupName color={color}>{groupName}</styled.GroupName>
      </styled.GroupNameBackground>
      {children}
    </styled.OrderGroupWrapper>
  );
};
