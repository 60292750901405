import React, { useEffect, useState } from "react";
import { Overlay } from "../../../LayoutComponents/styled";
import * as styled from "../../styled";
import { generateSuccessMessageText } from "../../helpers";
import { OrderDetails } from "../../../../types/OrderDetailsType";
import useIsMobile from "../../../../hooks/useIsMobile";
import CurbsideIcon from "../../../../resources/CurbsideIcon.svg";

const SUCCESS_MESSAGE_TIME_SECONDS = 3;

export default ({
  orderDetails,
  hideOrderDetails,
}: {
  orderDetails: OrderDetails;
  hideOrderDetails: () => void;
}) => {
  const isMobile = useIsMobile();
  const [remainingCountdown, setRemainingCountdown] = useState(
    SUCCESS_MESSAGE_TIME_SECONDS
  );

  useEffect(() => {
    if (remainingCountdown <= 0) {
      hideOrderDetails();
    } else {
      const timerId = setTimeout(
        () => setRemainingCountdown((prevValue) => prevValue - 1),
        1000
      );
      return () => clearTimeout(timerId);
    }
  }, [remainingCountdown, hideOrderDetails]);

  return (
    <>
      <Overlay onClick={() => hideOrderDetails()} zIndex={4} />
      <styled.OrderDetailsWrapper isMobile={isMobile}>
        <styled.OrderDetailsStatusHeader>
          <styled.HeaderText>COMPLETED</styled.HeaderText>
        </styled.OrderDetailsStatusHeader>
        <styled.StatusDescriptionText>
          {generateSuccessMessageText(orderDetails)}
        </styled.StatusDescriptionText>
        <styled.OrderIdText>
          <b>Order ID:</b>
          {orderDetails.orderId}
        </styled.OrderIdText>
        <styled.SuccessMessageText>Great job!</styled.SuccessMessageText>
        <styled.SuccessMessageText>That was fast.</styled.SuccessMessageText>
        <styled.SuccessMessageGraphic src={CurbsideIcon} alt="Shopping Bag" />
        <styled.SuccessMessageTimerText>
          {`Closing in ${remainingCountdown} ${
            remainingCountdown > 1 ? "secs" : "sec"
          }`}
        </styled.SuccessMessageTimerText>
        <styled.ButtonWrapper>
          <styled.BackButton onClick={() => hideOrderDetails()}>
            Back Now
          </styled.BackButton>
        </styled.ButtonWrapper>
      </styled.OrderDetailsWrapper>
    </>
  );
};
