import { useState, useEffect, useCallback } from "react";
import { useOrderStatus } from "./useSharedContext";
import { OrderDetails } from "../types/OrderDetailsType";
import { ExtendedGlobal } from "../types/GlobalExtensionFunctions";

export default (
  currentOrders: OrderDetails[],
  historyOrders: OrderDetails[]
) => {
  const [
    orderDetailsOverlayData,
    setOrderDetailsOverlayData,
  ] = useState<OrderDetails | null>(null);
  const { refreshOrderDetails } = useOrderStatus();
  const [waitingForOrderId, setWaitingForOrderID] = useState("");

  /*
    This function uses the existing orders that are available and opens the modal
    for that order if the orderId is found
   */
  const openOrderDetailsModalSync = useCallback(
    (orderId: string) => {
      let targetOrder = currentOrders.find(
        (order) => order.orderId === orderId
      );
      if (!targetOrder) {
        targetOrder = historyOrders.find((order) => order.orderId === orderId);
      }
      if (
        targetOrder &&
        targetOrder.lastUpdateTime !== orderDetailsOverlayData?.lastUpdateTime
      ) {
        setOrderDetailsOverlayData(targetOrder);
        return true;
      }
      return false;
    },
    [currentOrders, historyOrders, orderDetailsOverlayData]
  );

  useEffect(() => {
    if (waitingForOrderId) {
      openOrderDetailsModalSync(waitingForOrderId);
      setWaitingForOrderID("");
    }
  }, [waitingForOrderId, currentOrders, openOrderDetailsModalSync]);

  /*
    This function updates the current orders first before trying to open the modal
   */
  const performAsyncUpdateThenOpen = async (orderId: string) => {
    await refreshOrderDetails();
    setWaitingForOrderID(orderId);
  };

  // DO NOT CHANGE THIS FUNCTION SIGNATURE OR RETURN TYPE - IT WILL BREAK THE NATIVE APP
  ((global as unknown) as ExtendedGlobal).openOrderDetailsModal = (
    orderId: string
  ) => {
    performAsyncUpdateThenOpen(orderId);
    return true;
  };

  useEffect(() => {
    if (orderDetailsOverlayData) {
      openOrderDetailsModalSync(orderDetailsOverlayData.orderId);
    }
  }, [
    currentOrders,
    orderDetailsOverlayData,
    historyOrders,
    openOrderDetailsModalSync,
  ]);

  return {
    orderDetailsOverlayData,
    setOrderDetailsOverlayData,
  };
};
