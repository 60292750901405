import { getApproximateArrivalTime } from "../../services/orderCalculations";
import { OrderDetails } from "../../types/OrderDetailsType";

const stateIsErrorOrHasEta = (order: OrderDetails, errorStates: string[]) => {
  return errorStates.includes(order.status) || order.eta !== undefined;
};

export const createSortOrdersFunction = (
  showingHistory: boolean,
  errorStates: string[]
) => (orderDetails1: OrderDetails, orderDetails2: OrderDetails) => {
  if (!showingHistory) {
    // Orders with ETA should be placed above orders without ETA
    if (
      stateIsErrorOrHasEta(orderDetails1, errorStates) &&
      !stateIsErrorOrHasEta(orderDetails2, errorStates)
    ) {
      return -1;
    } else if (
      !stateIsErrorOrHasEta(orderDetails1, errorStates) &&
      stateIsErrorOrHasEta(orderDetails2, errorStates)
    ) {
      return 1;
    }
  }

  // Order approximate arrival time is either the expected arrival time
  // or the last update time if it is not receiving updates (eg Error State or Arrived)
  const arrivalTime1 = orderDetails1.eta
    ? getApproximateArrivalTime(orderDetails1.lastUpdateTime, orderDetails1.eta)
    : getApproximateArrivalTime(
        orderDetails1.lastTransitionTime || orderDetails1.lastUpdateTime,
        orderDetails1.eta
      );
  const arrivalTime2 = orderDetails2.eta
    ? getApproximateArrivalTime(orderDetails2.lastUpdateTime, orderDetails2.eta)
    : getApproximateArrivalTime(
        orderDetails2.lastTransitionTime || orderDetails2.lastUpdateTime,
        orderDetails2.eta
      );
  // When showing history we want the most recent (latest eta) orders shown first
  let difference;
  if (showingHistory) {
    difference = arrivalTime2.valueOf() - arrivalTime1.valueOf();
  } else {
    // When showing active orders we want the earliest eta showing first
    difference = arrivalTime1.valueOf() - arrivalTime2.valueOf();
  }
  // If the times are the same then orders with eta greater than should appear after orders with eta less than
  if (difference === 0) {
    if (orderDetails1.etaDirection !== orderDetails2.etaDirection) {
      if (orderDetails1.etaDirection === "lessThan") {
        return -1;
      } else {
        return 1;
      }
    }
  }
  return difference;
};
