import styled from "styled-components";

interface IsMobileProps {
  isMobile: boolean;
}

export const BackgroundPanel = styled.div`
  background-color: ${(props) => props.theme.colors.DARK_BACKGROUND};
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const BackgroundPanelMobile = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  position: relative;
`;

export const SsoLinkWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  width: 35rem;
  height: 35rem;
  padding: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SsoLoginMobilePanel = styled.div`
  margin: auto;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const CenterAlignmentWrapper = styled.main`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SsoLinkText = styled.h1`
  font-size: 1.3rem;
  font-weight: bold;
`;

export const StoreCodeInput = styled.input`
  font-size: 1.4rem;
  background-color: rgb(251, 251, 251);
  border: 1px solid rgb(235, 235, 235);
  font-family: Spartan, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 1rem 1.6rem;
  line-height: normal;
  width: 100%;
  margin-right: 0px;
`;

export const LoadingText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const OneTimeCodeSmallText = styled.h1`
  margin: 3rem 0 2rem 0;
  font-size: 1.3rem;
`;

export const LogoImage = styled.img`
  height: 3rem;
  width: auto;
`;

export const LogoWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FooterWrapper = styled.div`
  color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 35rem;
  margin-top: 1rem;
  text-align: center;

  a {
    color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
    margin-left: 1%;
  }
`;

export const FooterText = styled.p`
  font-size: 0.8rem;
  margin: 0;
`;

export const FooterLink = styled.a`
  font-size: 0.8rem;
`;

export const MobileFooterWrapper = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;

  a {
    color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  }
`;

export const ErrorMessageWrapper = styled.div`
  height: 3rem;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.ERROR};
  text-align: center;
  margin: 0 auto;
  font-weight: 700;
`;

export const ButtonWithErrorWrapper = styled.div<IsMobileProps>`
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    font-size: 1.2rem;
  }

  ${(props) => {
    if (props.isMobile) {
      return `
              width: 100%;
          `;
    }
  }}
`;
