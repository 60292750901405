import styled from "styled-components";

export const LoadingWrapper = styled.div`
  text-align: center;
`;

export const LoadingText = styled.h2`
  margin: auto;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border: ${(props) => props.theme.colors.ERROR} solid 3px;
  border-radius: 0.6rem;
  max-width: 25rem;
  margin: 4rem auto;

  & svg {
    fill: ${(props) => props.theme.colors.ERROR};
    margin: auto 2rem;
  }
`;

export const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.ERROR};
  font-weight: 600;
  width: 75%;
`;
