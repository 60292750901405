import { useEffect, useState } from "react";

export default () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const handleConnectionChange = (event: { type: string }) => {
    if (event.type === "offline") {
      setIsOnline(false);
    }
    if (event.type === "online") {
      setIsOnline(true);
    }
  };

  useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("online", handleConnectionChange);
    };
  }, []);

  return isOnline;
};
