import { useRef } from "react";

export default (arraySize: number) => {
  const focusableRefElements = [];

  for (let i = 0; i < arraySize; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const elementRef = useRef(null);
    const setFocus = () =>
      elementRef.current && (elementRef.current as HTMLInputElement).focus();
    focusableRefElements.push({ elementRef, setFocus });
  }

  return focusableRefElements;
};
