import React from "react";
import * as styled from "./styled";
import Logo from "../../resources/Bluedot_Logo.png";
import useIsMobile from "../../hooks/useIsMobile";

export default () => {
  const isMobile = useIsMobile();

  const pageContent = (
    <>
      <styled.LogoWrapper></styled.LogoWrapper>
      <styled.LogoWrapper>
        <styled.LogoImage src={Logo} title="Bluedot Logo" alt="Bluedot Logo" />
      </styled.LogoWrapper>

      <styled.ErrorPageHeader>
        We couldn't link your device
      </styled.ErrorPageHeader>

      <styled.ErrorPageParagraph>
        Seems like the URL you're using to connect to Hello Screens has expired.
      </styled.ErrorPageParagraph>

      <styled.ErrorPageParagraph>
        Please contact your admin to get a new URL
      </styled.ErrorPageParagraph>
    </>
  );

  if (isMobile) {
    return (
      <styled.BackgroundPanelMobile>
        <styled.OneTimeCodeMobilePanel>
          <styled.CenterAlignmentWrapper>
            {pageContent}
          </styled.CenterAlignmentWrapper>
        </styled.OneTimeCodeMobilePanel>
      </styled.BackgroundPanelMobile>
    );
  }
  return (
    <styled.BackgroundPanel>
      <styled.CenterAlignmentWrapper>
        <styled.OneTimeCodeWrapper>{pageContent}</styled.OneTimeCodeWrapper>
      </styled.CenterAlignmentWrapper>
    </styled.BackgroundPanel>
  );
};
