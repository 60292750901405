import React, { ChangeEvent, KeyboardEvent } from "react";
import useFocusArray from "../../hooks/useFocusArray";
import * as styled from "./styled";

export default ({
  code,
  onUpdateCode,
  codeLength,
  submit,
}: {
  code: string;
  onUpdateCode: React.Dispatch<React.SetStateAction<string>>;
  codeLength: number;
  submit: () => void;
}) => {
  const inputFocusControlArray = useFocusArray(codeLength);

  const onUpdateValue = (
    updateEvent: ChangeEvent<HTMLInputElement>,
    inputIndex: number
  ) => {
    const targetValue = updateEvent?.target?.value;

    // Backspaces are handled by the OnKeyDown event
    if (targetValue.length) {
      onUpdateCode((prevValue: string) => {
        let newValue;
        newValue = prevValue + targetValue;
        newValue = newValue.substring(0, codeLength);
        return newValue;
      });
      const newTargetInputIndex = Math.min(codeLength - 1, inputIndex + 1);
      inputFocusControlArray[newTargetInputIndex].setFocus();
    }
  };

  const onKeyDown = (keyDownEvent: KeyboardEvent<HTMLInputElement>) => {
    if (keyDownEvent.key === "Backspace") {
      onUpdateCode((prevValue: string) => {
        if (prevValue.length) {
          inputFocusControlArray[prevValue.length - 1].setFocus();
          return prevValue.substring(0, prevValue.length - 1);
        }
        return prevValue;
      });
    } else if (keyDownEvent.key === "Enter" && code.length === codeLength) {
      submit();
    }
  };

  const onClickInputField = () => {
    const targetInputIndex =
      code === "" ? 0 : Math.min(codeLength - 1, code.length);
    inputFocusControlArray[targetInputIndex].setFocus();
  };

  const generateInputBoxes = () => {
    const inputBoxes = [];
    for (let i = 0; i < codeLength; i += 1) {
      inputBoxes.push(
        <styled.NumberInput
          id={`position${i + 1}`}
          key={`position${i + 1}`}
          aria-label={`verification code digit ${i + 1}`}
          value={code.length > i ? code.charAt(i) : ""}
          onChange={(event) => onUpdateValue(event, i)}
          ref={inputFocusControlArray[i].elementRef}
          onClick={onClickInputField}
          onKeyDown={onKeyDown}
          className={
            i === code.length || (code.length === 3 && i === 2)
              ? "menu-item"
              : undefined
          }
        />
      );
    }
    return inputBoxes;
  };

  return (
    <styled.CodeInputWrapper>{generateInputBoxes()}</styled.CodeInputWrapper>
  );
};
