import { Random } from "random-js";
import { v4 as uuid } from "uuid";

const randomStringGenerator = new Random();

export const generateRegistrationCode = () => {
  const allowedCharacters = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
  const code = randomStringGenerator.string(7, allowedCharacters);
  console.log(`Generated code: ${code}`);
  return code;
};

export const generateAuthToken = () => {
  return uuid();
};
