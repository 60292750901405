import React from "react";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import OrderCardFieldsDisplay from "../OrderCardFieldsDisplay";
import * as styled from "./styled";
import {
  getOrderArrivalTimeText,
  getOrderStatusText,
} from "../../services/orderCalculations";
import { OrderDetails } from "../../types/OrderDetailsType";
import useIsMobile from "../../hooks/useIsMobile";
import { useStateDefinitions } from "../../hooks/useSharedContext";
import { AlertTimer, StateDefinition } from "../../types/StateDefinitionType";

export interface OrderCardProps {
  orderDetails: OrderDetails;
  openOrderDetailsOverlay: () => void;
  isActive: boolean;
  alertTimer?: AlertTimer;
  orderCardFields: string[] | undefined;
  showDate: boolean;
}

const getCustomerName = (orderDetails: OrderDetails) => {
  if (orderDetails.customerName) {
    return orderDetails.customerName;
  }
  return "-";
};

const getOrderNumber = (orderDetails: OrderDetails) => {
  if (orderDetails.orderId) {
    return orderDetails.orderId;
  }
  return "-";
};

// Show date indicates if the card should be enlarged to show the
// date of the last interaction as well as the time on the order card.
// This is only used for the history page and only used when the view is mobile
// as it can fit without enlarging the space otherwise
export default ({
  orderDetails,
  openOrderDetailsOverlay,
  isActive,
  alertTimer,
  orderCardFields,
  showDate,
}: OrderCardProps) => {
  const isMobile = useIsMobile();
  const { stateDictionary } = useStateDefinitions();
  const targetState = stateDictionary[orderDetails.status] as StateDefinition;

  const approximateArrivalTime = getOrderArrivalTimeText(
    orderDetails,
    targetState.displayState!,
    showDate
  );

  const cardContent = (
    <styled.OrderCardWrapper
      onClick={() => openOrderDetailsOverlay()}
      isActive={isActive}
      role="button"
      tabIndex={0}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          openOrderDetailsOverlay();
        }
      }}
      showDate={showDate && isMobile}
    >
      <styled.OrderStatusEdgeColor color={targetState.displayState!.color} />
      <styled.EtaStatusWrapper>
        <styled.TimeRemainingText className="main-card-text">
          {getOrderStatusText(
            orderDetails,
            isMobile,
            targetState.displayState!,
            alertTimer
          )}
        </styled.TimeRemainingText>
        {(showDate || !isMobile) && (
          <styled.EtaText isMobile={isMobile}>
            {approximateArrivalTime}
          </styled.EtaText>
        )}
      </styled.EtaStatusWrapper>
      <styled.CustomerNameText className="main-card-text">
        {getCustomerName(orderDetails)}
      </styled.CustomerNameText>
      {Boolean(!isMobile && orderCardFields?.length) && (
        <OrderCardFieldsDisplay
          orderCardFields={orderCardFields!}
          hsFields={orderDetails.hsFields}
        />
      )}
      <styled.OrderNumberWrapper>
        <styled.OrderNumberText className="main-card-text">
          {getOrderNumber(orderDetails)}
        </styled.OrderNumberText>
        {!isMobile && (
          <styled.OrderDetailsLink isActive={isActive}>
            <Icon glyph="chevron" title="More details icon" />
          </styled.OrderDetailsLink>
        )}
      </styled.OrderNumberWrapper>
    </styled.OrderCardWrapper>
  );

  if (!orderCardFields?.length) {
    return cardContent;
  }

  if (isMobile) {
    return (
      <styled.MobileWithOrderCardFieldsWrapper
        isActive={true}
        showDate={showDate && isMobile}
      >
        <styled.OrderStatusEdgeColor color={targetState.displayState!.color} />
        <styled.InnerMobileWithOrderCardFieldsWrapper>
          {cardContent}
          <OrderCardFieldsDisplay
            orderCardFields={orderCardFields}
            hsFields={orderDetails.hsFields}
          />
        </styled.InnerMobileWithOrderCardFieldsWrapper>
      </styled.MobileWithOrderCardFieldsWrapper>
    );
  }

  return (
    <styled.OrderCardWithFieldsOverrideWrapper>
      {cardContent}
    </styled.OrderCardWithFieldsOverrideWrapper>
  );
};
