import React, { createContext, ReactNode, useMemo } from "react";
import useOrderStateConfiguration from "../../hooks/useOrderStateConfiguration";
import { StateDefinition, StateDefinitionConfig } from "../../types/StateDefinitionType";

interface OrderStatusContextValue {
  stateDefinitions: StateDefinitionConfig | undefined;
  stateDictionary: Record<string, StateDefinition>;
  stateCategoryMapping: Record<string, string[]>;
  updateStateDefinitions: () => void;
  errorStates: string[];
  stateDefinitionHash?: string;
  stateDefinitionsLoaded: boolean;
}

const defaultProviderValue: OrderStatusContextValue = {
  stateDefinitions: undefined,
  stateDictionary: {},
  stateCategoryMapping: {},
  updateStateDefinitions: () =>
    console.log("Placeholder function for updateStateDefinitions"),
  errorStates: [],
  stateDefinitionHash: undefined,
  stateDefinitionsLoaded: false,
};

const StateDefinitionsContext = createContext(defaultProviderValue);

const StateDefinitionsProvider = ({ children }: { children: ReactNode }) => {
  const {
    stateDefinitions,
    updateStateDefinitions,
    stateDefinitionHash,
    stateDefinitionsLoaded,
  } = useOrderStateConfiguration();

  const stateCategoryMapping = useMemo(() => {
    if (!stateDefinitions) {
      return {};
    }
    const stateCategoryMap: Record<string, string[]> = {};
    for (const category of stateDefinitions.categories) {
      for (const state of stateDefinitions.states) {
        if (state.displayState?.categoryTitle === category) {
          if (stateCategoryMap.hasOwnProperty(category)) {
            stateCategoryMap[category].push(state.name);
          } else {
            stateCategoryMap[category] = [state.name];
          }
        }
      }
    }
    return stateCategoryMap;
  }, [stateDefinitions]);

  const stateDictionary = useMemo(() => {
    if (!stateDefinitions) {
      return {};
    }
    const stateDictionary: Record<string, StateDefinition> = {};
    for (const state of stateDefinitions.states) {
      stateDictionary[state.name] = state;
    }
    return stateDictionary;
  }, [stateDefinitions]);

  const errorStates: string[] = useMemo(() => {
    if (!stateDefinitions) {
      return [];
    }
    const errorStatesArray: string[] = [];
    for (const state of stateDefinitions.states) {
      if (state.isError) {
        errorStatesArray.push(state.name);
      }
    }
    return errorStatesArray;
  }, [stateDefinitions]);

  const providerValue = {
    stateDefinitions,
    updateStateDefinitions,
    stateCategoryMapping,
    errorStates,
    stateDictionary,
    stateDefinitionHash,
    stateDefinitionsLoaded,
  };

  return (
    <StateDefinitionsContext.Provider value={providerValue}>
      {children}
    </StateDefinitionsContext.Provider>
  );
};

export { StateDefinitionsContext, StateDefinitionsProvider };
