import styled, { keyframes } from "styled-components";

// scaling... any units
const rotate = keyframes`
     from {
        transform: rotate(0deg);
     }

     to {
        transform: rotate(360deg);
     }
 `;

const dash = keyframes`
     0% {
       stroke-dasharray: 1, 200;
       stroke-dashoffset: 0;
     }
     50% {
       stroke-dasharray: 89, 200;
       stroke-dashoffset: -35px;
     }
     100% {
       stroke-dasharray: 89, 200;
       stroke-dashoffset: -124px;
     }
 `;

// demo-specific
export const SpinnerWrap = styled.div``;
// end demo-specific

export const Spinner = styled.div`
  position: relative;
  margin: 0 auto;
  width: 20rem;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

export const Circular = styled.svg`
  animation: ${rotate} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const Path = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dash} 1.5s ease-in-out infinite;
  stroke-linecap: round;
`;
