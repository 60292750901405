import { v4 as uuidv4 } from "uuid";
import { OrderDetails } from "../types/OrderDetailsType";
import moment from "moment";

const projectId = uuidv4();

function createGetOrderIdFunction() {
  let orderIdStart = 123456;
  return () => {
    orderIdStart += 1;
    return orderIdStart.toString();
  };
}

const getOrderId = createGetOrderIdFunction();

export const exampleTempoOrder: OrderDetails = {
  installRef: uuidv4(),
  destinationId: "test-destination-id",
  etaDirection: "lessThan",
  eta: 60,
  status: "TempoOnTheWay",
  projectId,
  lastUpdateTime: moment("2013-02-08 09:29").toISOString(),
  lastTransitionTime: moment("2013-02-08 09:29").toISOString(),
  customerName:
    "Hubert Blaine Wolfeschlegelstdfsklnfkldjndfsklnmdfsklndfsklnkdflsndfsklnkldfsnkleinhausenbergerdorff Sr.",
  orderId: "Verylongorderid-teinhausenbergerdorff",
  hsFields: {
    "Mobile Number": "0424663104",
    "Car Model": "Flying Car",
    "Car Color": "Invisible",
    "Car Plate": "GH0ST77",
    "Parking Bay": "3",
    "Mobile Numbers": "0424663104",
    "Car Models": "Flying Car",
    "Car Colors": "Invisible",
    "Car Plates": "GH0ST77",
    "Hubert Blaine Wolfeschlegelstedfnskndfskldfnslkfdsnlkfdsnlkdfsnlkfsinhausenbergerdorff Sr.":
      "Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr.",
    CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq:
      "Red",
    Red:
      "CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
    CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqs:
      "CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
  },
  isAcknowledged: false,
  orderActivationTime: moment("2013-02-08 09:29").toISOString(),
  helloId: uuidv4(),
};

export const exampleWaveOrder: OrderDetails = {
  installRef: uuidv4(),
  destinationId: "test-destination-id",
  status: "OnTheWay",
  projectId,
  lastUpdateTime: moment("2013-02-08 09:30").toISOString(),
  lastTransitionTime: moment("2013-02-08 09:30").toISOString(),
  customerName: "Wave Hamilton",
  orderId: getOrderId(),
  simphonyCheckNumber: "no 7",
  hsFields: {
    "Mobile Number": "0424663104",
    "Car Model": "Flying Car",
    "Car Color": "Invisible",
    "Car Plate": "GH0ST77",
    "Parking Bay": "3",
    "Mobile Numbers": "0424663104",
    "Car Models": "Flying Car",
    "Car Colors": "Invisible",
    "Car Plates": "GH0ST77",
    "Hubert Blaine Wolfeschlegelstedfnskndfskldfnslkfdsnlkfdsnlkdfsnlkfsinhausenbergerdorff Sr.":
      "Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr.",
    CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq:
      "Red",
    Red:
      "CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
    CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqs:
      "CarColorqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
  },
  isAcknowledged: false,
  orderActivationTime: moment("2013-02-08 09:30").toISOString(),
  helloId: uuidv4(),
};

export const mockOrderDetails: OrderDetails[] = [
  exampleTempoOrder,
  {
    ...exampleTempoOrder,
    installRef: uuidv4(),
    eta: 300,
    customerName: "Mary Zappa",
    orderId: getOrderId(),
  },
  {
    ...exampleTempoOrder,
    installRef: uuidv4(),
    eta: 120,
    customerName: "Mary Zappa",
    orderId: getOrderId(),
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group 9",
    },
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 20)
      .toISOString(),
  },
  exampleWaveOrder,
  {
    ...exampleTempoOrder,
    installRef: uuidv4(),
    eta: 60,
    customerName: "Mary Zappa",
    orderId: getOrderId(),
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group 9",
    },
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 20)
      .toISOString(),
  },
  {
    ...exampleTempoOrder,
    installRef: uuidv4(),
    eta: 600,
    etaDirection: "greaterThan",
    customerName: "Herbert Ham",
    orderId: getOrderId(),
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group 8",
    },
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 20)
      .toISOString(),
  },
  {
    ...exampleWaveOrder,
    installRef: uuidv4(),
    status: "Fulfilled",
    customerName: "First Man On The Moon",
    lastUpdateTime: moment().set("hour", 9).set("minute", 20).toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 20).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 20)
      .toISOString(),
    orderId: getOrderId(),
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group 8",
    },
  },
  {
    ...exampleWaveOrder,
    customerName: "Sid Vicious",
    orderId: getOrderId(),
    lastUpdateTime: moment().set("hour", 9).set("minute", 35).toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 35).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 35)
      .toISOString(),
    hsFields: {
      orderGroupId: "Group 1",
    },
  },
  {
    ...exampleWaveOrder,
    installRef: uuidv4(),
    status: "Stopped",
    customerName: "First Man On The Moon",
    lastUpdateTime: moment().set("hour", 9).set("minute", 50).toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 50).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 50)
      .toISOString(),
    orderId: getOrderId(),
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group 7",
    },
  },
  {
    ...exampleWaveOrder,
    installRef: uuidv4(),
    status: "Stopped",
    customerName: "Million Ants",
    lastUpdateTime: moment().set("hour", 9).set("minute", 50).toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 50).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 50)
      .toISOString(),
    orderId: getOrderId(),
    isAcknowledged: true,
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group 7",
    },
  },
  {
    ...exampleTempoOrder,
    installRef: uuidv4(),
    eta: 300,
    status: "LostConnection",
    etaDirection: "lessThan",
    lastUpdateTime: moment().set("hour", 9).set("minute", 40).toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 40).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 40)
      .toISOString(),
    customerName: "Second Man On The Moon",
    orderId: getOrderId(),
    hsFields: {
      orderGroupId: "Group 1",
    },
  },
  {
    ...exampleWaveOrder,
    installRef: uuidv4(),
    status: "Timeout",
    lastUpdateTime: moment().set("hour", 9).set("minute", 37).toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 37).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 37)
      .toISOString(),
    customerName: "Last Man On The Moon",
    orderId: getOrderId(),
    hsFields: {
      orderGroupId: "Group a",
    },
  },
  {
    ...exampleWaveOrder,
    status: "Arrived",
    customerName: "Wave Zambrero",
    orderId: getOrderId(),
    lastUpdateTime: moment()
      .subtract(1, "day")
      .set("hour", 17)
      .set("minute", 35)
      .toISOString(),
    lastTransitionTime: moment()
      .subtract(1, "day")
      .set("hour", 17)
      .set("minute", 35)
      .toISOString(),
    orderActivationTime: moment()
      .subtract(1, "day")
      .set("hour", 17)
      .set("minute", 35)
      .toISOString(),
    isAcknowledged: true,
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group a",
    },
  },
  {
    ...exampleWaveOrder,
    orderId: getOrderId(),
    customerName: "Slow Wave Hamilton",
    status: "Arrived",
    lastUpdateTime: moment().set("hour", 9).set("minute", 35).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 35)
      .toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 35).toISOString(),
    hsFields: {
      ...exampleWaveOrder.hsFields,
      orderGroupId: "Group z",
    },
  },
  {
    ...exampleWaveOrder,
    orderId: getOrderId(),
    customerName: "Jimmy The Eater",
    status: "Fulfilled",
    lastUpdateTime: moment().set("hour", 9).set("minute", 35).toISOString(),
    orderActivationTime: moment()
      .set("hour", 9)
      .set("minute", 35)
      .toISOString(),
    lastTransitionTime: moment().set("hour", 9).set("minute", 35).toISOString(),
    hsFields: {
      orderGroupId: "Group z",
    },
  },
];

export const mockGetOrdersResponse = {
  orderDetails: mockOrderDetails,
  stateHash: "mock-state-hash",
};
