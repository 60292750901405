import React from "react";
import * as styled from "./styled";
import { useNetworkStatus } from "../../hooks/useSharedContext";

export type ConnectionStatus = "Online" | "Unstable" | "Disconnected";

export default () => {
  const { networkStatus } = useNetworkStatus();
  let connectionStatus: ConnectionStatus = "Online";
  let ariaLabel = "Green Circle";
  switch (networkStatus) {
    case "REALTIME":
      connectionStatus = "Online";
      ariaLabel = "Green Circle";
      break;
    case "ONLINE":
      connectionStatus = "Online";
      ariaLabel = "Blue Circle";
      break;
    case "OFFLINE":
      connectionStatus = "Disconnected";
      ariaLabel = "Red Circle";
      break;
    case "UNSTABLE":
      connectionStatus = "Unstable";
      ariaLabel = "Orange Circle";
  }

  return (
    <styled.StatusWrapper
      aria-label="Connection Status Indicator"
      role="figure"
    >
      <styled.StatusTrafficLight
        networkStatus={networkStatus}
        aria-label={ariaLabel}
        role="figure"
      />
      <styled.StatusText networkStatus={networkStatus}>
        {connectionStatus}
      </styled.StatusText>
    </styled.StatusWrapper>
  );
};
