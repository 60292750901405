import React, { useEffect, useState } from "react";
import {
  navigate,
  RouteComponentProps,
  useParams,
  useLocation,
} from "@reach/router";
import queryString from "query-string";
import { deleteAllValues } from "../../services/persistenceManager";
import { registerViaUrlCode } from "../../services/api";
import { isValidRegion, Region } from "../../types/RegionType";
import { useOrderStatus } from "../../hooks/useSharedContext";
import { isValidUrlCode } from "../../services/utils";
import LoadingPage from "../LoadingPage";
import UrlLinkingFailed from "../UrlLinkingFailed/UrlLinkingFailed";

const UrlLinker = (props: RouteComponentProps) => {
  const urlParams = useParams();
  const location = useLocation();
  const {
    loginSuccessful,
    loadingFirstRequest,
    refreshOrderDetails,
  } = useOrderStatus();
  const [registrationInProgress, setRegistrationInProgress] = useState(false);
  const [registrationError, setRegistrationError] = useState<
    undefined | Error
  >();

  useEffect(() => {
    document.title = "Hello Screens - One Time Code";
  }, []);

  // Set region based on url params and local storage
  // If not token generated and region selected then register new codes
  useEffect(() => {
    const handleRegisterCode = async () => {
      setRegistrationInProgress(true);
      const region = urlParams.region as Region;
      const urlCode = urlParams.urlCode;
      try {
        await registerViaUrlCode(urlCode, region);
        await refreshOrderDetails();
        return;
      } catch (error) {
        setRegistrationError(error as Error);
      }
      setRegistrationInProgress(false);
    };

    if (
      !isValidRegion(urlParams.region) ||
      !isValidUrlCode(urlParams.urlCode)
    ) {
      navigate("/");
      return;
    }

    if (location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.loadAction === "logout") {
        deleteAllValues();
        navigate(`/${urlParams.region}/${urlParams.urlCode}`);
        return;
      }
    }

    if (loginSuccessful) {
      navigate("/active");
      return;
    }

    if (!loadingFirstRequest && !registrationInProgress && !registrationError) {
      handleRegisterCode();
    }
  }, [
    loginSuccessful,
    loadingFirstRequest,
    urlParams.region,
    urlParams.urlCode,
    registrationError,
    registrationInProgress,
    refreshOrderDetails,
    location.search,
  ]);

  if (registrationError) {
    return <UrlLinkingFailed />;
  }

  return <LoadingPage />;
};

export default UrlLinker;
