import styled from "styled-components";

interface OverlayProps {
  zIndex: number
}

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: ${(props) => props.zIndex};
  background-color: black;
  opacity: 0.5;
`;
