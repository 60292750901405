import styled from "styled-components";

export const CodeInputWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 1rem;

  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const NumberInput = styled.input`
  height: 4rem;
  width: 4rem;
  margin: 0.5rem;
  text-align: center;
  font-size: 2rem;
`;
