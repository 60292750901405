import styled from "styled-components";

export const MobileOrderCardFields = styled.div`
  width: 95%;
  display: flex;
  padding: 0.5rem 0;
  margin: 0 auto;
  border-top: solid 2px ${(props) => props.theme.colors.BORDER_COLOR};
  justify-content: space-between;

  & p {
    font-size: 0.8rem;
  }
`;

export const InlineOrderCardFields = styled.div`
  width: 31%;
  height: 100%;
  padding: 1rem 0;
`;

export const InlineOrderCardFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InlineOrderCardFieldWrapper = styled.div`
  width: 48%;
  margin: 0.1rem 0;
  background-color: ${(props) => props.theme.colors.DARKER_NEUTRAL_PANEL};
  border-radius: 3px;
`;

export const MobileOrderCardFieldsWrapper = styled(InlineOrderCardFieldWrapper)`
  width: 24%;
`;

export const InlineOrderCardFieldPlaceholder = styled.div`
  width: 48%;
  margin: 0.1rem 0;
`;

export const MobileOrderCardFieldsPlaceholder = styled(
  InlineOrderCardFieldPlaceholder
)`
  width: 23%;
`;

export const OrderCardFieldText = styled.p`
  margin: 0.2rem;
  text-align: center;
  font-weight: 800;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
