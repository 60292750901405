import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants/layout";
import { IsMobileProps } from "../../types/sharedComponentProps";

export const ScreenWrapper = styled.main`
  height: calc(100vh - ${HEADER_HEIGHT} - 4rem);
  background-color: ${(props) => props.theme.colors.NEUTRAL_PANEL};
  margin-top: 2rem;
  padding: 1% 3%;
  overflow: auto;
`;

export const RightTopBar = styled.div<IsMobileProps>`
  justify-content: space-between;
  display: flex;
  ${(props) => {
    if (!props.isMobile) {
      return `
              width: 55%;
              min-width: 22rem;
          `;
    }
  }}
`;

export const MiddleTopBar = styled.div<IsMobileProps>`
  text-align: center;
  ${(props) => {
    if (!props.isMobile) {
      return `
              width: 50%;
          `;
    }
  }}
`;

export const TopBar = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActiveOrdersWrapper = styled.div<IsMobileProps>`
  display: inline-flex;
  font-size: 1.25rem;
  margin-right: 2rem;
`;

export const ActiveOrdersNumber = styled.p`
  margin: 0;
  margin-right: 1rem;
  font-weight: 600;
`;

export const ActiveOrdersText = styled.p`
  margin: 0;
`;
