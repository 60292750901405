import React, { useEffect, useState, useCallback } from "react";
import { navigate, useParams, RouteComponentProps } from "@reach/router";
import { POLLING_INTERVAL } from "../../constants/intervals";
import Logo from "../../resources/Bluedot_Logo.png";
import Button from "../../components/Button";
import RegionSelector from "../../components/RegionSelector";
import SsoLoginLink from "../../components/SsoLoginLink";
import * as styled from "./styled";
import * as api from "../../services/api";
import useIsMobile from "../../hooks/useIsMobile";
import * as persistenceManager from "../../services/persistenceManager";
import { isValidRegion, Region } from "../../types/RegionType";
import { useConfirmationModal } from "../../hooks/useSharedContext";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";

export default (props: RouteComponentProps) => {
  const isMobile = useIsMobile();
  const [registrationCode, setRegistrationCode] = useState<
    string | undefined
  >();
  const [error, setError] = useState<undefined | Error>();
  const [, setLoginAttempts] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState<Region | undefined>();
  const urlParams = useParams();
  const showConfirmationModal = useConfirmationModal();
  const { hideMainContent } = useAriaHideMainContent();

  const saveNewRegistrationCode = useCallback(
    async (region: Region) => {
      try {
        const newRegistrationCode = await api.registerNewCode(region);
        setRegistrationCode(newRegistrationCode);
      } catch (error) {
        setError(error as Error);
      }
    },
    [setRegistrationCode, setError]
  );

  // When new region is selected generate new code
  useEffect(() => {
    let storedRegion = persistenceManager.getRegion();

    if (selectedRegion && selectedRegion !== storedRegion) {
      persistenceManager.setRegion(selectedRegion);
      persistenceManager.deleteRegisteredValues();
      saveNewRegistrationCode(selectedRegion as Region);
    }
  }, [selectedRegion, saveNewRegistrationCode]);

  // Set region based on url params and local storage
  // If not token generated and region selected then register new codes
  useEffect(() => {
    const authToken = persistenceManager.getAuthToken();
    const storedCode = persistenceManager.getRegistrationCode();
    let storedRegion = persistenceManager.getRegion();

    if (urlParams.region) {
      if (isValidRegion(urlParams.region)) {
        if (storedRegion && urlParams.region !== storedRegion) {
          navigate("/register");
          return;
        }
        persistenceManager.setRegion(urlParams.region);
        storedRegion = urlParams.region;
        setSelectedRegion(urlParams.region);
      } else {
        navigate("/register");
        return;
      }
    }

    if (storedRegion) {
      setSelectedRegion(storedRegion);
      if (!authToken || !storedCode) {
        persistenceManager.deleteRegisteredValues();
        saveNewRegistrationCode(storedRegion as Region);
      } else {
        setRegistrationCode(storedCode);
      }
    }

    // If user previously logged in via SSO and then navigated to this page, delete SSO login details
    const ssoLoginUrl = persistenceManager.getSsoLoginPage();
    if (ssoLoginUrl) {
      persistenceManager.deleteSsoLoginUrl();
    }
  }, [saveNewRegistrationCode, urlParams.region]);

  useEffect(() => {
    document.title = "Hello Screens - Register Linking Code";
  }, []);

  // Poll for orders if code has been generated
  useEffect(() => {
    const attemptLogin = async () => {
      const token = persistenceManager.getAuthToken();
      const region = persistenceManager.getRegion();

      if (token && region) {
        try {
          await api.getOrders(token, region as Region);
          // This needs to stay as a location redirect rather than navigate via React
          // as the Native app uses the change url callback to identify when linking occurs
          window.location.href = "./active";
        } catch {
          setLoginAttempts((previousLoginAttempts: number) => {
            if (
              previousLoginAttempts !== 0 &&
              previousLoginAttempts % 10 === 0
            ) {
              console.log(`Failed login attempts: ${previousLoginAttempts}`);
            }
            return previousLoginAttempts + 1;
          });
        }
      }
    };

    attemptLogin();
    const intervalId = setInterval(() => attemptLogin(), POLLING_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  const onUpdateSelectedRegion = ({ value }: { value: Region }) => {
    setSelectedRegion(value);
  };

  const generateRegistrationCodeText = () => {
    if (registrationCode) {
      const codeLabel = registrationCode.split("").join(" ");
      return (
        <>
          <styled.OneTimeCodeExplanationText>
            Use this one-time code to setup your screen
          </styled.OneTimeCodeExplanationText>
          <styled.OneTimeCodeText aria-label={codeLabel}>
            {registrationCode}
          </styled.OneTimeCodeText>
        </>
      );
    }
    if (error) {
      return (
        <styled.OneTimeCodeSmallText>
          Failed to generate code
        </styled.OneTimeCodeSmallText>
      );
    }
    const region = persistenceManager.getRegion();
    if (!region) {
      return (
        <styled.OneTimeCodeSmallText>
          Select your region to generate code
        </styled.OneTimeCodeSmallText>
      );
    }

    return (
      <styled.OneTimeCodeSmallText>Generating</styled.OneTimeCodeSmallText>
    );
  };

  const onShowGenerateNewCodeModal = () => {
    const onGenerateNewCode = () => {
      const storedRegion = persistenceManager.getRegion();
      if (storedRegion) {
        saveNewRegistrationCode(storedRegion as Region);
      }
    };
    const title = "New Code?";
    const message =
      "Generating a new code means the previous code will no longer work for registration. Are you sure?";
    showConfirmationModal(title, message, onGenerateNewCode);
  };

  const pageContent = (
    <>
      <styled.LogoWrapper>
        <styled.LogoImage src={Logo} title="Bluedot Logo" alt="Bluedot Logo" />
      </styled.LogoWrapper>
      {generateRegistrationCodeText()}
      <styled.RegionSelectorWrapper isMobile={isMobile}>
        <RegionSelector
          handleRegionChange={onUpdateSelectedRegion}
          selectedRegion={selectedRegion}
        />
      </styled.RegionSelectorWrapper>
      {registrationCode && (
        <styled.GenerateCodeButtonWrapper isMobile={isMobile}>
          <Button onClick={onShowGenerateNewCodeModal}>
            Generate New Code
          </Button>
        </styled.GenerateCodeButtonWrapper>
      )}
      <SsoLoginLink
        buttonText="Single Sign-On"
        navigationLink="/au/sso/coles"
      />
    </>
  );

  if (isMobile) {
    return (
      <styled.BackgroundPanelMobile aria-hidden={hideMainContent}>
        <styled.OneTimeCodeMobilePanel>
          <styled.CenterAlignmentWrapper>
            {pageContent}
          </styled.CenterAlignmentWrapper>
        </styled.OneTimeCodeMobilePanel>
      </styled.BackgroundPanelMobile>
    );
  }
  return (
    <styled.BackgroundPanel aria-hidden={hideMainContent}>
      <styled.CenterAlignmentWrapper>
        <styled.OneTimeCodeWrapper>{pageContent}</styled.OneTimeCodeWrapper>
      </styled.CenterAlignmentWrapper>
    </styled.BackgroundPanel>
  );
};
