import React, { useEffect, useState } from "react";
import AppHeader from "../AppHeader/AppHeader";
import { ScreenWrapper } from "../../scenes/ActiveOrdersDisplay/styled";
import * as styled from "../PrivateRoute/styled";
import Spinner from "../Spinner";
import { Icon } from "@bluedot-innovation/bluedot-ui";

export default () => {
  const [networkIssues, setNetworkIssues] = useState(false);

  useEffect(() => {
    document.title = "Hello Screens - Loading Page";
    setTimeout(() => setNetworkIssues(true), 5000);
  }, []);

  return (
    <>
      <AppHeader />
      <ScreenWrapper>
        <styled.LoadingWrapper>
          <Spinner />
          <styled.LoadingText>Loading</styled.LoadingText>
          {networkIssues && (
            <styled.ErrorWrapper>
              <Icon glyph="alert" size={24} />
              <styled.ErrorText>
                Network issues detected. Still attempting to connect...
              </styled.ErrorText>
            </styled.ErrorWrapper>
          )}
        </styled.LoadingWrapper>
      </ScreenWrapper>
    </>
  );
};
