import {
  useOrderStatus,
  useStateDefinitions,
} from "../../hooks/useSharedContext";
import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import useModalTabController from "../../hooks/useModalTabController";
import { StateDefinition } from "../../types/StateDefinitionType";
import { OrderDetails } from "../../types/OrderDetailsType";
import * as persistenceManager from "../../services/persistenceManager";
import * as api from "../../services/api";
import { Region } from "../../types/RegionType";
import * as errorFactory from "../../services/errorFactory";
import { shouldOrderShowSuccessMessage } from "./helpers";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";
import { useWaitTime } from "../../hooks/useSharedContext";
import { VerificationPatch } from "../../services/api";

export type OrderDetailsModalPageOverride =
  | "SUCCESS_MESSAGE"
  | "VERIFICATION_CODE_INPUT"
  | "NO_VERIFICATION_REASON_INPUT"
  | undefined;

export interface UseOrderDetailsControllerOutput {
  pageOverride: OrderDetailsModalPageOverride;
  isUpdating: boolean;
  setShowChangeStatus: (newValue: boolean) => void;
  showChangeStatus: boolean;
  onUpdateOrderStatus: (
    newOrderStatus: string,
    usedQuickButton: boolean,
    verificationPatch?: VerificationPatch
  ) => Promise<void>;
  updateError: undefined | Error;
  currentState: StateDefinition;
  acknowledgeOrder: (
    orderDetails: OrderDetails,
    isInBackground: boolean
  ) => Promise<void>;
  selectedForceTransitionState: undefined | string;
  setSelectedForceTransitionState: (newValue: string | undefined) => void;
  modalRef: RefObject<HTMLDivElement>;
  errorMessage: string;
  setPageOverride: (newStatus: OrderDetailsModalPageOverride) => void;
  verificationTargetState: string;
}

export default (
  orderDetails: OrderDetails,
  hideOrderDetails: () => void
): UseOrderDetailsControllerOutput => {
  const { refreshOrderDetails, useRealtimeOrders } = useOrderStatus();
  const [updateError, setUpdateError] = useState<Error | undefined>();
  const [isUpdating, setIsUpdating] = useState(false);
  const { stateDictionary, stateDefinitions } = useStateDefinitions();
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [
    selectedForceTransitionState,
    setSelectedForceTransitionState,
  ] = useState<string | undefined>();
  const [pageOverride, setPageOverride] = useState<
    OrderDetailsModalPageOverride
  >();
  const currentState = useMemo(
    () => stateDictionary[orderDetails.status] as StateDefinition,
    [stateDictionary, orderDetails]
  );
  const [acknowledgeCalled, setAcknowledgeCalled] = useState(false);
  const { modalRef, setSelectedElementIndex } = useModalTabController(
    ".menu-item",
    hideOrderDetails,
    true
  );
  const { setHideMainContent, setHideBanner } = useAriaHideMainContent();
  const { refreshWaitTime } = useWaitTime();
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationTargetState, setVerificationTargetState] = useState("");

  useEffect(() => {
    setHideMainContent(true);
    setHideBanner(true);

    return () => {
      setHideMainContent(false);
      setHideBanner(false);
    };
  }, [setHideMainContent, setHideBanner]);

  const acknowledgeOrder = useCallback(
    async (orderDetails: OrderDetails, isInBackground: boolean) => {
      const eventTime = new Date().toISOString();
      const authToken = persistenceManager.getAuthToken();
      const region = persistenceManager.getRegion();

      if (authToken && region && orderDetails.orderId) {
        try {
          if (isInBackground) {
            await api.acknowledgeOrderWithRetries(
              orderDetails.orderId,
              authToken,
              region as Region,
              eventTime,
              orderDetails.lastUpdateTime,
              undefined
            );
          } else {
            await api.acknowledgeOrderWithRetries(
              orderDetails.orderId,
              authToken,
              region as Region,
              eventTime,
              orderDetails.lastUpdateTime,
              setErrorMessage
            );
          }
          if (!useRealtimeOrders) {
            await refreshOrderDetails();
          }
          // Is with retries refers to Arrival acknowledgement while the card
          // is still open. In this case do not hide the modal after acknowledgement
          // Otherwise it should occur after a button press so do hide the modal
          if (!isInBackground) {
            hideOrderDetails();
          }
        } catch (error) {
          setUpdateError(error as Error);
        }
      } else {
        if (!authToken) {
          setUpdateError(errorFactory.createNoTokenError() as Error);
        } else if (!region) {
          setUpdateError(errorFactory.createNoRegionError() as Error);
        }
      }
    },
    [refreshOrderDetails, setUpdateError, hideOrderDetails, useRealtimeOrders]
  );

  useEffect(() => {
    if (
      !acknowledgeCalled &&
      currentState.name === orderDetails.status &&
      currentState.canAcknowledge &&
      currentState.isActive &&
      !orderDetails.isAcknowledged
    ) {
      // If this acknowledgement is related to consolidation alerts then only acknowledge once the alert has been triggered
      if (
        currentState.notificationTypes?.includes("consolidation") &&
        !orderDetails.hasNotified?.includes("consolidation")
      ) {
        return;
      }
      setAcknowledgeCalled(true);
      acknowledgeOrder(orderDetails, true);
    }
  }, [
    orderDetails,
    acknowledgeOrder,
    currentState,
    acknowledgeCalled,
    setAcknowledgeCalled,
  ]);

  const onUpdateOrderStatus = async (
    newOrderStatus: string,
    usedQuickButton: boolean,
    verificationPatch?: VerificationPatch
  ) => {
    const newState = stateDictionary[newOrderStatus];
    if (newState.isVerified && !verificationPatch) {
      setPageOverride("VERIFICATION_CODE_INPUT");
      setVerificationTargetState(newOrderStatus);
      return;
    }
    setIsUpdating(true);
    const eventTime = new Date().toISOString();
    const authToken = persistenceManager.getAuthToken();
    const region = persistenceManager.getRegion();

    if (authToken && region && orderDetails.orderId) {
      try {
        await api.updateOrderStatusWithRetries(
          orderDetails.orderId,
          newOrderStatus,
          authToken,
          region as Region,
          setErrorMessage,
          eventTime,
          orderDetails.lastUpdateTime,
          verificationPatch
        );
        if (!useRealtimeOrders) {
          refreshWaitTime();
        }
        setIsUpdating(false);
        if (
          usedQuickButton &&
          shouldOrderShowSuccessMessage(
            orderDetails,
            currentState,
            stateDefinitions?.features
          )
        ) {
          setPageOverride("SUCCESS_MESSAGE");
        } else {
          hideOrderDetails();
          if (!useRealtimeOrders) {
            await refreshOrderDetails();
          }
        }
      } catch (error) {
        setUpdateError(error as Error);
        setIsUpdating(false);
      }
    } else {
      setUpdateError(errorFactory.createNoRegionError() as Error);
      setIsUpdating(false);
    }
  };

  const onSetShowChangeStatus = (newValue: boolean) => {
    setShowChangeStatus(newValue);
    setSelectedElementIndex(undefined);
  };

  return {
    pageOverride,
    isUpdating,
    setShowChangeStatus: onSetShowChangeStatus,
    showChangeStatus,
    onUpdateOrderStatus,
    updateError,
    currentState,
    acknowledgeOrder,
    selectedForceTransitionState,
    setSelectedForceTransitionState,
    modalRef,
    errorMessage,
    setPageOverride,
    verificationTargetState,
  };
};
