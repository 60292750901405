import styled from "styled-components";
import { Button } from "../Button/styled";
import { ColorProps } from "../../types/sharedComponentProps";
import { SMALL_PHONE_MAX_WIDTH } from "../../constants/layout";

interface OrderDetailsWrapperProps {
  isMobile: boolean;
}

export const OrderDetailsWrapper = styled.div<OrderDetailsWrapperProps>`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  position: absolute;
  top: 0;
  left: 0;
  ${(props) => {
    if (!props.isMobile) {
      return `top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);`;
    }
  }}
  min-width: ${(props) => (props.isMobile ? "100" : "55")}vw;
  min-height: ${(props) => (props.isMobile ? "100" : "40")}vh;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 5;
  text-align: center;
`;

interface OrderStatusProps {
  color?: string;
}

export const OrderDetailsStatusHeader = styled.div<OrderStatusProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color || props.theme.colors.SUCCESS};
  height: 6rem;
`;

export const HeaderText = styled.h1`
  color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  margin: auto 1rem;
  font-size: 1.8rem;
`;

export const CustomDataCardsWrapper = styled.section`
  width: 80%;
  margin: 1rem auto;
  overflow-y: scroll;
  max-height: 36vh;
`;

export const OrderStatusText = styled.p`
  font-weight: 700;
  font-size: 1.6rem;
  margin-top: 3rem;
  margin-bottom: 0rem;
`;

export const StatusDescriptionText = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0rem;
  margin-top: 1rem;
  overflow-wrap: break-word;
  padding: 0.5rem;

  b {
    margin-left: 0.5rem;
  }
`;

export const OrderIdText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  padding: 0.5rem;
  overflow-wrap: break-word;
  margin-top: 0.5rem;
  b {
    margin-right: 0.5rem;
  }
`;

export const OrderGroupText = styled.p<ColorProps>`
  color: ${(props) => props.color};
  font-size: 1rem;
  font-weight: 1000;
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  margin: 1rem auto;
  width: 80%;
`;

export const TwoButtonWrapper = styled.div`
  margin: 0 auto 0.5rem;
  display: inline-flex;
  width: 80%;
  justify-content: space-between;
  button {
    width: 40%;
  }
`;

export const BackButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  border-color: ${(props) => props.theme.colors.PRIMARY_TEXT};
`;

export const UpdateErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.TERTIARY_ACCENT};
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

export const SuccessMessageText = styled.h2``;

export const SuccessMessageTimerText = styled.p`
  color: ${(props) => props.theme.colors.DISABLED_TEXT};
`;

export const SuccessMessageGraphic = styled.img`
  height: 12rem;
  margin: 2rem 0;
`;

interface ErrorMessageWrapperProps {
  hasErrorMessage: boolean;
}

export const ErrorMessageWrapper = styled.div<ErrorMessageWrapperProps>`
  width: 80%;
  height: 4rem;
  ${(props) => {
    if (props.hasErrorMessage) {
      return `border: ${props.theme.colors.ERROR} solid 0.2rem;`;
    }
  }}

  margin: auto 10%;
  padding: 0 5%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    fill: ${(props) => props.theme.colors.ERROR};
    margin-right: 4rem;
  }
`;

export const VerificationCodeHeader = styled.p`
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 1.5rem;
`;

export const VerificationCodeInstructions = styled.p``;

export const CantProvideCodeButton = styled.button`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  border: 2px solid ${(props) => props.theme.colors.PRIMARY_TEXT};

  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 8rem;
`;

export const ReasonButtonsWrapper = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 4rem;
`;

export const ReasonButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  padding: 0 0.8rem;

  @media only screen and (max-width: ${SMALL_PHONE_MAX_WIDTH}) {
    min-height: 5rem;
  }
`;

export const ReasonRadioButton = styled.input`
  display: flex;
  justify-self: flex-start;
  align-self: center;
  transform: scale(1.5);
`;

export const ReasonButtonLabel = styled.label`
  text-align: left;
  margin-left: 1rem;
  font-weight: 700;
`;
