import styled from "styled-components";
import { IsMobileProps } from "../../types/sharedComponentProps";

export const WaitTimeDisplayWrapper = styled.div<IsMobileProps>`
  display: flex;
  ${(props) => {
    if (!props.isMobile) {
      return "flex-direction: column; align-items: flex-start;";
    } else {
      return "margin-top: 1rem; width: fit-content; align-items: center;";
    }
  }}
`;

export const WaitTimeWithGoalDisplayWrapper = styled.div<IsMobileProps>`
  display: flex;
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  padding: 0 1rem;
  border-radius: 0.5rem;
  ${(props) => {
    if (!props.isMobile) {
      return "flex-direction: column; align-items: flex-start;";
    } else {
      return "margin-top: 1rem; width: 100%; align-items: center; justify-content: space-between; padding-top: 0.5rem;";
    }
  }}
`;

export const MobileWaitTimeWrapper = styled.div`
  display: flex;
`;

export const WaitTimeLabel = styled.h3<IsMobileProps>`
  font-size: 1rem;
  ${(props) => {
    if (props.isMobile) {
      return "margin-right: 0.5rem;";
    }
  }}
`;

export const WaitTimeWrapper = styled.div`
  display: flex;
`;

export const WaitTimeWithUnitsWrapper = styled.div<IsMobileProps>`
  display: flex;
  ${(props) => {
    if (props.isMobile) {
      return "margin-top: 0.7rem;";
    }
  }}
`;

export const NoOrdersWrapper = styled.div<IsMobileProps>`
  ${(props) => {
    if (!props.isMobile) {
      return "width: 100%;";
    }
  }}
  text-align: center;
`;

export const NoOrdersText = styled.div<IsMobileProps>`
  text-align: center;
`;

interface WaitTimePerformanceProps {
  waitTimeGoal: number;
  waitTimeAlert: number;
  waitTime: number;
}

export const ColoredWaitTimePerformance = styled.p<WaitTimePerformanceProps>`
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  color: ${(props) => {
    if (props.waitTime >= props.waitTimeGoal) {
      return props.theme.colors.ERROR;
    }
    if (props.waitTime >= props.waitTimeAlert) {
      return props.theme.colors.WARNING;
    }
    return props.theme.colors.SUCCESS;
  }};
`;

export const WaitTimeNumber = styled.p`
  font-size: 19px;
  font-weight: 600;
  margin: 0;
`;

export const WaitTimeGoalDivider = styled.p`
  font-size: 19px;
  font-weight: 600;
  margin: 0 0.2rem 0.5rem;
`;

export const WaitTimeUnit = styled.p<IsMobileProps>`
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.5rem;
  margin-top: ${(props) => (props.isMobile ? "5px" : "3px")};
`;
