import React, { useEffect } from "react";
import { SAMLAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";
import Logo from "../../resources/Bluedot_Logo.png";
import Button from "../Button";
import * as styled from "./styled";
import * as errorFactory from "../../services/errorFactory";
import useIsMobile from "../../hooks/useIsMobile";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";
import SsoLoginLink from "../SsoLoginLink";

interface SsoLoginProps {
  error: Error | undefined;
  setShouldLoad: (newValue: boolean) => void;
  setError: (error: undefined | Error) => void;
  identityProvider: string;
}

export default ({
  error,
  setShouldLoad,
  setError,
  identityProvider,
}: SsoLoginProps) => {
  const isMobile = useIsMobile();
  const { hideMainContent } = useAriaHideMainContent();

  const onClickLogin = (targetIdentityProvider: string) => {
    const auth = getAuth();
    const provider = new SAMLAuthProvider(`saml.${targetIdentityProvider}`);
    signInWithRedirect(auth, provider);
  };

  useEffect(() => {
    document.title = "Hello Screens - Login via SSO";
  }, []);

  const pageContent = (
    <>
      <styled.LogoWrapper>
        <styled.LogoImage src={Logo} title="Bluedot Logo" alt="Bluedot Logo" />
      </styled.LogoWrapper>

      {!error ? (
        <>
          <styled.SsoLoginText>
            Connect to Hello Screens with your organization's identity provider
          </styled.SsoLoginText>
          <styled.LoginButtonWrapper isMobile={isMobile}>
            <Button
              onClick={() => onClickLogin(identityProvider)}
              disabled={!identityProvider}
            >
              Single Sign On
            </Button>
          </styled.LoginButtonWrapper>
        </>
      ) : error.name === errorFactory.NETWORK_CONNECTION_ERROR ? (
        <>
          <styled.SsoLoginText>
            Network connection failed. Click the button below to retry
          </styled.SsoLoginText>

          <styled.LoginButtonWrapper isMobile={isMobile}>
            <Button
              onClick={() => {
                setShouldLoad(true);
                setError(undefined);
              }}
            >
              Retry
            </Button>
          </styled.LoginButtonWrapper>
        </>
      ) : (
        <styled.SsoLoginText>
          Invalid login URL. Please check your URL and try again
        </styled.SsoLoginText>
      )}
    </>
  );

  if (isMobile) {
    return (
      <styled.BackgroundPanelMobile aria-hidden={hideMainContent}>
        <styled.SsoLoginMobilePanel>
          <styled.CenterAlignmentWrapper>
            <styled.SsoLoginWrapper>
              {pageContent}
              <SsoLoginLink
                buttonText="One Time Code"
                navigationLink="/register"
              />
            </styled.SsoLoginWrapper>
          </styled.CenterAlignmentWrapper>
        </styled.SsoLoginMobilePanel>
      </styled.BackgroundPanelMobile>
    );
  }
  return (
    <styled.BackgroundPanel aria-hidden={hideMainContent}>
      <styled.CenterAlignmentWrapper>
        <styled.SsoLoginWrapper>
          {pageContent}
          <SsoLoginLink buttonText="One Time Code" navigationLink="/register" />
        </styled.SsoLoginWrapper>
      </styled.CenterAlignmentWrapper>
    </styled.BackgroundPanel>
  );
};
