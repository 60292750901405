import styled from "styled-components";
import { Icon } from "@bluedot-innovation/bluedot-ui";

interface IsMobileProps {
  isMobile: boolean;
}

export const ModalTitle = styled.h1<IsMobileProps>`
  font-size: 1.5rem;
  ${(props) => {
    if (props.isMobile) {
      return `margin-bottom: 5rem;`;
    }
  }}
`;

export const ModalMessage = styled.p`
  margin: 2rem 0;
`;

export const CloseButton = styled(Icon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const ModalWrapper = styled.div<IsMobileProps>`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  position: absolute;
  top: 0;
  left: 0;
  ${(props) => {
    if (!props.isMobile) {
      return `top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding: 2% 5%;`;
    }
  }}
  min-width: ${(props) => (props.isMobile ? "100" : "20")}vw;
  min-height: ${(props) => (props.isMobile ? "100" : "20")}vh;
  z-index: 10;
  text-align: center;
  box-sizing: border-box;
`;

export const ButtonsWrapper = styled.div<IsMobileProps>`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;

  ${(props) => {
    if (props.isMobile) {
      return `flex-direction: column;
            align-items: center;
            
            button {
              margin-bottom: 3rem;
            }`;
    }
  }}
`;

export const MobileVeritcalCenterContainer = styled.div<IsMobileProps>`
  ${(props) => {
    if (props.isMobile) {
      return `margin: 0;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              padding: 2% 10%;`;
    }
  }}
`;

export const ButtonWrapper = styled.div`
  width: 30%;
`;

export const CancelButtonWrapper = styled(ButtonWrapper)`
  button {
    border-color: ${(props) => props.theme.colors.TERTIARY_ACCENT};
    background-color: ${(props) => props.theme.colors.TERTIARY_ACCENT};
  }
`;
