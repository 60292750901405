import { useState, useEffect } from "react";
import * as persistenceManager from "../services/persistenceManager";
import { getWebsocketUrl } from "../services/api";
import * as api from "../services/api";
import { Region } from "../types/RegionType";
import { OrderDetails } from "../types/OrderDetailsType";
import {
  StateUpdateReceivedMessage,
  UpdateOrderMessage,
  UpdateStateMessage,
} from "../types/WebsocketTypes";
import { useNetworkStatus } from "./useSharedContext";
import { NETWORK_STATUS_ENUM } from "../components/ConnectionStatusSnackbar/ConnectionStatusContext";

const CONNECTION_CHECK_SEND_TIMER = 1000 * 20;
const CONNECTION_CHECK_RECEIVE_TIMER = 1000 * 25;

export default (
  refreshWaitTime: () => void,
  stateHash: string | undefined,
  refreshOrderDetails: () => Promise<void>
) => {
  const [isConnected, setIsConnected] = useState(false);
  const [websocket, setWebsocket] = useState<WebSocket | undefined>();
  const [shouldConnect, setShouldConnect] = useState(false);
  const [lastEventSendTime, setLastEventSendTime] = useState("");
  const [lastReceivedTime, setLastReceivedTime] = useState("");
  const [orders, setOrders] = useState<OrderDetails[] | undefined>();
  const [useRealtimeOrders, setUseRealtimeOrders] = useState(false);
  const [shouldCheckReceivedTime, setShouldCheckReceivedTime] = useState(false);
  const { setRealtimeConnected, networkStatus } = useNetworkStatus();

  useEffect(() => {
    const region = persistenceManager.getRegion();
    const token = persistenceManager.getAuthToken();
    if (!region || !token) {
      return;
    }
    if (
      shouldConnect &&
      networkStatus === NETWORK_STATUS_ENUM.ONLINE &&
      (!websocket || websocket.readyState === websocket.CLOSED)
    ) {
      if (isConnected) {
        setIsConnected(false);
      }
      const targetUrl = getWebsocketUrl(region);
      const socket = new WebSocket(targetUrl, token);
      socket.onopen = async (event) => {
        setIsConnected(true);
        setLastEventSendTime(new Date().toISOString());
        const getOrdersResponse = await api.getOrders(token, region as Region);
        if (socket.readyState === socket.OPEN) {
          refreshWaitTime();
          setUseRealtimeOrders(true);
          setOrders(getOrdersResponse.orderDetails);
          setLastReceivedTime(new Date().toISOString());
          if (getOrdersResponse.stateHash !== stateHash) {
            refreshOrderDetails();
          }
          setRealtimeConnected(true);
        }
      };

      socket.onclose = (event) => {
        setIsConnected(false);
        setUseRealtimeOrders(false);
        setWebsocket(undefined);
        setLastReceivedTime("");
        setRealtimeConnected(false);
      };

      socket.onmessage = async (event) => {
        const parsedMessage = JSON.parse(event.data);
        setLastReceivedTime(new Date().toISOString());
        if (parsedMessage.messageType === "ORDER_UPDATE") {
          const orderUpdateMessage = (parsedMessage as unknown) as UpdateOrderMessage;
          setOrders((prevState) => {
            if (!prevState) {
              return undefined;
            }
            const newOrders = [...prevState];
            const existingOrderIndex = newOrders.findIndex(
              (order) => order.orderId === orderUpdateMessage.order.orderId
            );
            if (existingOrderIndex === -1) {
              newOrders.push(orderUpdateMessage.order);
            } else if (
              newOrders[existingOrderIndex].lastUpdateTime <
              orderUpdateMessage.order.lastUpdateTime
            ) {
              newOrders[existingOrderIndex] = orderUpdateMessage.order;
            }
            return newOrders;
          });
          if (orderUpdateMessage.order.waitTime) {
            refreshWaitTime();
          }
          const updateReceivedMessage = {
            messageType: "ORDER_UPDATE_RECEIVED",
            notificationId: orderUpdateMessage.notificationId,
            timestamp: new Date().toISOString(),
          };
          socket.send(JSON.stringify(updateReceivedMessage));
        } else if (parsedMessage.messageType === "STATE_UPDATE") {
          const stateUpdateMessage = (parsedMessage as unknown) as UpdateStateMessage;
          await refreshOrderDetails();
          const message: StateUpdateReceivedMessage = {
            messageType: "STATE_UPDATE_RECEIVED",
            projectId: stateUpdateMessage.projectId,
            eventTime: stateUpdateMessage.eventTime,
            timestamp: new Date().toISOString(),
          };
          if (stateUpdateMessage.destinationId) {
            message.destinationId = stateUpdateMessage.destinationId;
          }
          socket.send(JSON.stringify(message));
        }
      };

      setWebsocket(socket);
    }
    if (!shouldConnect) {
      setIsConnected(false);
      setUseRealtimeOrders(false);
      setWebsocket(undefined);
      setLastReceivedTime("");
      setRealtimeConnected(false);
    }
  }, [
    shouldConnect,
    websocket,
    isConnected,
    refreshWaitTime,
    networkStatus,
    setRealtimeConnected,
    stateHash,
    refreshOrderDetails,
  ]);

  useEffect(() => {
    if (isConnected && websocket) {
      const sendConnectionCheckMessage = async () => {
        if (websocket.readyState === websocket.OPEN) {
          const connectionCheckMessage = JSON.stringify({
            messageType: "CONNECTION_CHECK",
            timestamp: new Date().getTime(),
          });
          try {
            websocket.send(connectionCheckMessage);
            setLastEventSendTime(new Date().toISOString());
          } catch (err) {
            console.log("Webhook message error", err);
          }
        } else {
          setIsConnected(false);
          setUseRealtimeOrders(false);
          setWebsocket(undefined);
          setLastReceivedTime("");
          setRealtimeConnected(false);
        }
      };
      const timerId = setTimeout(
        sendConnectionCheckMessage,
        CONNECTION_CHECK_SEND_TIMER
      );
      return () => clearInterval(timerId);
    }
  }, [isConnected, lastEventSendTime, websocket, setRealtimeConnected]);

  useEffect(() => {
    if (isConnected && lastReceivedTime) {
      const expectedLastMessageTime = new Date(
        new Date().getTime() - CONNECTION_CHECK_RECEIVE_TIMER
      ).toISOString();
      if (
        lastReceivedTime < expectedLastMessageTime &&
        websocket?.readyState === websocket?.OPEN
      ) {
        websocket?.close();
        console.log("Closing due to no connection update received");
      }

      setShouldCheckReceivedTime(false);
      const timerId = setTimeout(() => {
        setShouldCheckReceivedTime(true);
      }, CONNECTION_CHECK_RECEIVE_TIMER + 1);
      return () => clearInterval(timerId);
    }
  }, [lastReceivedTime, isConnected, websocket, shouldCheckReceivedTime]);

  return {
    realtimeOrders: orders || [],
    useRealtimeOrders,
    setShouldConnectToRealtimeService: setShouldConnect,
    lastRealtimeEventTime: lastReceivedTime,
  };
};
