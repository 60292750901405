import { useEffect, useState } from "react";
import { POLLING_INTERVAL } from "../constants/intervals";
const VERSION_NUMBER = process.env.REACT_APP_VERSION_NUMBER || "1.0";

export default () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const shouldForceRefresh = async (): Promise<boolean> => {
    // This is used only when running locally from dev
    if (process.env.REACT_APP_OVERRIDE_POLL_APP_VERSION === "true") {
      return false;
    }
    try {
      // Adding a timestamp in here prevents the browser from caching the response
      const response = await fetch(
        `/meta.json?timestamp=${new Date().getTime()}`
      );
      const meta = await response.json();
      if (!meta.version) {
        console.log("Could not find the latest version in meta.json");
        return false;
      }
      const latestVersion = meta.version;
      if (latestVersion !== VERSION_NUMBER) {
        console.log(
          `We have a new version - ${latestVersion}. Should force refresh`
        );
        return true;
      }
    } catch (err) {
      console.log("Error while comparing version", err);
    }
    return false;
  };

  useEffect(() => {
    const timerId = setInterval(async () => {
      if (await shouldForceRefresh()) {
        setShouldRefresh(true);
        return;
      }
    }, POLLING_INTERVAL);

    return () => clearInterval(timerId);
  }, []);

  return shouldRefresh;
};
