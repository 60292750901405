import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import useModalTabController from "../../hooks/useModalTabController";
import * as styled from "./styled";
import Button from "../Button";
import { Overlay } from "../LayoutComponents/styled";

interface ConfirmationModalProps {
  modalTitle: string;
  message: string;
  onConfirm: () => void;
  onHideModal: () => void;
}

/*
  To use this component, please use the ConfirmationModalContext defined in the ConfirmationModalContext file in this folder
  For usage instructions see the ConfirmationModalContext file
 */
export default ({
  modalTitle,
  message,
  onConfirm,
  onHideModal,
}: ConfirmationModalProps) => {
  const isMobile = useIsMobile();
  const { modalRef } = useModalTabController(".modal-item", onHideModal, true);
  return (
    <>
      <Overlay zIndex={8} />
      <styled.ModalWrapper
        isMobile={isMobile}
        ref={modalRef}
        role="dialog"
        aria-label={`${modalTitle} Modal`}
      >
        <styled.CloseButton
          glyph="x"
          size="18"
          onClick={() => onHideModal()}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Enter") {
              onHideModal();
            }
          }}
          className="modal-item"
          tabIndex={0}
        />
        <styled.MobileVeritcalCenterContainer isMobile={isMobile}>
          <styled.ModalTitle isMobile={isMobile}>
            {modalTitle}
          </styled.ModalTitle>
          <styled.ModalMessage>{message}</styled.ModalMessage>
          <styled.ButtonsWrapper isMobile={isMobile}>
            {isMobile ? (
              <>
                <styled.ButtonWrapper>
                  <Button
                    onClick={() => {
                      onConfirm();
                      onHideModal();
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        onConfirm();
                        onHideModal();
                      }
                    }}
                    className="modal-item"
                  >
                    Yes
                  </Button>
                </styled.ButtonWrapper>
                <styled.CancelButtonWrapper>
                  <Button
                    onClick={() => onHideModal()}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        onHideModal();
                      }
                    }}
                    className="modal-item"
                  >
                    No
                  </Button>
                </styled.CancelButtonWrapper>
              </>
            ) : (
              <>
                <styled.CancelButtonWrapper>
                  <Button
                    onClick={() => onHideModal()}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        onHideModal();
                      }
                    }}
                    className="modal-item"
                  >
                    No
                  </Button>
                </styled.CancelButtonWrapper>
                <styled.ButtonWrapper>
                  <Button
                    onClick={() => {
                      onConfirm();
                      onHideModal();
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        onConfirm();
                        onHideModal();
                      }
                    }}
                    className="modal-item"
                  >
                    Yes
                  </Button>
                </styled.ButtonWrapper>
              </>
            )}
          </styled.ButtonsWrapper>
        </styled.MobileVeritcalCenterContainer>
      </styled.ModalWrapper>
    </>
  );
};
