import styled from "styled-components";

interface IsMobileProps {
  isMobile: boolean;
}

export const SsoLinkWrapper = styled.div`
  width: 100%;
`;

export const SsoLinkDividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const SsoLinkDividerLine = styled.div`
  height: 2px;
  width: 40%;
  background-color: ${(props) => props.theme.colors.PRIMARY_TEXT};
`;

export const SsoLinkDividerText = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
`;

export const SsoLoginButtonWrapper = styled.div<IsMobileProps>`
  margin: 2rem auto;
  width: 80%;

  ${(props) => {
    if (props.isMobile) {
      return `
              width: 100%;
              button {
                font-size: 1.5rem;
                padding: 1.5rem;
              }
          `;
    }
  }}

  & button {
    background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
    color: ${(props) => props.theme.colors.PRIMARY_TEXT};
    border-color: ${(props) => props.theme.colors.PRIMARY_TEXT};
    height: 3.5rem;
    padding: 0.5rem;
    font-size: 1.2rem;
  }
`;
