import React, { useEffect, useState } from "react";
import * as styled from "./styled";
import useIsMobile from "../../hooks/useIsMobile";
import { Overlay } from "../LayoutComponents/styled";
import rocket from "../../resources/rocket.png";
import useModalTabController from "../../hooks/useModalTabController";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";

export default ({ shouldRefresh }: { shouldRefresh: boolean }) => {
  const [seconds, setSeconds] = useState(10);
  const isMobile = useIsMobile();

  const { setHideMainContent, setHideBanner } = useAriaHideMainContent();

  useEffect(() => {
    if (shouldRefresh) {
      setHideMainContent(true);
      setHideBanner(true);
    }
  }, [setHideMainContent, shouldRefresh, setHideBanner]);

  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
  };

  const { modalRef } = useModalTabController(
    ".refresh-modal-item",
    refreshCacheAndReload,
    shouldRefresh
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!shouldRefresh) {
        return;
      }

      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
      if (seconds === 0) {
        refreshCacheAndReload();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [seconds, shouldRefresh]);

  return (
    <>
      {shouldRefresh && (
        <>
          <Overlay onClick={() => refreshCacheAndReload()} zIndex={9} />
          <styled.AutoRefreshWrapper
            isMobile={isMobile}
            ref={modalRef}
            role="dialog"
          >
            <styled.AutoRefreshHeader isMobile={isMobile}>
              <styled.AutoRefreshImage src={rocket} alt="Rocket clipart" />
            </styled.AutoRefreshHeader>
            <styled.HeaderText>New version available</styled.HeaderText>
            <styled.BodyText>
              We are going to automatically refresh the app to get the latest
              updates.
            </styled.BodyText>
            <styled.BodyText>
              Refreshing in{" "}
              <styled.BluedotPurpleText>
                {seconds} seconds
              </styled.BluedotPurpleText>
            </styled.BodyText>
            <styled.ButtonWrapper isMobile={isMobile}>
              <styled.Button
                onClick={() => {
                  refreshCacheAndReload();
                }}
                isMobile={isMobile}
                className="refresh-modal-item"
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    refreshCacheAndReload();
                  }
                }}
              >
                REFRESH NOW
              </styled.Button>
            </styled.ButtonWrapper>
          </styled.AutoRefreshWrapper>
        </>
      )}
    </>
  );
};
