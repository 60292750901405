import { ExtendedGlobal } from "../types/GlobalExtensionFunctions";

const consolidationAlertSound = require("../resources/consolidation-alert.wav");
const notificationSound = require("../resources/arrival-notification-sound.mp3");

const notificationPlayer: HTMLAudioElement = new Audio(notificationSound);
const consolidationAlertPlayer: HTMLAudioElement = new Audio(
  consolidationAlertSound
);

let playOnLoop = false;
let soundPlaying = false;
export let playerSoundEnabled = false;

const soundEndedEventListener = () => {
  if (playOnLoop && playerSoundEnabled) {
    soundPlaying = true;
    notificationPlayer.play();
  } else {
    soundPlaying = false;
  }
};

const soundPausedEventListener = () => {
  soundPlaying = false;
};

export const loopNotificationSound = async () => {
  playOnLoop = true;
  if (!soundPlaying && playerSoundEnabled) {
    notificationPlayer.addEventListener("ended", soundEndedEventListener);
    notificationPlayer.addEventListener("pause", soundPausedEventListener);
    try {
      await notificationPlayer.play();
      soundPlaying = true;
    } catch (error) {
      // This happens when the browser is preventing the sound from being
      // played (usually the user needs to interact with the screen first)
      console.log("Failed to play sound", error);
      soundPlaying = false;
    }
  }
};

export const stopNotificationSound = () => {
  playOnLoop = false;
  notificationPlayer.pause();
};

const consolidationAlertEndedListener = () => {
  if (playOnLoop && playerSoundEnabled) {
    loopNotificationSound();
  }
};

export const playConsolidationAlert = () => {
  if (!playerSoundEnabled) {
    return;
  }
  if (playOnLoop) {
    notificationPlayer.pause();
  }
  consolidationAlertPlayer.addEventListener(
    "ended",
    consolidationAlertEndedListener
  );
  consolidationAlertPlayer.play();
};

export const enableNotificationSounds = async () => {
  try {
    await notificationPlayer.play();
    playerSoundEnabled = true;
    if (playOnLoop) {
      await loopNotificationSound();
    } else {
      notificationPlayer.pause();
    }
  } catch (error) {
    console.error("Failed to enable sounds", error);
    playerSoundEnabled = false;
  }
};

export const disableNotificationSounds = async () => {
  try {
    playerSoundEnabled = false;
    await notificationPlayer.pause();
  } catch (error) {
    console.error("Failed to disable sounds");
  }
};

// DO NOT CHANGE THESE GLOBAL FUNCTION SIGNATURES OR RETURN TYPE - IT WILL BREAK THE NATIVE APP
((global as unknown) as ExtendedGlobal).disableNotificationSounds = disableNotificationSounds;

((global as unknown) as ExtendedGlobal).getSoundEnabledStatus = () => {
  return playerSoundEnabled;
};

((global as unknown) as ExtendedGlobal).enableNotificationSounds = enableNotificationSounds;
