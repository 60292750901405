import React, { useEffect } from "react";
import ConnectionStatus from "../../components/ConnectionStatusSnackbar";
import styled from "./styled";
import BackwardsNavigation from "../../components/BackwardsNavigation";
import AppHeader from "../../components/AppHeader/AppHeader";
import {
  useOrderStatus,
  useStateDefinitions,
} from "../../hooks/useSharedContext";
import OrderTracker from "../../components/OrderTracker";
import useOrderDetailsOverlay from "../../hooks/useOrderDetailsOverlay";
import OrderDetailsDisplay from "../../components/OrderDetailsDisplay/OrderDetailsDisplay";
import useOrderSearchFilter from "../../hooks/useOrderSearchFilter";
import useIsMobile from "../../hooks/useIsMobile";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";
import NavigationLinks from "../../components/NavigationLinks";

const OrderHistoryDisplay = () => {
  const { historyOrders, currentOrders, orderGroups } = useOrderStatus();
  const {
    orderDetailsOverlayData,
    setOrderDetailsOverlayData,
  } = useOrderDetailsOverlay(currentOrders, historyOrders);
  const { stateDefinitions } = useStateDefinitions();
  const { filteredOrders, searchTerm, setSearchTerm } = useOrderSearchFilter(
    historyOrders
  );
  const isMobile = useIsMobile();
  const { hideMainContent, hideBanner } = useAriaHideMainContent();

  useEffect(() => {
    document.title = "Hello Screens - Order History";
  }, []);

  return (
    <>
      {orderDetailsOverlayData && (
        <OrderDetailsDisplay
          orderDetails={orderDetailsOverlayData}
          hideOrderDetails={() => setOrderDetailsOverlayData(null)}
          orderGroups={orderGroups}
        />
      )}
      <AppHeader ariaHidden={hideBanner} />
      <styled.ScreenWrapper aria-hidden={hideMainContent}>
        <styled.TopBar>
          <BackwardsNavigation />
          {!isMobile && !stateDefinitions?.features?.hideSearchBar && (
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          )}
          <ConnectionStatus />
        </styled.TopBar>
        {isMobile && !stateDefinitions?.features?.hideSearchBar && (
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
        <OrderTracker
          showingHistory={true}
          categoryTitle="History"
          orders={filteredOrders}
          setOrderDetailsOverlayData={setOrderDetailsOverlayData}
          isSearching={Boolean(searchTerm)}
        />
        <NavigationLinks />
      </styled.ScreenWrapper>
    </>
  );
};

export default OrderHistoryDisplay;
