import React from "react";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import { myTheme } from "./services/theme";
import { ConfirmationModalProvider } from "./components/ConfirmationModal/ConfirmationModalContext";
import { OrderStatusProvider } from "./components/OrderStatusContext/OrderStatusContext";
import { StateDefinitionsProvider } from "./components/StateDefinitionsContext/StateDefinitionsContext";
import { WaitTimeProvider } from "./components/WaitTimeDisplay/WaitTimeContext";
import { ConnectionStatusProvider } from "./components/ConnectionStatusSnackbar/ConnectionStatusContext";
import { StoreProvider } from "./components/StoreContext/StoreContext";
import ActiveOrdersDisplay from "./scenes/ActiveOrdersDisplay";
import AppContainer from "./components/AppContainer";
import OneTimeCodeSetup from "./scenes/OneTimeCodeSetup";
import OrderHistoryDisplay from "./scenes/OrderHistoryDisplay";
import PrivateRoute from "./components/PrivateRoute";
import UrlLinker from "./components/UrlLinker";
import usePollAppVersion from "./hooks/usePollAppVersion";
import useLockScalingForNativeApp from "./hooks/useLockScalingForNativeApp";
import AutoRefresh from "./components/AutoRefresh";
import SsoHandler from "./scenes/SsoHandler";
import { AriaHideMainContentProvider } from "./components/AriaHideMainContentContext/AriaHideMainContentContext";

function App() {
  const shouldRefresh = usePollAppVersion();
  useLockScalingForNativeApp();
  return (
    <ThemeProvider theme={myTheme}>
      <ConnectionStatusProvider>
        <AriaHideMainContentProvider>
          <AutoRefresh shouldRefresh={shouldRefresh} />
          <StateDefinitionsProvider>
            <WaitTimeProvider>
              <OrderStatusProvider>
                <StoreProvider>
                  <ConfirmationModalProvider>
                    <AppContainer>
                      <Router>
                        <PrivateRoute
                          path="/"
                          component={<ActiveOrdersDisplay />}
                        />
                        <PrivateRoute
                          path="/active"
                          component={<ActiveOrdersDisplay />}
                        />
                        <PrivateRoute
                          path="/history"
                          component={<OrderHistoryDisplay />}
                        />
                        <SsoHandler path="/:region/sso/:urlCode" />
                        <UrlLinker path="/:region/:urlCode" />
                        <OneTimeCodeSetup path="/:region" />
                        <OneTimeCodeSetup path="/register" />
                      </Router>
                    </AppContainer>
                  </ConfirmationModalProvider>
                </StoreProvider>
              </OrderStatusProvider>
            </WaitTimeProvider>
          </StateDefinitionsProvider>
        </AriaHideMainContentProvider>
      </ConnectionStatusProvider>
    </ThemeProvider>
  );
}

export default App;
