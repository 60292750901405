import React, { RefObject, useState } from "react";
import { Overlay } from "../../../LayoutComponents/styled";
import * as styled from "../../styled";
import {
  generateHeaderText,
  generateStatusDescriptionText,
} from "../../helpers";
import { OrderDetails } from "../../../../types/OrderDetailsType";
import useIsMobile from "../../../../hooks/useIsMobile";
import { StateDefinition } from "../../../../types/StateDefinitionType";
import CodeInput from "../../../CodeInput";
import {
  getGroupColor,
  getOrderGroup,
  OrderGroupMapping,
  shouldRenderOrderGroup,
} from "../../../../services/orderGrouping";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import Button from "../../../Button";
import { OrderDetailsModalPageOverride } from "../../useOrderDetailsDisplayController";
import { VerificationPatch } from "../../../../services/api";
import { hasUsedQuickButton } from "../../../../services/utils";

const CODE_LENGTH = 3;

export default ({
  orderDetails,
  hideOrderDetails,
  currentState,
  orderGroups,
  errorMessage,
  modalRef,
  setPageOverride,
  onUpdateOrderStatus,
  targetStatus,
  isUpdating,
}: {
  orderDetails: OrderDetails;
  hideOrderDetails: () => void;
  currentState: StateDefinition;
  orderGroups: OrderGroupMapping | undefined;
  errorMessage: string;
  modalRef: RefObject<HTMLDivElement>;
  setPageOverride: (newStatus: OrderDetailsModalPageOverride) => void;
  onUpdateOrderStatus: (
    newOrderStatus: string,
    usedQuickButton: boolean,
    verificationPatch?: VerificationPatch
  ) => Promise<void>;
  targetStatus: string;
  isUpdating: boolean;
}) => {
  const isMobile = useIsMobile();
  const [code, setCode] = useState("");

  const hasGroup = shouldRenderOrderGroup(orderDetails, orderGroups);
  const targetGroup = getOrderGroup(orderDetails, orderGroups);

  return (
    <>
      <Overlay onClick={() => hideOrderDetails()} zIndex={4} />
      <styled.OrderDetailsWrapper
        isMobile={isMobile}
        ref={modalRef}
        role="dialog"
        aria-label="Verification Code Input"
      >
        <styled.OrderDetailsStatusHeader>
          <styled.HeaderText>
            {generateHeaderText(orderDetails, currentState)}
          </styled.HeaderText>
        </styled.OrderDetailsStatusHeader>
        <styled.StatusDescriptionText>
          {generateStatusDescriptionText(orderDetails, currentState)}
        </styled.StatusDescriptionText>
        <styled.OrderIdText>
          <b>Order ID:</b>
          {orderDetails.orderId}
        </styled.OrderIdText>
        {hasGroup && (
          <styled.OrderGroupText
            color={getGroupColor(targetGroup!.groupNumber)}
          >
            {targetGroup!.groupName}
          </styled.OrderGroupText>
        )}
        <styled.VerificationCodeHeader>
          Order Verification Code
        </styled.VerificationCodeHeader>
        <styled.VerificationCodeInstructions>
          Ask for customer&apos;s code to complete the order
        </styled.VerificationCodeInstructions>
        <CodeInput
          codeLength={CODE_LENGTH}
          code={code}
          onUpdateCode={setCode}
          submit={() => {
            const usedQuickButton = hasUsedQuickButton(
              currentState,
              targetStatus
            );
            onUpdateOrderStatus(targetStatus, usedQuickButton, {
              op: "replace",
              path: "/verificationCode",
              value: code,
            });
          }}
        />
        <styled.CantProvideCodeButton
          onClick={() => setPageOverride("NO_VERIFICATION_REASON_INPUT")}
          className="menu-item"
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              setPageOverride("NO_VERIFICATION_REASON_INPUT");
            }
          }}
        >
          Can&apos;t provide code
        </styled.CantProvideCodeButton>
        <styled.ErrorMessageWrapper hasErrorMessage={Boolean(errorMessage)}>
          {errorMessage && (
            <>
              {!isMobile && <Icon glyph="alert" size={28} />}
              <styled.UpdateErrorMessage role="status">
                {errorMessage}
              </styled.UpdateErrorMessage>
            </>
          )}
        </styled.ErrorMessageWrapper>
        <styled.ButtonWrapper>
          <Button
            onClick={() => {
              const usedQuickButton = hasUsedQuickButton(
                currentState,
                targetStatus
              );
              onUpdateOrderStatus(targetStatus, usedQuickButton, {
                op: "replace",
                path: "/verificationCode",
                value: code,
              });
            }}
            disabled={code.length !== CODE_LENGTH || isUpdating}
            className="menu-item"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                const usedQuickButton = hasUsedQuickButton(
                  currentState,
                  targetStatus
                );
                onUpdateOrderStatus(targetStatus, usedQuickButton, {
                  op: "replace",
                  path: "/verificationCode",
                  value: code,
                });
              }
            }}
          >
            Verify Code
          </Button>
        </styled.ButtonWrapper>
        <styled.ButtonWrapper>
          <styled.BackButton
            onClick={() => setPageOverride(undefined)}
            className="menu-item"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                setPageOverride(undefined);
              }
            }}
          >
            Back
          </styled.BackButton>
        </styled.ButtonWrapper>
      </styled.OrderDetailsWrapper>
    </>
  );
};
