import styled from "styled-components";
import { Icon } from "@bluedot-innovation/bluedot-ui";

export const BackwardsNavigationIcon = styled(Icon)`
  transform: rotate(180deg);
  margin-right: 1rem;
`;

export const BackwardsNavigationWrapper = styled.div`
  display: inline-flex;
`;

export const BackwardsNavigationText = styled.p`
  margin: auto;
  padding: 0.2rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
`;
