import styled from "styled-components";

export const Button = styled.button`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.DISABLED_TEXT
      : props.theme.colors.SECONDARY_ACCENT};
  border: solid 1px
    ${(props) =>
      props.disabled
        ? props.theme.colors.DISABLED_TEXT
        : props.theme.colors.SECONDARY_ACCENT};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  padding: 0.8rem 1rem;
  width: 100%;
  font-weight: 800;
  font-size: 19px;
  font-family: "Spartan", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
`;
