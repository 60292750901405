import { useContext } from "react";
import { ConfirmationModalContext } from "../components/ConfirmationModal/ConfirmationModalContext";
import { OrderStatusContext } from "../components/OrderStatusContext/OrderStatusContext";
import { StateDefinitionsContext } from "../components/StateDefinitionsContext/StateDefinitionsContext";
import { AriaHideMainContentContext } from "../components/AriaHideMainContentContext/AriaHideMainContentContext";
import { WaitTimeContext } from "../components/WaitTimeDisplay/WaitTimeContext";
import { ConnectionStatusContext } from "../components/ConnectionStatusSnackbar/ConnectionStatusContext";
import { StoreContext } from "../components/StoreContext/StoreContext";

export const useConfirmationModal = () => useContext(ConfirmationModalContext);
export const useOrderStatus = () => useContext(OrderStatusContext);
export const useStateDefinitions = () => useContext(StateDefinitionsContext);
export const useAriaHideMainContent = () =>
  useContext(AriaHideMainContentContext);
export const useWaitTime = () => useContext(WaitTimeContext);
export const useNetworkStatus = () => useContext(ConnectionStatusContext);
export const useStore = () => useContext(StoreContext);
