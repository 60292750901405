import React from "react";
import * as styled from "./styled";

export default () => {
  return (
    <styled.NavLinksWrapper aria-labelledby="navigation-links-label">
      <styled.LinksDescription id="navigation-links-label">
        Site Map:
      </styled.LinksDescription>
      <styled.NavLink href="/active">Active Orders</styled.NavLink>
      <styled.NavLink href="/history">Order History</styled.NavLink>
    </styled.NavLinksWrapper>
  );
};
