import { useCallback, useEffect, useState } from "react";
import * as persistenceManager from "../services/persistenceManager";
import * as api from "../services/api";
import * as errorFactory from "../services/errorFactory";
import { Store } from "../types/StoreType";
import { ExtendedGlobal } from "../types/GlobalExtensionFunctions";

export default () => {
  const [store, setStore] = useState<undefined | Store>();
  const [shouldRefresh, setShouldRefresh] = useState(true);

  // DO NOT CHANGE THIS FUNCTION SIGNATURE OR RETURN TYPE - IT WILL BREAK THE NATIVE APP
  ((global as unknown) as ExtendedGlobal).getStoreDetails = () => {
    return store;
  };

  useEffect(() => {
    const getStore = async () => {
      setShouldRefresh(false);
      const token = persistenceManager.getAuthToken();
      const region = persistenceManager.getRegion();

      if (token && region) {
        try {
          const storeResponse = await api.getStore(token as string, region);
          setStore(storeResponse);
        } catch (error) {
          console.log("Failed to get store.");
        }
      } else {
        if (!token) {
          console.log(errorFactory.createNoTokenError());
        } else if (!region) {
          console.log(errorFactory.createNoRegionError());
        }
        setStore(undefined);
      }
    };

    if (shouldRefresh) {
      getStore();
    }
  }, [shouldRefresh]);

  const refreshStore = useCallback(() => {
    setShouldRefresh(true);
  }, [setShouldRefresh]);

  return {
    store,
    refreshStore,
  };
};
