import { useState, useMemo } from "react";
import { OrderDetails } from "../types/OrderDetailsType";

export default (orders: OrderDetails[]) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOrders = useMemo(() => {
    if (!searchTerm) {
      return orders;
    }
    return orders.filter((order) => {
      if (
        order.orderId.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      ) {
        return true;
      }
      return (
        order.customerName &&
        order.customerName.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
          -1
      );
    });
  }, [searchTerm, orders]);

  return {
    filteredOrders,
    searchTerm,
    setSearchTerm,
  };
};
