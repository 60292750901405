import React from "react";
import { navigate } from "@reach/router";
import { Overlay } from "../LayoutComponents/styled";
import { deleteAllValues } from "../../services/persistenceManager";
import { useConfirmationModal } from "../../hooks/useSharedContext";
import { useOrderStatus } from "../../hooks/useSharedContext";
import useIsMobile from "../../hooks/useIsMobile";
import useModalTabController from "../../hooks/useModalTabController";
import * as styled from "./styled";
import { Store } from "../../types/StoreType";

const VERSION_NUMBER = process.env.REACT_APP_VERSION_NUMBER || "1.0";

export default ({
  closeMenu,
  store,
}: {
  closeMenu: () => void;
  store: Store | undefined;
}) => {
  const isMobile = useIsMobile();
  const showConfirmationModal = useConfirmationModal();
  const { onClearOrders } = useOrderStatus();
  const { modalRef } = useModalTabController(".menu-item", closeMenu, true);
  const showLogoutModal = () => {
    closeMenu();
    const modalTitle = "Log out?";
    const modalMessage =
      "If you log out, this screen will be reset and you will need to register all over again.";
    const logoutAction = () => {
      deleteAllValues();
      onClearOrders();
      // DO NOT CHANGE - Using location.reload rather than navigate as Native App is
      // using this trigger to unsubscribe from updates
      document.location.reload();
    };
    showConfirmationModal(modalTitle, modalMessage, logoutAction);
  };

  return (
    <>
      <Overlay zIndex={3} />
      <div ref={modalRef}>
        <styled.MenuWrapper isMobile={isMobile}>
          <styled.MenuHeader>
            <styled.CloseButtonWrapper>
              <styled.MenuButton
                glyph="x"
                size="20"
                onClick={closeMenu}
                onKeyUp={(event: KeyboardEvent) => {
                  console.log("modalref", modalRef);
                  if (event.key === "Enter") {
                    closeMenu();
                  }
                }}
                aria-label="Close Menu"
                role="button"
                className="menu-item"
                tabIndex={0}
              />
            </styled.CloseButtonWrapper>
          </styled.MenuHeader>

          <styled.MenuLinksWrapper>
            <styled.MenuLink
              onClick={() => {
                closeMenu();
                navigate("/history");
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  closeMenu();
                  navigate("/history");
                }
              }}
              className="menu-item"
            >
              History
            </styled.MenuLink>

            <styled.MenuLink
              onClick={() => {
                showLogoutModal();
              }}
              className="menu-item"
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  showLogoutModal();
                }
              }}
              role="button"
            >
              Log out
            </styled.MenuLink>
          </styled.MenuLinksWrapper>

          <styled.VersionTag>
            {store ? (
              <>
                Store name: {store.storeName}
                <br />
                Destination: {store.destinationId}
                <br />
              </>
            ) : (
              ""
            )}
            {`Version ${VERSION_NUMBER}`}
          </styled.VersionTag>
        </styled.MenuWrapper>
      </div>
    </>
  );
};
