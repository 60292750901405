import React from "react";
import Logo from "../../resources/Bluedot_Logo.png";
import * as styled from "./styled";
import DropdownMenu from "../DropdownMenu";
import { useStore } from "../../hooks/useSharedContext";
import useIsMobile from "../../hooks/useIsMobile";

export default ({ ariaHidden }: { ariaHidden?: boolean }) => {
  const store = useStore();
  const isMobile = useIsMobile();
  return (
    <styled.HeaderWrapper aria-hidden={ariaHidden} role="banner">
      <styled.LogoImage
        src={Logo}
        title="Bluedot Logo"
        alt="Bluedot Logo"
        isMobile={isMobile}
      />
      <styled.HeaderText>{store?.storeName}</styled.HeaderText>
      <styled.MenuButtonWrapper>
        <DropdownMenu store={store} />
      </styled.MenuButtonWrapper>
    </styled.HeaderWrapper>
  );
};
