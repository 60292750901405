import React from "react";
import * as styled from "./styled";
import useIsMobile from "../../hooks/useIsMobile";

export default ({
  hsFields,
  orderCardFields,
}: {
  hsFields: Record<string, string> | undefined;
  orderCardFields: string[];
}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    if (!hsFields) {
      return <styled.MobileOrderCardFields />;
    }

    return (
      <styled.MobileOrderCardFields>
        {Boolean(orderCardFields[0] && hsFields[orderCardFields[0]]) ? (
          <styled.MobileOrderCardFieldsWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[0]]}
            </styled.OrderCardFieldText>
          </styled.MobileOrderCardFieldsWrapper>
        ) : (
          <styled.MobileOrderCardFieldsPlaceholder />
        )}

        {Boolean(orderCardFields[1] && hsFields[orderCardFields[1]]) ? (
          <styled.MobileOrderCardFieldsWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[1]]}
            </styled.OrderCardFieldText>
          </styled.MobileOrderCardFieldsWrapper>
        ) : (
          <styled.MobileOrderCardFieldsPlaceholder />
        )}
        {Boolean(orderCardFields[2] && hsFields[orderCardFields[2]]) ? (
          <styled.MobileOrderCardFieldsWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[2]]}
            </styled.OrderCardFieldText>
          </styled.MobileOrderCardFieldsWrapper>
        ) : (
          <styled.MobileOrderCardFieldsPlaceholder />
        )}

        {Boolean(orderCardFields[3] && hsFields[orderCardFields[3]]) ? (
          <styled.MobileOrderCardFieldsWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[3]]}
            </styled.OrderCardFieldText>
          </styled.MobileOrderCardFieldsWrapper>
        ) : (
          <styled.MobileOrderCardFieldsPlaceholder />
        )}
      </styled.MobileOrderCardFields>
    );
  }

  if (!hsFields) {
    return <styled.InlineOrderCardFields />;
  }

  return (
    <styled.InlineOrderCardFields>
      <styled.InlineOrderCardFieldsRow>
        {Boolean(orderCardFields[0] && hsFields[orderCardFields[0]]) ? (
          <styled.InlineOrderCardFieldWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[0]]}
            </styled.OrderCardFieldText>
          </styled.InlineOrderCardFieldWrapper>
        ) : (
          <styled.InlineOrderCardFieldPlaceholder />
        )}

        {Boolean(orderCardFields[1] && hsFields[orderCardFields[1]]) ? (
          <styled.InlineOrderCardFieldWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[1]]}
            </styled.OrderCardFieldText>
          </styled.InlineOrderCardFieldWrapper>
        ) : (
          <styled.InlineOrderCardFieldPlaceholder />
        )}
      </styled.InlineOrderCardFieldsRow>
      <styled.InlineOrderCardFieldsRow>
        {Boolean(orderCardFields[2] && hsFields[orderCardFields[2]]) ? (
          <styled.InlineOrderCardFieldWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[2]]}
            </styled.OrderCardFieldText>
          </styled.InlineOrderCardFieldWrapper>
        ) : (
          <styled.InlineOrderCardFieldPlaceholder />
        )}

        {Boolean(orderCardFields[3] && hsFields[orderCardFields[3]]) ? (
          <styled.InlineOrderCardFieldWrapper>
            <styled.OrderCardFieldText>
              {hsFields[orderCardFields[3]]}
            </styled.OrderCardFieldText>
          </styled.InlineOrderCardFieldWrapper>
        ) : (
          <styled.InlineOrderCardFieldPlaceholder />
        )}
      </styled.InlineOrderCardFieldsRow>
    </styled.InlineOrderCardFields>
  );
};
