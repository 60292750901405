import styled from "styled-components";

/*
    Note: Many of these styles are imported by the UrlLinkingFailed component as well
    Please ensure any changes made here are also validated in UrlLinkingFailed
 */

interface IsMobileProps {
  isMobile: boolean;
}

export const BackgroundPanel = styled.main`
  background-color: ${(props) => props.theme.colors.DARK_BACKGROUND};
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const BackgroundPanelMobile = styled.main`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  position: relative;
`;

export const OneTimeCodeWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  width: 40rem;
  padding: 3rem;
  text-align: center;
`;

export const OneTimeCodeMobilePanel = styled.div`
  margin: auto;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const CenterAlignmentWrapper = styled.main`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const OneTimeCodeExplanationText = styled.h1`
  font-size: 1.3rem;
`;

export const OneTimeCodeText = styled.h2`
  font-size: 5rem;
`;

export const OneTimeCodeSmallText = styled.h1`
  margin: 3rem 0 2rem 0;
  font-size: 1.3rem;
`;

export const LogoImage = styled.img`
  height: 3rem;
  width: auto;
`;

export const LogoWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FooterWrapper = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;

  a {
    color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
    margin-left: 1%;
  }
`;

export const MobileFooterWrapper = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;

  a {
    color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  }
`;

export const GenerateCodeButtonWrapper = styled.div<IsMobileProps>`
  margin: 2rem auto;
  width: 80%;

  ${(props) => {
    if (props.isMobile) {
      return `
              width: 100%;
              button {
                font-size: 1.5rem;
                padding: 1.5rem;
              }
          `;
    }
  }}
`;

export const RegionSelectorWrapper = styled.div<IsMobileProps>`
  margin: auto;
  margin-bottom: 3rem;
  width: ${(props) => (props.isMobile ? "100%" : "80%")};
`;
