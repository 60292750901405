import {
  getApproximateArrivalTime,
  formatTimestamp,
} from "../../services/orderCalculations";
import stringTemplateFormat from "string-template";
import { OrderDetails } from "../../types/OrderDetailsType";
import {
  FeatureToggles,
  StateDefinition,
} from "../../types/StateDefinitionType";
import { getAlertTimer } from "../../services/utils";

const createStringTemplateValues = (order: OrderDetails) => {
  const arrivalTimestamp = getApproximateArrivalTime(
    order.lastUpdateTime,
    order.eta
  );
  const arrivalTime = formatTimestamp(arrivalTimestamp.toISOString());
  const transitionTimestamp = order.lastTransitionTime;
  const formattedLastTransitionTime = formatTimestamp(transitionTimestamp);
  const etaMinutesInteger = order.eta ? Math.round(order.eta / 60) : "-";
  const etaDirectionText =
    order.etaDirection === "lessThan" ? "LESS THAN" : "MORE THAN";
  const etaMinutes =
    etaMinutesInteger > 1
      ? `${etaDirectionText} ${etaMinutesInteger} MINUTES`
      : `${etaDirectionText} ${etaMinutesInteger} MINUTE`;
  let etaDirectionPreposition = "-";
  if (order.etaDirection) {
    if (order.etaDirection === "lessThan") {
      etaDirectionPreposition = "at";
    } else {
      etaDirectionPreposition = "after";
    }
  }
  const templateValues: Record<
    string,
    string | number | string[] | Record<string, string> | boolean
  > = {
    ...order.hsFields,
    ...order,
    arrivalTime,
    formattedLastTransitionTime,
    etaMinutes,
    etaDirectionPreposition,
  };

  if (order.waitTimeStart) {
    templateValues.formattedWaitTimeStart = formatTimestamp(
      order.waitTimeStart
    );
  }

  return templateValues;
};

export const generateHeaderText = (
  orderDetails: OrderDetails,
  stateDefinition: StateDefinition
) => {
  if (!stateDefinition.displayState) {
    console.log("Order does not have display state", orderDetails);
    return "Something Went Wrong";
  }
  const templateValues = createStringTemplateValues(orderDetails);
  return stringTemplateFormat(
    stateDefinition.displayState.orderDetailsTitle,
    templateValues
  );
};

export const generateSuccessMessageText = (orderDetails: OrderDetails) => {
  const currentTimestamp = new Date().toISOString();
  const currentTime = formatTimestamp(currentTimestamp);
  const SUCCESS_MESSAGE_TEMPLATE = `{customerName}'s order was completed at ${currentTime}`;
  const templateValues = createStringTemplateValues(orderDetails);
  return stringTemplateFormat(SUCCESS_MESSAGE_TEMPLATE, templateValues);
};

export const generateStatusDescriptionText = (
  orderDetails: OrderDetails,
  stateDefinition: StateDefinition
) => {
  if (!stateDefinition.displayState) {
    console.log("Order does not have display state", orderDetails);
    return "Something Went Wrong";
  }
  const templateValues = createStringTemplateValues(orderDetails);
  return stringTemplateFormat(
    stateDefinition.displayState.orderDetailsSummary,
    templateValues
  );
};

export const shouldOrderShowSuccessMessage = (
  orderDetails: OrderDetails,
  targetState: StateDefinition,
  statesFeatures: FeatureToggles | undefined
) => {
  const targetTimer = getAlertTimer(orderDetails, targetState, statesFeatures);
  if (targetTimer) {
    return targetTimer.showSuccessMessage;
  }
  return false;
};

export const sortCustomDataFields = (
  hsFieldsPriority: string[] | undefined,
  hsFields: Record<string, string>
) => {
  let orderedFields: Record<number, string> = {};
  let sortedFields: string[] = [];

  Object.keys(hsFields).forEach((key) => {
    if (hsFieldsPriority) {
      for (let index = 0; index < hsFieldsPriority.length; index++) {
        if (
          hsFieldsPriority[index].replace(/\s+/g, "").toLowerCase() ===
          key.replace(/\s+/g, "").toLowerCase()
        ) {
          orderedFields[index] = key;
        }
      }
    }
  });

  sortedFields = Object.entries(orderedFields)
    .sort(([indexA], [indexB]) => Number(indexA) - Number(indexB))
    .map(([, key]) => key);

  const unorderedFields: string[] = [];

  Object.keys(hsFields).forEach((fieldKey) => {
    if (!sortedFields.includes(fieldKey)) {
      unorderedFields.push(fieldKey);
    }
  });

  const alphabeticallySortedFields = unorderedFields.sort((a, b) =>
    a.localeCompare(b)
  );
  sortedFields.push(...alphabeticallySortedFields);

  return sortedFields;
};
