import { OrderDetails } from "../types/OrderDetailsType";

export interface GroupDetails {
  groupName: string;
  orderCount: number;
  groupNumber: number;
}

// The key for OrderGroupMapping should be the orderGroupId
export type OrderGroupMapping = Record<string, GroupDetails>;

/*
    This returns all the different groups for the current day, ordered sequentially from when
    any order in the group first came in
    Any orders from previous days are ignored
 */
export const createOrderGroupMapping = (
  orders: OrderDetails[],
  groupPrefix: string
) => {
  const orderGroupMapping: OrderGroupMapping = {};
  const currentDay = new Date();
  currentDay.setHours(0, 0);
  const startOfDay = currentDay.toISOString();
  // This will sort orders with the earliest orderActivationTime first
  const sortedGroupOrders = orders
    .filter((order) =>
      Boolean(order?.hsFields && order.hsFields["orderGroupId"])
    )
    .filter((order) => order.orderActivationTime > startOfDay)
    .sort(
      (a, b) =>
        new Date(a.orderActivationTime).getTime() -
        new Date(b.orderActivationTime).getTime()
    );

  let foundGroups = 0;
  sortedGroupOrders.forEach((order: OrderDetails) => {
    const groupId = order.hsFields!["orderGroupId"];
    let targetGroup: GroupDetails | undefined = orderGroupMapping[groupId];
    if (!targetGroup) {
      foundGroups++;
      const newGroup: GroupDetails = {
        groupName: `${groupPrefix}${foundGroups}`,
        orderCount: 1,
        groupNumber: foundGroups,
      };
      orderGroupMapping[groupId] = newGroup;
    } else {
      targetGroup.orderCount++;
    }
  });

  return orderGroupMapping;
};

export const getGroupColor = (groupNumber: number) => {
  const GROUP_COLORS = [
    "#AA27D8",
    "#4255FF",
    "#F54900",
    "#1D7D8C",
    "#AF7F18",
    "#000000",
  ];
  const colorIndex = (groupNumber - 1) % GROUP_COLORS.length;
  return GROUP_COLORS[colorIndex];
};

// This returns true if the group exists and contains more than one order
export const shouldRenderOrderGroup = (
  order: OrderDetails,
  orderGroups: OrderGroupMapping | undefined
) => {
  if (!orderGroups) {
    return false;
  }
  if (!order.hsFields) {
    return false;
  }
  const targetGroupId = order.hsFields["orderGroupId"];
  if (!targetGroupId) {
    return false;
  }
  const targetGroup = orderGroups[targetGroupId];
  if (!targetGroup) {
    return false;
  }
  return targetGroup.orderCount > 1;
};

// This returns the target group even if it has only 1 order
// Returns undefined if not group associated with order
export const getOrderGroup = (
  order: OrderDetails,
  orderGroups: OrderGroupMapping | undefined
) => {
  if (!orderGroups) {
    return;
  }
  if (!order.hsFields) {
    return;
  }
  const targetGroupId = order.hsFields["orderGroupId"];
  if (!targetGroupId) {
    return;
  }
  return orderGroups[targetGroupId];
};
