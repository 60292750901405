import { useEffect } from "react";

/*
    The purpose of this function to to prevent scaling behaviour when using the Native App version of
    Hello Screens only. While scaling is an important feature via a normal browser view and should not be
    disabled for accessibility reasons, when using an app, in particular on iOS, the pinch behaviour is easy
    to do accidentally and does not provide an expected experience for a native app. For this reason native apps
    will set a value in localStorage so they can be identified. If this value is present then disable scaling.

    The apps should be setting this value at first load so if not identified straight away then waiting 1 minute and trying
    again should allow plenty of time.
 */
export default () => {
  useEffect(() => {
    const disableScaling = () => {
      const meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      const head = document.getElementsByTagName("head")[0];
      head.appendChild(meta);
      console.log("scaling disabled");
    };
    if (localStorage.getItem("appName")) {
      disableScaling();
    }
  }, []);
};
