import React, { useEffect, useState } from "react";
import { navigate, useLocation } from "@reach/router";
import Logo from "../../resources/Bluedot_Logo.png";
import Button from "../Button";
import * as styled from "./styled";
import * as api from "../../services/api";
import * as errorFactory from "../../services/errorFactory";
import Spinner from "../Spinner";
import useIsMobile from "../../hooks/useIsMobile";
import * as persistenceManager from "../../services/persistenceManager";
import { Region } from "../../types/RegionType";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";
import { useOrderStatus } from "../../hooks/useSharedContext";

interface SsoLinkProps {
  jwtToken: string;
  region: Region;
  loginError: undefined | Error;
}
export default ({ jwtToken, region, loginError }: SsoLinkProps) => {
  const isMobile = useIsMobile();
  const [error, setError] = useState<undefined | Error>();
  const { hideMainContent } = useAriaHideMainContent();
  const [storeCode, setStoreCode] = useState("");
  const [loadingResponse, setLoadingResponse] = useState(false);
  const { resetLoadingFirstRequest } = useOrderStatus();
  const location = useLocation();

  const connectToStore = async () => {
    const projectId = persistenceManager.getSsoProjectId();
    if (!storeCode || !jwtToken) {
      return;
    }
    setLoadingResponse(true);
    try {
      const token = await api.getAuthTokenViaSso(
        region,
        jwtToken,
        storeCode,
        projectId
      );
      persistenceManager.setRegion(region);
      persistenceManager.storeRegistrationCode("");
      persistenceManager.storeAuthToken(token);
      resetLoadingFirstRequest();
      persistenceManager.setSsoLink(location.href);
      navigate("/active");
    } catch (err) {
      if ((err as Error).message === "Request failed with status code 404") {
        setError(errorFactory.createSsoDestinationNotFoundError());
      } else if ((err as Error).message === "Network Error") {
        setError(errorFactory.createNetworkError());
      } else {
        console.error(err);
        setError(errorFactory.createUnknownError());
      }
      setLoadingResponse(false);
    }
  };

  useEffect(() => {
    document.title = "Hello Screens - Link via SSO";
  }, []);

  const loginUrl = persistenceManager.getSsoLoginPage();

  const renderPageContent = (
    ssoJwtToken: string,
    apiError: Error | undefined
  ) => {
    if (!ssoJwtToken && !apiError && !loginError) {
      return (
        <>
          <Spinner />
          <styled.LoadingText>Loading ...</styled.LoadingText>
        </>
      );
    }
    if (
      loginError &&
      loginError.name === errorFactory.NETWORK_CONNECTION_ERROR
    ) {
      return (
        <>
          <styled.SsoLinkText>
            {loginUrl
              ? "Network connection failed. Check your network connection and click below to retry"
              : "Login details not found. Please login using the SSO login link provided."}
          </styled.SsoLinkText>
          {loginUrl && (
            <Button onClick={() => navigate(loginUrl)}>Retry Login</Button>
          )}
        </>
      );
    }
    if (
      loginError &&
      loginError.name !== errorFactory.NETWORK_CONNECTION_ERROR
    ) {
      return (
        <>
          <styled.SsoLinkText>
            {loginUrl
              ? "Login failed. Please try again."
              : "Login details not found. Please login using the SSO login link provided."}
          </styled.SsoLinkText>
          {loginUrl && (
            <Button onClick={() => (window.location.href = loginUrl)}>
              Retry Login
            </Button>
          )}
        </>
      );
    }
    if (ssoJwtToken && apiError?.name !== errorFactory.UNKNOWN_ERROR) {
      return (
        <>
          <styled.SsoLinkText>
            Welcome! Please enter the store code you would like to connect
          </styled.SsoLinkText>
          <styled.StoreCodeInput
            placeholder="Store Code"
            value={storeCode}
            onChange={(event) => setStoreCode(event.target.value)}
          />
          <styled.ButtonWithErrorWrapper isMobile={isMobile}>
            <styled.ErrorMessageWrapper>
              {apiError && (
                <styled.ErrorMessage>
                  {apiError.name ===
                  errorFactory.SSO_DESTINATION_NOT_FOUND_ERROR
                    ? "Store code not found. Please double check the code and try again"
                    : "Network connection failed. Check your connection and try again"}
                </styled.ErrorMessage>
              )}
            </styled.ErrorMessageWrapper>
            <Button
              onClick={connectToStore}
              disabled={!storeCode || loadingResponse}
            >
              Open Hello Screens
            </Button>
          </styled.ButtonWithErrorWrapper>
        </>
      );
    }
    if (ssoJwtToken && apiError?.name === errorFactory.UNKNOWN_ERROR) {
      return (
        <>
          <styled.SsoLinkText>
            Login failed. You do not have access to the target project. Please
            contact your support team
          </styled.SsoLinkText>
        </>
      );
    }
  };

  if (isMobile) {
    return (
      <styled.BackgroundPanelMobile aria-hidden={hideMainContent}>
        <styled.SsoLoginMobilePanel>
          <styled.CenterAlignmentWrapper>
            <styled.SsoLinkWrapper>
              <styled.LogoWrapper>
                <styled.LogoImage
                  src={Logo}
                  title="Bluedot Logo"
                  alt="Bluedot Logo"
                />
              </styled.LogoWrapper>
              {renderPageContent(jwtToken, error)}
            </styled.SsoLinkWrapper>
          </styled.CenterAlignmentWrapper>
        </styled.SsoLoginMobilePanel>
      </styled.BackgroundPanelMobile>
    );
  }
  return (
    <styled.BackgroundPanel aria-hidden={hideMainContent}>
      <styled.CenterAlignmentWrapper>
        <styled.SsoLinkWrapper>
          <styled.LogoWrapper>
            <styled.LogoImage
              src={Logo}
              title="Bluedot Logo"
              alt="Bluedot Logo"
            />
          </styled.LogoWrapper>
          {renderPageContent(jwtToken, error)}
        </styled.SsoLinkWrapper>
      </styled.CenterAlignmentWrapper>
    </styled.BackgroundPanel>
  );
};
