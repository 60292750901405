import styled from "styled-components";
import { DESKTOP_MIN_WIDTH, SMALL_PHONE_MAX_WIDTH } from "../../../../constants/layout";

export const ChangeOrderStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;

  @media only screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
    min-width: 45rem;
  }
`;

export const CurrentStatusWrapper = styled.div`
  display: flex;
  margin: auto;
`;

export const CurrentStatusLabel = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 1.5rem;
`;

export const CurrentStatusText = styled.h2`
  font-size: 1.2rem;
`;

export const SelectNewStatusText = styled.h2`
  font-size: 1rem;
  text-align: left;
  margin-left: 2rem;
`;

export const StatusRadioButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem 2rem;
  padding: 0 0.5rem;
  margin-bottom: 1.5rem;

  @media only screen and (min-width: ${DESKTOP_MIN_WIDTH}) { 
    padding: 0 3rem;
  }

  @media only screen and (max-width: ${SMALL_PHONE_MAX_WIDTH}) { 
    grid-template-columns: 1fr;
    padding: 0 2rem;
  }
`;

export const StatusButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 4rem;
  border: solid ${(props) => props.theme.colors.PRIMARY_TEXT} 2px;
  border-radius: 6px;
  padding: 0 0.8rem;

  @media only screen and (max-width: ${SMALL_PHONE_MAX_WIDTH}) { 
    min-height: 5rem;
  }
`;

export const StatusButtonLabel = styled.label`
  text-align: left;
  max-width: 8rem;
  margin-left: 1rem;

  @media only screen and (min-width: ${DESKTOP_MIN_WIDTH}), (max-width: ${SMALL_PHONE_MAX_WIDTH}) { 
    // to centre text exactly disregarding radio position
    position: absolute;
    left: 0; right: 0; 
    margin-left: auto; margin-right: auto; 
    width: fit-content;
    max-width: none;
  } 
`;

export const StatusRadioButton = styled.input`
  display: flex;
  justify-self: flex-start;
  align-self: center;
  transform: scale(1.5);
`;
