import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
  colors: {
    NEUTRAL_PANEL: "#EBEFF2",
    DARKER_NEUTRAL_PANEL: "#ECECFD",
    INVERTED_TEXT_NEUTRAL_PANEL: "rgba(0, 0, 0, 0.4)",
    TEXT_BACKGROUND: "#FFFFFF",
    PRIMARY_TEXT: "#18182D",
    PRIMARY_ACCENT: "#7B10F4",
    SECONDARY_ACCENT: "#279EBA",
    TERTIARY_ACCENT: "#D82D59",
    SUCCESS: "#45A04A",
    WARNING: "#E17A19",
    ERROR: "#D82D59",
    BORDER_COLOR: "rgba(151, 151, 151, 0.12)",
    SHADOW_COLOR: "rgba(0, 0, 0, 0.06)",
    DARKENED_SECONDARY_ACCENT: "#008CB3",
    DISABLED_TEXT: "#6B6B6B",
    DARK_BACKGROUND: "rgb(24, 24, 45)",
  },
};

export { myTheme };
