import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants/layout";

export const HeaderWrapper = styled.div`
  width: 100%;
  min-height: ${HEADER_HEIGHT};
  display: inline-flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  box-shadow: 0px 1px 2px ${(props) => props.theme.colors.SHADOW_COLOR};
`;

export const LogoImage = styled.img<{ isMobile: boolean }>`
  height: 2rem;
  margin: auto ${(props) => (props.isMobile ? "1" : "3")}rem;
`;

export const HeaderText = styled.p`
  font-size: 1.5rem;
  margin: auto;
  margin-left: 0;
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

`;

export const MenuButtonWrapper = styled.div`
  margin: auto 2rem;
`;
