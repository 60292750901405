import * as activeOrdersStyles from "../ActiveOrdersDisplay/styled";
import styled from "styled-components";

export const PageTitleWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
`;

export const PageTitle = styled.h2`
  font-size: 1.2rem;
`;

export const NoOrdersFoundText = styled.h2`
  margin: 2rem 0;
  text-align: left;
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.DISABLED_TEXT};
`;

export default {
  ...activeOrdersStyles,
  PageTitle,
  PageTitleWrapper,
  NoOrdersFoundText,
};
