import {
  StateDefinition,
  StateDefinitionConfig,
} from "../types/StateDefinitionType";
import { StateDefinitionsResponse } from "../services/api";

export const mockStateDefinitions: StateDefinitionConfig = {
  hsFieldsPriority: ["Parking Bay", "Mobile Number", "Car Model"],
  states: [
    {
      name: "Registered",
      displayState: null,
      progressionLevel: 1,
      canAcknowledge: false,
      eventTriggers: ["REGISTRATION"],
      isActive: true,
      isError: false,
      canForceTransition: false,
      notificationTypes: [],
    },
    {
      name: "OnTheWay", // State name that's shared in code.
      displayState: {
        orderDetailsTitle: "ON THE WAY",
        orderDetailsSummary: "{customerName} is on the way",
        categoryTitle: "On The Way",
        stateDisplayName: "On The Way",
        color: "#7B10F4",
        showCollectionTime: true,
      },
      progressionLevel: 10,
      canAcknowledge: false,
      eventTriggers: ["TEMPO_UPDATE", "onTheWay"],
      isActive: true,
      isError: false,
      canForceTransition: true,
      notificationTypes: ["state-transition"],
    },
    {
      name: "TempoOnTheWay", // State name that's shared in code.
      displayState: {
        orderDetailsTitle: "{etaMinutes} AWAY",
        orderDetailsSummary:
          "{customerName} is arriving {etaDirectionPreposition} {arrivalTime}.",
        categoryTitle: "On The Way",
        stateDisplayName: "On The Way",
        color: "#7B10F4",
      },
      progressionLevel: 10,
      canAcknowledge: false,
      eventTriggers: ["TEMPO_UPDATE"],
      isActive: true,
      isError: false,
      analyticsCategory: "onTheWay",
      canForceTransition: false,
      notificationTypes: ["state-transition"],
    },
    {
      name: "LostConnection",
      displayState: {
        orderDetailsTitle: "LOST CONNECTION",
        orderDetailsSummary:
          "Order for {customerName} lost connection at {formattedLastTransitionTime}",
        categoryTitle: "On The Way",
        stateDisplayName: "Lost Connection",
        color: "#D82D59",
        quickButtons: [{ label: "Move To History", action: "ACKNOWLEDGE" }],
      },
      progressionLevel: 10,
      canAcknowledge: true,
      eventTriggers: ["NO_UPDATES_5_MINS"],
      isActive: false,
      isError: true,
      canForceTransition: false,
      notificationTypes: [],
    },
    {
      name: "Stopped",
      displayState: {
        orderDetailsTitle: "STOPPED",
        orderDetailsSummary:
          "Order for {customerName} stopped tracking journey at {formattedLastTransitionTime}",
        categoryTitle: "On The Way",
        stateDisplayName: "Stopped",
        color: "#D82D59",
        quickButtons: [{ label: "Move To History", action: "ACKNOWLEDGE" }],
      },
      progressionLevel: 15,
      canAcknowledge: true,
      eventTriggers: ["TEMPO_CANCELLED"],
      isActive: false,
      isError: true,
      canForceTransition: true,
      notificationTypes: ["state-transition"],
    },

    {
      name: "Timeout",
      displayState: {
        orderDetailsTitle: "TIMEOUT",
        orderDetailsSummary:
          "Order for {customerName} stopped tracking journey at {formattedLastTransitionTime}",
        categoryTitle: "On The Way",
        stateDisplayName: "Timeout",
        color: "#D82D59",
        quickButtons: [{ label: "Move To History", action: "ACKNOWLEDGE" }],
      },
      progressionLevel: 15,
      canAcknowledge: true,
      eventTriggers: ["TEMPO_TIMEOUT"],
      isActive: false,
      isError: true,
      canForceTransition: false,
      notificationTypes: ["state-transition"],
    },
    {
      name: "Arrived",
      displayState: {
        orderDetailsTitle: "CUSTOMER ARRIVED",
        orderDetailsSummary:
          "{customerName} arrived at {formattedLastTransitionTime}.",
        categoryTitle: "Arrived",
        quickButtons: [{ label: "Fulfilled", action: "Fulfilled" }],
        stateDisplayName: "Arrived",
        color: "#45A04A",
      },
      progressionLevel: 20,
      canAcknowledge: true,
      eventTriggers: ["GEOTRIGGER", "arrival"],
      isActive: true,
      isError: false,
      canForceTransition: true,
      notificationTypes: ["state-transition"],
    },
    {
      name: "Fulfilled",
      displayState: {
        categoryTitle: "Arrived",
        orderDetailsSummary: "{customerName}'s order is completed.",
        orderDetailsTitle: "FULFILLED",
        stateDisplayName: "Fulfilled",
        color: "#5AB75F",
      },
      progressionLevel: 100,
      canAcknowledge: false,
      eventTriggers: ["completed"],
      isActive: false,
      isError: false,
      canForceTransition: true,
      notificationTypes: ["state-transition"],
      // isVerified: true,
    },
  ],

  categories: ["Arrived", "On The Way"],

  features: {
    hideSearchBar: false,
    waitTime: {
      startState: ["Arrived"],
      endState: ["Fulfilled"],
      // goal: 100000000000000,
      // alert: 5,
    },
    orderGrouping: {
      enabled: true,
      groupPrefix: "GROUP ",
    },
  },

  orderCardFields: ["Car Model", "Car Color", "Car Plate", "Parking Bay"],

  verificationErrorCodes: [
    {
      errorCode: "FRIEND",
      description: "Order is for someone else (relative/friend)",
      shouldDisplay: true,
    },
    {
      errorCode: "BUSINESS",
      description: "Order is for a business or organisation",
      shouldDisplay: true,
    },
    {
      errorCode: "DONT-KNOW",
      description: "Customer can't remember or doesn't know.",
      shouldDisplay: true,
    },
    {
      errorCode: "UNWILLING",
      description: "Customer is unwilling to provide.",
      shouldDisplay: true,
    },
    {
      errorCode: "OTHER",
      description: "Other",
      shouldDisplay: true,
    },
  ],
};

export const mockStateDefinitionsResponse: StateDefinitionsResponse = {
  stateDefinitions: mockStateDefinitions,
  stateHash: undefined,
};

export const mockStateDictionary = mockStateDefinitions.states.reduce(
  (accumulator, currentValue) => {
    accumulator[currentValue.name] = currentValue;
    return accumulator;
  },
  {} as Record<string, StateDefinition>
);

export const mockGetStatesResponse = {
  stateDefinitions: mockStateDefinitions,
  stateHash: undefined,
};

export const mockRetailStateDefinitionsResponse: StateDefinitionsResponse = {
  stateHash: undefined,
  stateDefinitions: {
    hsFieldsPriority: [
      "Customer Name",
      "Order Number",
      "Collection Window",
      "Van Number",
      "Sequence Number",
      "Ambient",
      "Bulk Ambient",
      "Chilled",
      "Bulk Chilled",
      "Frozen",
      "Hot Food",
      "Restricted",
      "Order Type",
    ],
    states: [
      {
        name: "Registered",
        displayState: null,
        progressionLevel: 1,
        canAcknowledge: false,
        eventTriggers: ["REGISTRATION"],
        isActive: true,
        isError: false,
        analyticsCategory: "registered",
        canForceTransition: false,
        notificationTypes: [],
      },
      {
        name: "OnTheWay",
        displayState: {
          orderDetailsTitle: "ON THE WAY",
          orderDetailsSummary: "{customerName} is on the way",
          categoryTitle: "On The Way",
          stateDisplayName: "On The Way",
          color: "#7B10F4",
        },
        progressionLevel: 10,
        canAcknowledge: false,
        eventTriggers: ["NO_UPDATES_5_MINS", "TEMPO_TIMEOUT", "onTheWay"],
        isActive: true,
        isError: false,
        analyticsCategory: "onTheWay",
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
      {
        name: "TempoOnTheWay",
        displayState: {
          orderDetailsTitle: "{etaMinutes} AWAY",
          orderDetailsSummary:
            "{customerName} is arriving {etaDirectionPreposition} {arrivalTime}.",
          categoryTitle: "On The Way",
          stateDisplayName: "On The Way",
          color: "#7B10F4",
        },
        progressionLevel: 10,
        canAcknowledge: false,
        eventTriggers: ["TEMPO_UPDATE"],
        isActive: true,
        isError: false,
        analyticsCategory: "onTheWay",
        canForceTransition: false,
        notificationTypes: ["state-transition"],
      },
      {
        name: "Stopped",
        displayState: {
          orderDetailsTitle: "STOPPED",
          orderDetailsSummary:
            "{customerName} stopped tracking journey at {formattedLastTransitionTime}",
          categoryTitle: "On The Way",
          stateDisplayName: "Stopped",
          color: "#D82D59",
          quickButtons: [
            {
              label: "Move To History",
              action: "ACKNOWLEDGE",
            },
          ],
        },
        progressionLevel: 15,
        canAcknowledge: true,
        eventTriggers: ["TEMPO_CANCELLED"],
        isActive: false,
        isError: true,
        analyticsCategory: "haltFail",
        canForceTransition: false,
        notificationTypes: ["state-transition"],
      },
      {
        name: "Cancelled",
        displayState: {
          orderDetailsTitle: "CANCELLED",
          orderDetailsSummary: "{customerName}'s order was cancelled",
          categoryTitle: "On The Way",
          stateDisplayName: "Cancelled",
          color: "#D82D59",
        },
        progressionLevel: 25,
        canAcknowledge: false,
        eventTriggers: ["cancelled"],
        isActive: false,
        isError: true,
        analyticsCategory: "haltFail",
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
      {
        name: "Arrived",
        displayState: {
          orderDetailsSummary:
            "{customerName} arrived at {formattedLastTransitionTime}.",
          orderDetailsTitle: "CUSTOMER ARRIVED",
          categoryTitle: "Arrived",
          quickButtons: [
            {
              label: "Delivering to Customer",
              action: "DeliveringToCustomer",
            },
          ],
          stateDisplayName: "Arrived",
          color: "#45A04A",
          showSuccessMessage: false,
        },
        progressionLevel: 20,
        canAcknowledge: true,
        eventTriggers: ["GEOTRIGGER", "arrival"],
        isActive: true,
        isError: false,
        analyticsCategory: "arrived",
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
      {
        name: "DeliveringToCustomer",
        displayState: {
          orderDetailsSummary:
            "{customerName} arrived at {formattedWaitTimeStart}.",
          orderDetailsTitle: "DELIVERING TO CUSTOMER",
          categoryTitle: "Delivering to Customer",
          quickButtons: [
            {
              label: "Complete",
              action: "Completed",
            },
          ],
          stateDisplayName: "Delivering",
          color: "#45A04A",
          showSuccessMessage: true,
        },
        progressionLevel: 21,
        canAcknowledge: false,
        eventTriggers: ["deliveringToCustomer"],
        isActive: true,
        isError: false,
        analyticsCategory: "arrived",
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
      {
        name: "Completed",
        displayState: {
          orderDetailsSummary: "{customerName}'s order is completed.",
          categoryTitle: "Arrived",
          orderDetailsTitle: "COMPLETED",
          stateDisplayName: "Completed",
          color: "#45A04A",
        },
        progressionLevel: 100,
        canAcknowledge: false,
        eventTriggers: ["completed"],
        isActive: false,
        isError: false,
        analyticsCategory: "haltSucceed",
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
      {
        name: "ReadyToCollect",
        displayState: {
          orderDetailsSummary: "{customerName}'s order is ready to collect.",
          categoryTitle: "Ready To Collect",
          stateDisplayName: "Ready To Collect",
          orderDetailsTitle: "READY TO COLLECT",
          color: "#16BFE6",
        },
        progressionLevel: 5,
        canAcknowledge: false,
        eventTriggers: ["pickCompleted"],
        isError: false,
        isActive: true,
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
      {
        name: "OrderOverdue",
        displayState: {
          orderDetailsSummary:
            "{customerName}'s order was not collected on time.",
          categoryTitle: "Overdue",
          stateDisplayName: "Order Overdue",
          orderDetailsTitle: "ORDER OVERDUE",
          color: "#16BFE6",
        },
        progressionLevel: 6,
        canAcknowledge: false,
        eventTriggers: ["orderOverdue"],
        isError: false,
        isActive: true,
        canForceTransition: true,
        notificationTypes: ["state-transition"],
      },
    ],
    categories: [
      "Delivering to Customer",
      "Arrived",
      "On The Way",
      "Ready To Collect",
      "Overdue",
    ],
    features: {
      hideSearchBar: false,
      waitTime: {
        startState: ["Arrived", "DeliveringToCustomer"],
        endState: ["Completed"],
        alert: 300,
        goal: 480,
      },
    },
  },
};
