import styled from "styled-components";

export const OrderTrackerWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 650;
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
`;

export const LeftHeaderCell = styled.div`
  width: 33%;
  padding-left: 2rem;
`;

export const MiddleHeaderCell = styled.div`
  width: 33%;
  text-align: center;
`;

export const HeaderText = styled.p`
  margin: 0;
`;

export const RightHeaderCell = styled.div`
  width: 33%;
  text-align: right;

  ${HeaderText} {
    padding-right: 2rem;
  }
`;

export const NoOrdersFoundText = styled.p`
  margin: 2rem 0;
  text-align: left;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.DISABLED_TEXT};
`;

export const OrderSectionHeader = styled.div`
  width: 100%;
  margin-top: 3rem;
  border-bottom: 0.2rem solid black;
`;

export const OrderSectionTitle = styled.h1`
  margin: 0.3rem 0;
  font-size: 1.17em;
`;
