import React, { useEffect } from "react";
import ConnectionStatusSnackbar from "../../components/ConnectionStatusSnackbar";
import * as styled from "./styled";
import AppHeader from "../../components/AppHeader/AppHeader";
import useIsMobile from "../../hooks/useIsMobile";
import {
  useOrderStatus,
  useStateDefinitions,
} from "../../hooks/useSharedContext";
import OrderTracker from "../../components/OrderTracker";
import EnableSoundControl from "../../components/EnableSoundControl";
import useOrderDetailsOverlay from "../../hooks/useOrderDetailsOverlay";
import OrderDetailsDisplay from "../../components/OrderDetailsDisplay/OrderDetailsDisplay";
import SearchBar from "../../components/SearchBar";
import useOrderSearchFilter from "../../hooks/useOrderSearchFilter";
import WaitTimeDisplay from "../../components/WaitTimeDisplay/WaitTimeDisplay";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";
import NavigationLinks from "../../components/NavigationLinks";

const ActiveOrdersDisplay = () => {
  const { currentOrders, historyOrders, orderGroups } = useOrderStatus();
  const {
    stateCategoryMapping,
    stateDefinitions,
    errorStates,
  } = useStateDefinitions();
  const isMobile = useIsMobile();
  const {
    orderDetailsOverlayData,
    setOrderDetailsOverlayData,
  } = useOrderDetailsOverlay(currentOrders, historyOrders);
  const { filteredOrders, searchTerm, setSearchTerm } = useOrderSearchFilter(
    currentOrders
  );
  const { hideMainContent, hideBanner } = useAriaHideMainContent();

  useEffect(() => {
    document.title = "Hello Screens - Active Orders";
  }, []);

  const activeOrdersCount = currentOrders.filter((order) => {
    return !errorStates.includes(order.status);
  }).length;

  return (
    <>
      {orderDetailsOverlayData && (
        <OrderDetailsDisplay
          orderDetails={orderDetailsOverlayData}
          hideOrderDetails={() => setOrderDetailsOverlayData(null)}
          orderGroups={orderGroups}
        />
      )}
      <AppHeader ariaHidden={hideBanner} />
      <styled.ScreenWrapper aria-hidden={hideMainContent}>
        <styled.TopBar>
          <styled.ActiveOrdersWrapper isMobile={isMobile}>
            <styled.ActiveOrdersNumber>
              {activeOrdersCount}
            </styled.ActiveOrdersNumber>
            <styled.ActiveOrdersText>Active</styled.ActiveOrdersText>
          </styled.ActiveOrdersWrapper>
          {isMobile ? (
            <EnableSoundControl />
          ) : (
            !stateDefinitions?.features?.hideSearchBar && (
              <SearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            )
          )}
          <styled.RightTopBar isMobile={isMobile}>
            {!isMobile && (
              <>
                <EnableSoundControl />
                <WaitTimeDisplay />
              </>
            )}
            <ConnectionStatusSnackbar />
          </styled.RightTopBar>
        </styled.TopBar>
        {isMobile && !stateDefinitions?.features?.hideSearchBar && (
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
        {isMobile && <WaitTimeDisplay />}
        {stateDefinitions?.categories.map((category) => {
          const targetOrders = filteredOrders.filter(
            (order) =>
              stateCategoryMapping[category] &&
              stateCategoryMapping[category].includes(order.status)
          );

          return (
            <OrderTracker
              orders={targetOrders}
              categoryTitle={category}
              showingHistory={false}
              key={category}
              setOrderDetailsOverlayData={setOrderDetailsOverlayData}
              isSearching={Boolean(searchTerm)}
              orderGroups={orderGroups}
            />
          );
        })}
        <NavigationLinks />
      </styled.ScreenWrapper>
    </>
  );
};

export default ActiveOrdersDisplay;
