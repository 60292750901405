import React from "react";
import * as styled from "./styled";

export default function Spinner() {
  return (
    <styled.SpinnerWrap>
      <styled.Spinner>
        <styled.Circular viewBox="25 25 50 50">
          <styled.Path
            cx="50"
            cy="50"
            r="10"
            fill="none"
            stroke="black"
            strokeWidth="1"
            strokeMiterlimit="10"
          />
        </styled.Circular>
      </styled.Spinner>
    </styled.SpinnerWrap>
  );
}
