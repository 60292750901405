import moment from "moment";
import { EtaDirection, OrderDetails } from "../types/OrderDetailsType";
import { AlertTimer, DisplayState } from "../types/StateDefinitionType";

export function getOrderStatusText(
  orderDetails: OrderDetails,
  isMobile: boolean,
  displayState: DisplayState,
  alertTimer: AlertTimer | undefined
): string {
  if (
    orderDetails?.scheduledTimeForCollection &&
    displayState.showCollectionTime
  ) {
    if (isMobile) {
      const collectionTimeFormat = "HH:mm";
      const collectionTime = moment(orderDetails.scheduledTimeForCollection);
      return `Scheduled at ${collectionTime.format(collectionTimeFormat)}`;
    }
    return `Pickup Scheduled`;
  }
  if (alertTimer && alertTimer.time) {
    const minutesOverdue = Math.floor(alertTimer.time / 60);
    if (minutesOverdue > 1) {
      return `Over ${minutesOverdue} minutes`;
    } else if (minutesOverdue === 1) {
      return `Over 1 minute`;
    }
  }
  if (orderDetails.eta) {
    const minutesRemaining = orderDetails.eta / 60;
    const timePeriodText = minutesRemaining > 1 ? "Minutes" : "Minute";
    if (isMobile) {
      return `${minutesRemaining} ${timePeriodText}`;
    }
    const timePrefix =
      orderDetails.etaDirection === "lessThan" ? "Less Than" : "More Than";
    return `${timePrefix} ${minutesRemaining} ${timePeriodText}`;
  } else {
    return displayState?.stateDisplayName || "Unknown";
  }
}

export function getApproximateArrivalTime(
  timestamp: string,
  eta?: number
): moment.Moment {
  // If order is completed or stopped return last update time
  if (!eta) {
    return moment(timestamp);
  }

  return moment(timestamp).add(eta, "s");
}

export function getApproximateTimeText(
  approximateArrivalTime: moment.Moment,
  etaDirection: EtaDirection | undefined,
  eta: number | undefined
): string {
  const arrivalTimeFormat = "HH:mm";
  if (!eta) {
    return `At ${approximateArrivalTime.format(arrivalTimeFormat)}`;
  }
  if (etaDirection === "lessThan") {
    return `ETA is ${approximateArrivalTime.format(arrivalTimeFormat)}`;
  }
  return `ETA is after ${approximateArrivalTime.format(arrivalTimeFormat)}`;
}

// Returns a string in the format 4:12 PM
export function formatTimestamp(timestamp: string): string {
  const dateObject = moment(timestamp);
  const displayTimeFormat = "LT";
  return dateObject.format(displayTimeFormat);
}

// Returns a string in the format 4:12 PM
export function getOrderArrivalTimeString(orderDetails: OrderDetails): string {
  // TODO: Remove the or condition for lastUpdateTime after 48 hours of deployment of dynamic states. This is just temporary
  // as during deployment not all orders will have been updated to include lastTransitionTime
  const arrivalTime = getApproximateArrivalTime(
    orderDetails.lastTransitionTime || orderDetails.lastUpdateTime,
    orderDetails.eta
  );
  const displayTimeFormat = "LT";
  return arrivalTime.format(displayTimeFormat);
}

export function getOrderArrivalTimeText(
  orderDetails: OrderDetails,
  displayState: DisplayState,
  showDate: boolean
): string {
  if (
    orderDetails.scheduledTimeForCollection &&
    displayState.showCollectionTime
  ) {
    const collectionTimeFormat = "HH:mm";
    const collectionTime = moment(orderDetails.scheduledTimeForCollection);
    return `At ${collectionTime.format(collectionTimeFormat)}`;
  }
  // If order has an eta then use lastUpdateTime, otherwise use lastTransitionTime
  const arrivalTime = orderDetails.eta
    ? getApproximateArrivalTime(orderDetails.lastUpdateTime, orderDetails.eta)
    : getApproximateArrivalTime(
        orderDetails.lastTransitionTime || orderDetails.lastUpdateTime,
        orderDetails.eta
      );
  const timeString = getApproximateTimeText(
    arrivalTime,
    orderDetails.etaDirection,
    orderDetails.eta
  );

  if (!showDate) {
    return timeString;
  }

  const dateFormat = " on DD/MMM";
  const dateString = moment(orderDetails.lastTransitionTime).format(dateFormat);
  return timeString + dateString;
}
