import styled from "styled-components";

export const SelectWrapper = styled.div`
  .region-selector {
    font-family: "Spartan", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    .region-selector__control {
      background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
      height: 3rem;
    }

    .region-selector__indicator-separator {
      width: 0;
    }

    .region-selector__option--is-focused {
      background-color: ${(props) => props.theme.colors.SECONDARY_ACCENT};
    }

    .region-selector__option--is-selected {
      background-color: ${(props) =>
        props.theme.colors.DARKENED_SECONDARY_ACCENT};
    }
  }
`;

export const RegionSelectorLabel = styled.label`
  font-size: 1.2rem;
  font-weight: 700;
`;
