import React, { useState } from "react";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import {
  playerSoundEnabled,
  enableNotificationSounds,
  disableNotificationSounds,
} from "../../services/sounds";
import useIsMobile from "../../hooks/useIsMobile";
import * as styled from "./styled";
export default () => {
  const [updatingSound, setUpdatingSound] = useState(false);
  const isMobile = useIsMobile();

  const toggleSound = async () => {
    if (updatingSound) {
      return;
    }
    if (playerSoundEnabled) {
      setUpdatingSound(true);
      await disableNotificationSounds();
      setUpdatingSound(false);
    } else {
      setUpdatingSound(true);
      await enableNotificationSounds();
      setUpdatingSound(false);
    }
  };

  return (
    <>
      <styled.SoundControlWrapper
        isMobile={isMobile}
        onClick={() => toggleSound()}
        onKeyUp={(keyEvent) => {
          if (keyEvent.key === "Enter") {
            toggleSound();
          }
        }}
        tabIndex={0}
        soundEnabled={playerSoundEnabled}
        role="button"
        id="toggle-sounds-button"
      >
        <Icon
          glyph={playerSoundEnabled ? "speaker-disabled" : "speaker-enabled"}
          size="24"
          alt={
            playerSoundEnabled
              ? "Speaker Disabled Icon"
              : "Speaker Enabled Icon"
          }
        />
        <styled.SoundControlText htmlFor="toggle-sounds-button">
          {playerSoundEnabled ? "Disable alerts" : "Enable alerts"}
        </styled.SoundControlText>
      </styled.SoundControlWrapper>
    </>
  );
};
