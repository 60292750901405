import { createRef, RefObject, useEffect, useState } from "react";

export default (
  tabItemSelector: string,
  onEscapeKeyPressed: () => void,
  keyCaptureOn: boolean
) => {
  const [selectedElementIndex, setSelectedElementIndex] = useState<
    undefined | number
  >(undefined);
  const modalRef = createRef() as RefObject<HTMLDivElement>;

  const handleTabKey = (e: any) => {
    e.preventDefault();

    const focusableModalElements:
      | NodeListOf<HTMLElement>
      | never[] = modalRef.current
      ? modalRef.current.querySelectorAll(tabItemSelector)
      : [];
    let targetElement;
    if (selectedElementIndex === undefined) {
      if (!e.shiftKey) {
        setSelectedElementIndex(0);
        targetElement = focusableModalElements[0];
      } else {
        setSelectedElementIndex(focusableModalElements.length - 1);
        targetElement =
          focusableModalElements[focusableModalElements.length - 1];
      }
    } else {
      if (!e.shiftKey) {
        if (selectedElementIndex + 1 < focusableModalElements.length) {
          setSelectedElementIndex(selectedElementIndex + 1);
          targetElement = focusableModalElements[selectedElementIndex + 1];
        } else {
          setSelectedElementIndex(0);
          targetElement = focusableModalElements[0];
        }
      } else {
        if (selectedElementIndex - 1 < 0) {
          setSelectedElementIndex(focusableModalElements.length - 1);
          targetElement =
            focusableModalElements[focusableModalElements.length - 1];
        } else {
          setSelectedElementIndex(selectedElementIndex - 1);
          targetElement = focusableModalElements[selectedElementIndex - 1];
        }
      }
    }
    // @ts-ignore
    targetElement && targetElement.focus();
  };

  const keyListenersMap: Record<string, (e?: any) => void> = {
    Escape: onEscapeKeyPressed,
    Tab: handleTabKey,
  };

  useEffect(() => {
    function keyListener(e: any) {
      const listener = keyListenersMap[e.key];
      return listener && listener(e);
    }

    function cancelDefault(e: any) {
      const NUMBER_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (!e.key.startsWith("Arrow") && !NUMBER_KEYS.includes(e.key)) {
        e.preventDefault();
      }
    }

    if (keyCaptureOn) {
      document.addEventListener("keydown", cancelDefault);
      document.addEventListener("keyup", keyListener);

      return () => {
        document.removeEventListener("keyup", keyListener);
        document.removeEventListener("keydown", cancelDefault);
      };
    }
  });

  return {
    modalRef,
    setSelectedElementIndex,
  };
};
