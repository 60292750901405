import React, { useEffect, useState } from "react";
import OrderCard from "../OrderCard/OrderCard";
import * as styled from "./styled";
import { OrderDetails } from "../../types/OrderDetailsType";
import { AlertTimer } from "../../types/StateDefinitionType";
import useIsMobile from "../../hooks/useIsMobile";

interface ArrivedOrderCardProps {
  orderDetails: OrderDetails;
  openOrderDetailsOverlay: () => void;
  shouldAnimate: boolean;
  animationStartOffset: number;
  isOnline: boolean;
  alertTimer: AlertTimer;
  orderCardFields: string[] | undefined;
}
export default ({
  orderDetails,
  openOrderDetailsOverlay,
  shouldAnimate,
  animationStartOffset,
  isOnline,
  alertTimer,
  orderCardFields,
}: ArrivedOrderCardProps) => {
  const [startAnimate, setStartAnimate] = useState(false);
  const isMobile = useIsMobile();

  // This triggers the animation after a random delay so card animations are not in sync
  useEffect(() => {
    if (shouldAnimate) {
      const timerId = setTimeout(() => {
        setStartAnimate(true);
      }, animationStartOffset);
      return () => clearInterval(timerId);
    } else {
      setStartAnimate(false);
    }
  }, [shouldAnimate, animationStartOffset]);

  return (
    <styled.AnimatedOrderCardOverrideWrapper
      className={startAnimate && isOnline ? "animateOn" : undefined}
      isOnline={isOnline}
      color={alertTimer.color}
      hasMobileOrderCardFields={Boolean(orderCardFields?.length) && isMobile}
    >
      <OrderCard
        orderDetails={orderDetails}
        openOrderDetailsOverlay={openOrderDetailsOverlay}
        isActive
        alertTimer={alertTimer}
        orderCardFields={orderCardFields}
        showDate={false}
      />
    </styled.AnimatedOrderCardOverrideWrapper>
  );
};
