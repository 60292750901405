import styled from "styled-components";

export const CustomDataCardWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  border: solid 0.1rem ${(props) => props.theme.colors.BORDER_COLOR};
  box-shadow: 0 0.2rem 0.2rem ${(props) => props.theme.colors.SHADOW_COLOR};
  padding: 0 1rem;
  min-height: 4rem;

  p {
    width: 50%;
    overflow-wrap: break-word;
    text-align: left;
    margin: auto;
  }

  p:last-child {
    text-align: right;
  }
`;

export const CustomDataFieldName = styled.p`
  font-weight: 700;
`;
