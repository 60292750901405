import React, { KeyboardEvent } from "react";
import * as styled from "./styled";

const Button = ({
  children,
  onClick,
  disabled,
  onKeyUp,
  className,
}: {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  onKeyUp?: (event: KeyboardEvent) => void;
  className?: string;
}) => {
  return (
    <styled.Button
      onClick={onClick}
      disabled={disabled}
      tabIndex={0}
      className={className}
      onKeyUp={onKeyUp}
    >
      {children}
    </styled.Button>
  );
};

export default Button;
