import React from "react";
import { OrderDetails } from "../../types/OrderDetailsType";
import Button from "../Button";
import {
  generateStatusDescriptionText,
  generateHeaderText,
  sortCustomDataFields,
} from "./helpers";
import CustomDataCard from "./components/CustomDataCard";
import * as styled from "./styled";
import useIsMobile from "../../hooks/useIsMobile";
import { Overlay } from "../LayoutComponents/styled";
import ChangeOrderStatus from "./components/ChangeOrderStatus";
import OrderSuccessMessage from "./components/OrderSuccessMessage";
import VerificationCodeInput from "./components/VerificationCodeInput";
import NoVerificationReasonInput from "./components/NoVerificationReasonInput";
import useOrderDetailsDisplayController from "./useOrderDetailsDisplayController";
import { useStateDefinitions } from "../../hooks/useSharedContext";
import { StateDefinitionConfig } from "../../types/StateDefinitionType";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import {
  getGroupColor,
  getOrderGroup,
  OrderGroupMapping,
  shouldRenderOrderGroup,
} from "../../services/orderGrouping";

export default ({
  orderDetails,
  hideOrderDetails,
  orderGroups,
}: {
  orderDetails: OrderDetails;
  hideOrderDetails: () => void;
  orderGroups: OrderGroupMapping | undefined;
}) => {
  const isMobile = useIsMobile();
  const {
    pageOverride,
    isUpdating,
    setShowChangeStatus,
    showChangeStatus,
    onUpdateOrderStatus,
    currentState,
    acknowledgeOrder,
    selectedForceTransitionState,
    setSelectedForceTransitionState,
    modalRef,
    errorMessage,
    setPageOverride,
    verificationTargetState,
  } = useOrderDetailsDisplayController(orderDetails, hideOrderDetails);
  const { stateDefinitions } = useStateDefinitions();

  if (pageOverride) {
    switch (pageOverride) {
      case "NO_VERIFICATION_REASON_INPUT":
        return (
          <NoVerificationReasonInput
            orderDetails={orderDetails}
            hideOrderDetails={hideOrderDetails}
            currentState={currentState}
            orderGroups={orderGroups}
            errorMessage={errorMessage}
            errorCodes={stateDefinitions?.verificationErrorCodes!}
            modalRef={modalRef}
            setPageOverride={setPageOverride}
            onUpdateOrderStatus={onUpdateOrderStatus}
            targetStatus={verificationTargetState}
            isUpdating={isUpdating}
          />
        );
      case "VERIFICATION_CODE_INPUT":
        return (
          <VerificationCodeInput
            orderDetails={orderDetails}
            hideOrderDetails={hideOrderDetails}
            currentState={currentState}
            orderGroups={orderGroups}
            errorMessage={errorMessage}
            modalRef={modalRef}
            setPageOverride={setPageOverride}
            onUpdateOrderStatus={onUpdateOrderStatus}
            targetStatus={verificationTargetState}
            isUpdating={isUpdating}
          />
        );
      case "SUCCESS_MESSAGE":
        return (
          <OrderSuccessMessage
            orderDetails={orderDetails}
            hideOrderDetails={hideOrderDetails}
          />
        );
    }
  }

  const generateActionButtons = (
    orderDetails: OrderDetails,
    showChangeOrderStatus: boolean,
    forceTransitionStatus: string
  ) => {
    const ErrorMessageComponent = (
      <styled.ErrorMessageWrapper hasErrorMessage={Boolean(errorMessage)}>
        {errorMessage && (
          <>
            {!isMobile && <Icon glyph="alert" size={28} />}
            <styled.UpdateErrorMessage role="status">
              {errorMessage}
            </styled.UpdateErrorMessage>
          </>
        )}
      </styled.ErrorMessageWrapper>
    );
    if (showChangeOrderStatus) {
      return (
        <>
          {ErrorMessageComponent}
          <styled.TwoButtonWrapper>
            <styled.BackButton
              className="menu-item"
              onClick={() => setShowChangeStatus(false)}
              onKeyUp={(event: { key: string }) => {
                if (event.key === "Enter") {
                  setShowChangeStatus(false);
                }
              }}
            >
              Back
            </styled.BackButton>
            <Button
              onClick={() => onUpdateOrderStatus(forceTransitionStatus, false)}
              disabled={isUpdating}
              className="menu-item"
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  onUpdateOrderStatus(forceTransitionStatus, false);
                }
              }}
            >
              {isUpdating ? "Updating" : "Update"}
            </Button>
          </styled.TwoButtonWrapper>
        </>
      );
    }
    const CommonButtons = (
      <>
        <styled.TwoButtonWrapper>
          <styled.BackButton
            onClick={() => hideOrderDetails()}
            className="menu-item"
            onKeyUp={(event: { key: string }) => {
              if (event.key === "Enter") {
                hideOrderDetails();
              }
            }}
          >
            Back
          </styled.BackButton>
          <Button
            onClick={() => setShowChangeStatus(true)}
            className="menu-item"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                setShowChangeStatus(true);
              }
            }}
            disabled={isUpdating}
          >
            Update Status
          </Button>
        </styled.TwoButtonWrapper>
      </>
    );
    if (!currentState.displayState?.quickButtons?.length) {
      return CommonButtons;
    }
    const targetQuickButton = currentState.displayState.quickButtons[0];
    if (targetQuickButton.action === "ACKNOWLEDGE") {
      if (orderDetails.isAcknowledged) {
        return (
          <>
            {ErrorMessageComponent}
            {CommonButtons}
          </>
        );
      }
      return (
        <>
          {ErrorMessageComponent}
          <styled.ButtonWrapper>
            <Button
              onClick={() => {
                acknowledgeOrder(orderDetails, false);
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  acknowledgeOrder(orderDetails, false);
                }
              }}
              disabled={isUpdating}
              className="menu-item"
            >
              {isUpdating ? "Updating" : targetQuickButton.label}
            </Button>
          </styled.ButtonWrapper>
          {CommonButtons}
        </>
      );
    }
    return (
      <>
        {ErrorMessageComponent}
        <styled.ButtonWrapper>
          <Button
            onClick={() => onUpdateOrderStatus(targetQuickButton.action, true)}
            disabled={isUpdating}
            className="menu-item"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                onUpdateOrderStatus(targetQuickButton.action, true);
              }
            }}
          >
            {isUpdating ? "Updating" : targetQuickButton.label}
          </Button>
        </styled.ButtonWrapper>
        {CommonButtons}
      </>
    );
  };

  const renderCustomDetails = (
    orderDetails: OrderDetails,
    stateDefinitions: StateDefinitionConfig | undefined
  ) => {
    if (orderDetails.hsFields) {
      const sortedFields = sortCustomDataFields(
        stateDefinitions!.hsFieldsPriority,
        orderDetails.hsFields
      );
      return sortedFields.map((key) => (
        <CustomDataCard
          key={key}
          fieldName={key}
          value={orderDetails.hsFields![key]}
        />
      ));
    }
  };
  const hasGroup = shouldRenderOrderGroup(orderDetails, orderGroups);
  const targetGroup = getOrderGroup(orderDetails, orderGroups);

  return (
    <>
      <Overlay onClick={() => hideOrderDetails()} zIndex={4} />
      <styled.OrderDetailsWrapper
        isMobile={isMobile}
        ref={modalRef}
        role="dialog"
        aria-label="Order Details"
      >
        <styled.OrderDetailsStatusHeader
          color={currentState.displayState!.color}
        >
          <styled.HeaderText>
            {generateHeaderText(orderDetails, currentState)}
          </styled.HeaderText>
        </styled.OrderDetailsStatusHeader>
        <styled.StatusDescriptionText>
          {generateStatusDescriptionText(orderDetails, currentState)}
        </styled.StatusDescriptionText>
        <styled.OrderIdText>
          <b>Order ID:</b>
          {orderDetails.orderId}
        </styled.OrderIdText>
        {hasGroup && (
          <styled.OrderGroupText
            color={getGroupColor(targetGroup!.groupNumber)}
          >
            {targetGroup!.groupName}
          </styled.OrderGroupText>
        )}
        {orderDetails.simphonyCheckNumber && (
          <styled.OrderIdText>
            <b>Simphony Check Number:</b>
            {orderDetails.simphonyCheckNumber}
          </styled.OrderIdText>
        )}
        {showChangeStatus ? (
          <ChangeOrderStatus
            orderStatus={orderDetails.status}
            selectedStatus={selectedForceTransitionState}
            setSelectedStatus={setSelectedForceTransitionState}
          />
        ) : (
          <styled.CustomDataCardsWrapper
            tabIndex={0}
            className={
              orderDetails.hsFields &&
              Object.keys(orderDetails.hsFields!).length
                ? "menu-item"
                : ""
            }
          >
            {renderCustomDetails(orderDetails, stateDefinitions)}
          </styled.CustomDataCardsWrapper>
        )}
        {generateActionButtons(
          orderDetails,
          showChangeStatus,
          selectedForceTransitionState as string
        )}
      </styled.OrderDetailsWrapper>
    </>
  );
};
