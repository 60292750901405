import styled from "styled-components";
import { ColorProps } from "../../types/sharedComponentProps";

export const OrderGroupWrapper = styled.div<ColorProps>`
  border: 0.2rem solid ${(props) => props.color};
  border-radius: 8px;
  padding: 0 0.3rem;
  margin: 0.7rem 0;
`;

export const GroupName = styled.p<ColorProps>`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 900;
`;

export const GroupNameBackground = styled.div`
  background-color: ${(props) => props.theme.colors.NEUTRAL_PANEL};
  z-index: 2;
  position: relative;
  top: -0.5rem;
  left: 2rem;
  width: fit-content;
  padding: 0 0.5rem;
  margin-bottom: -0.8rem;
`;
