import React from "react";
import * as styled from "./styled";

export default ({ fieldName, value }: { key: string, fieldName: string; value: string }) => {
  return (
    <styled.CustomDataCardWrapper>
      <styled.CustomDataFieldName>{fieldName}</styled.CustomDataFieldName>
      <p>{value}</p>
    </styled.CustomDataCardWrapper>
  );
};
