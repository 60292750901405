import React, { useEffect, useMemo } from "react";
import { useStateDefinitions } from "../../../../hooks/useSharedContext";
import * as styled from "./styled";

export default ({
  orderStatus,
  selectedStatus,
  setSelectedStatus,
}: {
  orderStatus: string;
  selectedStatus: string | undefined;
  setSelectedStatus: (newStatus: string) => void;
}) => {
  const { stateDefinitions, stateDictionary } = useStateDefinitions();

  const transitionableStates = useMemo(
    () =>
      stateDefinitions?.states
        .filter(
          (state) =>
            state.canForceTransition &&
            state.displayState &&
            state.name !== orderStatus
        )
        .map((state) => ({
          orderStatus: state.name,
          label: state.displayState!.stateDisplayName,
        })) || [],
    [stateDefinitions, orderStatus]
  );

  useEffect(() => {
    if (selectedStatus === undefined && transitionableStates.length) {
      setSelectedStatus(transitionableStates[0].orderStatus);
    }
  }, [selectedStatus, transitionableStates, setSelectedStatus, orderStatus]);

  const currentOrderDisplayState =
    stateDictionary[orderStatus].displayState?.stateDisplayName;
  return (
    <styled.ChangeOrderStatusWrapper>
      <styled.CurrentStatusWrapper>
        <styled.CurrentStatusLabel>Current Status:</styled.CurrentStatusLabel>
        <styled.CurrentStatusText>
          {currentOrderDisplayState}
        </styled.CurrentStatusText>
      </styled.CurrentStatusWrapper>
      <styled.SelectNewStatusText>Select new status</styled.SelectNewStatusText>
      <styled.StatusRadioButtonGrid>
        {transitionableStates.map((transitionableState, index) => {
          return (
            <styled.StatusButtonWrapper
              key={transitionableState.orderStatus}
              onClick={() => setSelectedStatus(transitionableState.orderStatus)}
            >
              <styled.StatusRadioButton
                type="radio"
                onChange={() =>
                  setSelectedStatus(transitionableState.orderStatus)
                }
                className={index === 0 ? "menu-item" : ""}
                name="force-order-status"
                checked={transitionableState.orderStatus === selectedStatus}
                id={transitionableState.orderStatus}
              />
              <styled.StatusButtonLabel
                htmlFor={transitionableState.orderStatus}
              >
                {transitionableState.label.length > 20
                  ? `${transitionableState.label.substring(0, 20)}...`
                  : transitionableState.label}
              </styled.StatusButtonLabel>
            </styled.StatusButtonWrapper>
          );
        })}
      </styled.StatusRadioButtonGrid>
    </styled.ChangeOrderStatusWrapper>
  );
};
