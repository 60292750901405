import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import { isValidRegion } from "../../types/RegionType";
import * as api from "../../services/api";
import * as persistenceManager from "../../services/persistenceManager";
import { createNetworkError } from "../../services/errorFactory";
import * as errorFactory from "../../services/errorFactory";
import { getAuth, getRedirectResult } from "firebase/auth";
import SsoLogin from "../../components/SsoLogin";
import SsoLink from "../../components/SsoLink";
import { useOrderStatus } from "../../hooks/useSharedContext";

const GCP_PROJECT = process.env.REACT_APP_GCP_PROJECT;
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: window?.location?.host,
  projectId: GCP_PROJECT,
  storageBucket: `${GCP_PROJECT}.appspot.com`,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

/*
    This is only used if a customer has requested we provide an alias URL
    Currently only one customer has asked for this
 */
const getUrlCodeAlias = (urlCode: string, region: string) => {
  const auAliases = {
    coles: "XTW7ZVC8TU1W",
  };
  const allRegionAliases: Record<string, Record<string, string>> = {
    au: auAliases,
  };

  if (allRegionAliases[region]) {
    return allRegionAliases[region][urlCode.trim().toLowerCase()];
  }
};

// Initialize Firebase
initializeApp(firebaseConfig);

export default (props: RouteComponentProps) => {
  const [loginError, setLoginError] = useState<undefined | Error>();
  const [getProviderError, setGetProviderError] = useState<undefined | Error>();
  const urlParams = useParams();
  const [identityProvider, setIdentityProvider] = useState("");
  const [shouldLoad, setShouldLoad] = useState(true);
  // This is false but will be set to true before any API call finishes if it is first page visit
  const [noLoginAttempted, setNoLoginAttempted] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const { loginSuccessful } = useOrderStatus();

  useEffect(() => {
    if (loginSuccessful) {
      navigate("/");
    }
  }, [loginSuccessful]);

  useEffect(() => {
    const getUrlCodeDetails = async () => {
      setShouldLoad(false);
      if (urlParams.region && urlParams.urlCode) {
        if (isValidRegion(urlParams.region)) {
          try {
            const targetUrlCode =
              getUrlCodeAlias(urlParams.urlCode, urlParams.region) ||
              urlParams.urlCode;
            const urlCodeDetails = await api.getSsoUrlCodeDetails(
              targetUrlCode,
              urlParams.region
            );
            persistenceManager.setSsoDetails(
              urlCodeDetails.projectId,
              urlCodeDetails.identityProvider
            );
            persistenceManager.setRegion(urlParams.region);
            setIdentityProvider(urlCodeDetails.identityProvider);
            return;
          } catch (err) {
            if ((err as Error).message === "Network Error") {
              setGetProviderError(createNetworkError());
              return;
            }
            console.error(err);
          }
        }
      }
      setGetProviderError(errorFactory.createInvalidUrlCodeError());
    };
    if (shouldLoad) {
      getUrlCodeDetails();
    }
  }, [urlParams.region, urlParams.urlCode, shouldLoad]);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const auth = getAuth();
      try {
        const result: any = await getRedirectResult(auth);
        if (result) {
          setJwtToken(result.user.accessToken);
        } else if (result === null) {
          setNoLoginAttempted(true);
        }
      } catch (err) {
        console.log("SSO login error", err);
        const anyError = err as any;
        if (anyError?.code === "auth/network-request-failed") {
          setLoginError(errorFactory.createNetworkError());
        } else {
          setLoginError(errorFactory.createSsoLoginFailedError());
        }
      }
    };
    checkAuthStatus();
  }, []);

  if (noLoginAttempted) {
    return (
      <SsoLogin
        identityProvider={identityProvider}
        setError={setGetProviderError}
        setShouldLoad={setShouldLoad}
        error={getProviderError}
      />
    );
  }

  return (
    <SsoLink
      jwtToken={jwtToken}
      region={urlParams.region}
      loginError={loginError}
    />
  );
};
