import styled from "styled-components";

interface AutoRefreshWrapperProps {
  isMobile: boolean;
}
interface ButtonWrapperProps {
  isMobile: boolean;
}

export const AutoRefreshWrapper = styled.div<AutoRefreshWrapperProps>`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  position: absolute;
  top: 0;
  left: 0;
  ${(props) => {
    if (!props.isMobile) {
      return `top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);`;
    } else {
      return `padding-top: 5rem;`;
    }
  }}
  min-width: ${(props) => (props.isMobile ? "100" : "40")}vw;
  min-height: ${(props) => (props.isMobile ? "100" : "40")}vh;
  max-width: 100vw;
  z-index: 10;
  text-align: center;
`;

export const AutoRefreshHeader = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: ${(props) => (props.isMobile ? "10" : "3")}rem;
`;

export const AutoRefreshImage = styled.img`
  width: 120px;
  height: 125px;
  margin: auto;
`;

export const HeaderText = styled.h2`
  margin: auto;
  font-size: 1.5rem;
`;

export const BodyText = styled.p`
  font-size: 1.25rem;
  padding: 1rem;
  overflow-wrap: break-word;
  b {
    margin-right: 0.5rem;
  }
  width: 80%;
  margin: auto;
`;

export const BluedotPurpleText = styled.span`
  color: ${(props) => props.theme.colors.PRIMARY_ACCENT};
  font-weight: bold;
  display: block;
  margin: 0.5rem;
  font-size: 1.5rem;
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin: 2.5rem auto;
  width: 80%;
  ${(props) => {
    if (props.isMobile) {
      return `margin: 6rem 10%;
              bottom: 0;
              position: absolute;`;
    }
  }}
`;

export const Button = styled.button<{ isMobile: boolean }>`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.DISABLED_TEXT
      : props.theme.colors.SECONDARY_ACCENT};
  border: solid 1px
    ${(props) =>
      props.disabled
        ? props.theme.colors.DISABLED_TEXT
        : props.theme.colors.SECONDARY_ACCENT};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  padding: ${(props) => (props.isMobile ? "1rem" : "0.8rem 1rem;")};
  width: 100%;
  font-weight: 800;
  font-size: 19px;
  font-family: "Spartan", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
`;
