import styled from "styled-components";
import { IsMobileProps } from "../../types/sharedComponentProps";

interface SoundControlWrapperProps extends IsMobileProps {
  soundEnabled: boolean;
}

export const SoundControlWrapper = styled.div<SoundControlWrapperProps>`
  background-color: ${(props) =>
    props.soundEnabled
      ? props.theme.colors.TERTIARY_ACCENT
      : props.theme.colors.PRIMARY_ACCENT};
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin: auto 0;
  width: fit-content;
  display: inline-flex;
  max-height: 3rem;
  ${(props) => {
    if (!props.isMobile) {
      return `margin-left: 1.5rem;`;
    }
  }}

  svg {
    fill: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  }
`;

export const SoundControlText = styled.label`
  font-weight: 800;
  margin: auto 1rem;
  padding-top: 0.3rem;
  color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
`;
