import styled from "styled-components";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import { HEADER_HEIGHT } from "../../constants/layout";

interface MenuWrapperProps {
  isMobile: boolean;
}

export const MenuWrapper = styled.div<MenuWrapperProps>`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  position: absolute;
  right: 0;
  top: 0;
  min-width: ${(props) => (props.isMobile ? "100" : "30")}vw;
  min-height: 100vh;
  z-index: 5;
  text-align: center;
`;

export const MenuTitle = styled.h2`
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
`;

export const MenuLinksWrapper = styled.div`
  margin: 0 2rem;
`;

export const MenuLink = styled.button`
  border: none;
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  text-align: left;
  width: 100%;
  font-size: 1.5em;
  margin: 0.83em 0;
  font-weight: bold;
  font-family: "Spartan", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
`;

export const VersionTag = styled.h4`
  color: ${(props) => props.theme.colors.PRIMARY_TEXT};
  position: absolute;
  bottom: 0;
  right: 2rem;
`;

export const MenuHeader = styled.div`
  width: 100%;
  text-align: right;
  height: ${HEADER_HEIGHT};
  box-shadow: 0 0.1rem ${(props) => props.theme.colors.SHADOW_COLOR};
`;

export const CloseButtonWrapper = styled.div`
  margin-right: 2rem;
  margin-top: 0.7rem;
`;

export const MenuButton = styled(Icon)`
  margin: 1rem;
`;
