import styled from "styled-components";
export {
  LogoWrapper,
  LogoImage,
  BackgroundPanelMobile,
  BackgroundPanel,
  CenterAlignmentWrapper,
  OneTimeCodeMobilePanel,
  OneTimeCodeWrapper,
} from "../../scenes/OneTimeCodeSetup/styled";

export const ErrorPageHeader = styled.h1`
  margin: 2rem auto;
  max-width: 100%;
`;

export const ErrorPageParagraph = styled.p`
  max-width: 80%;
  margin: 2rem auto;
`;
