import React, { useState } from "react";
import * as styled from "./styled";
import OpenDropdownMenu from "./OpenDropdownMenu";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";
import { Store } from "../../types/StoreType";

export default ({ store }: { store?: Store | undefined}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setHideMainContent } = useAriaHideMainContent();

  const openMenu = () => {
    setIsOpen(true);
    setHideMainContent(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setHideMainContent(false);
  };

  if (isOpen) {
    return <OpenDropdownMenu closeMenu={closeMenu} store={store} />;
  }
  return (
    <styled.MenuButton
      glyph="hamburger"
      size="24"
      onClick={openMenu}
      tabIndex={0}
      onKeyUp={(event: KeyboardEvent) => {
        if (event.key === "Enter") {
          openMenu();
        }
      }}
      aria-label="Open Navigation Menu"
      role="button"
    />
  );
};
