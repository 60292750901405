import React from "react";
import { Link } from "@reach/router";
import * as styled from "./styled";

export default () => {
  return (
    <Link
      to="/active"
      style={{ textDecoration: "none" }}
      aria-label="Back to Active Orders"
    >
      <styled.BackwardsNavigationWrapper>
        <styled.BackwardsNavigationIcon
          glyph="chevron"
          size="16"
          title="Back Icon"
        />
        <styled.BackwardsNavigationText>Back</styled.BackwardsNavigationText>
      </styled.BackwardsNavigationWrapper>
    </Link>
  );
};
