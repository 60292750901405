import styled from "styled-components";
import { IsMobileProps } from "../../types/sharedComponentProps";

export const SearchBarAndLabelWrapper = styled.div<IsMobileProps>`
  width: 100%;
  ${(props) => {
    if (props.isMobile) {
      return `margin-top: 1rem;`;
    } else {
      return `max-width: 33%;`;
    }
  }}
`;

export const SearchBarLabel = styled.label`
  font-weight: 500;
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  border-radius: 5px;
  border: ${(props) => props.theme.colors.PRIMARY_TEXT} solid 2px;
`;

export const SearchInput = styled.input`
  border: none;
  padding: 12px 0 12px 12px;
  font-size: 16px;
  width: 100%;
  height: 100%;
`;

export const ClearIconContainer = styled.div`
  margin: 0.5rem 1rem auto 0;

  & svg {
    transform: rotate(45deg);
  }
`;

export const SearchIconWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  margin: 0 0.5rem;
  align-items: center;
  display: flex;
  height: 100%;

  & svg {
    margin: auto;
  }
`;
