import React, { createContext, ReactNode } from "react";
import usePollWaitTime from "../../hooks/usePollWaitTime";

interface WaitTimeContextValue {
  waitTime: number;
  numberOfOrders: number;
  refreshWaitTime: () => void;
  setWaitTimePollingDisabled: (disabled: boolean) => void;
}

const defaultProviderValue: WaitTimeContextValue = {
  waitTime: 0,
  refreshWaitTime: () =>
    console.log("Placeholder function for refreshWaitTime"),
  numberOfOrders: 0,
  setWaitTimePollingDisabled: () => undefined,
};

const WaitTimeContext = createContext(defaultProviderValue);

const WaitTimeProvider = ({ children }: { children: ReactNode }) => {
  const {
    waitTime,
    refreshWaitTime,
    numberOfOrders,
    setDisablePolling,
  } = usePollWaitTime();

  const providerValue = {
    waitTime,
    refreshWaitTime,
    numberOfOrders,
    setWaitTimePollingDisabled: setDisablePolling,
  };

  return (
    <WaitTimeContext.Provider value={providerValue}>
      {children}
    </WaitTimeContext.Provider>
  );
};

export { WaitTimeContext, WaitTimeProvider };
