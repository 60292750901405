import { useEffect, useState, useCallback } from "react";
import * as persistenceManager from "../services/persistenceManager";
import * as api from "../services/api";
import * as errorFactory from "../services/errorFactory";
import { POLLING_INTERVAL } from "../constants/intervals";
import { OrderDetails } from "../types/OrderDetailsType";
import { Region } from "../types/RegionType";
import { enableNotificationSounds } from "../services/sounds";
import { ExtendedGlobal } from "../types/GlobalExtensionFunctions";
import { useNetworkStatus } from "./useSharedContext";
import { mockGetOrdersResponse } from "../data/mockOrderDetails";

export default () => {
  const [latestOrderDetails, setLatestOrderDetails] = useState<OrderDetails[]>(
    []
  );
  const [error, setError] = useState<Error | undefined>();
  const [loadingFirstRequest, setLoadingFirstRequest] = useState(true);
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [ordersStateHash, setOrdersStateHash] = useState<string | undefined>();
  const [isLoggedOut, setIsLoggedOut] = useState<boolean | undefined>();
  const { addRequestResult } = useNetworkStatus();
  const [disablePolling, setDisablePolling] = useState(false);
  const [lastPollTimestamp, setLastPollTimestamp] = useState("");

  const updateOrderDetails = useCallback(async () => {
    const token = persistenceManager.getAuthToken();
    const region = persistenceManager.getRegion();

    if (token && region) {
      try {
        const getOrdersResponse =
          process.env.REACT_APP_USE_MOCK_VALUES === "true"
            ? mockGetOrdersResponse
            : await api.getOrders(token, region as Region);
        addRequestResult(true);
        setIsLoggedOut(false);
        setLatestOrderDetails(getOrdersResponse.orderDetails);
        setOrdersStateHash(getOrdersResponse.stateHash);
        setLastPollTimestamp(new Date().toISOString());
        if (!loginSuccessful) {
          setLoginSuccessful(true);
        }
        setError(undefined);
        setLoadingFirstRequest(false);
      } catch (err) {
        // This is expected to occur often when screen has not yet been linked
        if (err instanceof Error) {
          if (err.name === errorFactory.NOT_YET_LINKED_ERROR) {
            setIsLoggedOut(true);
          } else {
            console.log(err);
          }
          if (err.name === errorFactory.NETWORK_CONNECTION_ERROR) {
            addRequestResult(false);
          } else {
            setLoadingFirstRequest(false);
          }
        }
        setError(err as Error);
      }
    } else {
      setIsLoggedOut(true);
      setLoadingFirstRequest(false);
      if (loginSuccessful) {
        setLoginSuccessful(false);
      }
      if (!token) {
        setError(errorFactory.createNoTokenError());
      } else if (!region) {
        setError(errorFactory.createNoRegionError());
      }
    }
  }, [loginSuccessful, addRequestResult]);

  useEffect(() => {
    if (loadingFirstRequest) {
      enableNotificationSounds();
    }

    updateOrderDetails();
    if (!disablePolling) {
      const timerId = setInterval(() => updateOrderDetails(), POLLING_INTERVAL);

      return () => clearInterval(timerId);
    }
  }, [
    loginSuccessful,
    loadingFirstRequest,
    updateOrderDetails,
    disablePolling,
  ]);

  // DO NOT CHANGE THIS FUNCTION SIGNATURE OR RETURN TYPE - IT WILL BREAK THE NATIVE APP
  ((global as unknown) as ExtendedGlobal).isLoggedOut = () => {
    return isLoggedOut;
  };

  const refreshOrderDetails = updateOrderDetails;

  const clearOrders = useCallback(() => {
    setLatestOrderDetails([]);
    setLoginSuccessful(false);
    setLoadingFirstRequest(true);
  }, [setLatestOrderDetails, setLoginSuccessful, setLoadingFirstRequest]);

  return {
    orders: latestOrderDetails,
    pollOrdersError: error,
    refreshOrderDetails,
    loginSuccessful,
    loadingFirstRequest,
    clearOrders,
    ordersStateHash,
    resetLoadingFirstRequest: () => setLoadingFirstRequest(true),
    setDisablePolling,
    lastOrderPollTimestamp: lastPollTimestamp,
  };
};
