import styled from "styled-components";

export const NavLinksWrapper = styled.nav`
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 3rem;
`;

export const NavLink = styled.a``;

export const LinksDescription = styled.p`
  margin: 0;
`;
