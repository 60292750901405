import React, { createContext, ReactNode, useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { useAriaHideMainContent } from "../../hooks/useSharedContext";

const ConfirmationModalContext = createContext(
  (title: string, message: string, onConfirm: () => void) =>
    console.log("Placeholder create modal function")
);

/*
  This function creates a ContextProvider which is designed to live at the root level of the App, allowing for the
  same modal to be used by all components in the app. Usage:

  const showConfirmationModal = useContext(ConfirmationModalContext);
  showConfirmationModal(title: string, message: string, onConfirm: () => void);

  Hiding of the modal is managed automatically after either clicking No or the X to close, or after completing the onConfirm function

  For a more concrete example you can check the OneTimeCodeSetup and DropdownMenu components
 */
const ConfirmationModalProvider = ({ children }: { children: ReactNode }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Placeholder Title");
  const [modalMessage, setModalMessage] = useState("Placeholder message");
  const [onConfirmAction, setOnConfirmAction] = useState<() => void>(() => () =>
    console.log("Modal confirm button clicked")
  );
  const { setHideMainContent, setHideBanner } = useAriaHideMainContent();

  const openModal = (title: string, message: string, onConfirm: () => void) => {
    setModalTitle(title);
    setModalMessage(message);
    setOnConfirmAction(() => onConfirm);
    setShowModal(true);
    setHideMainContent(true);
    setHideBanner(true);
  };

  const onHideModal = () => {
    setShowModal(false);
    setHideMainContent(false);
    setHideBanner(false);
  };

  return (
    <ConfirmationModalContext.Provider value={openModal}>
      {showModal && (
        <ConfirmationModal
          modalTitle={modalTitle}
          message={modalMessage}
          onConfirm={() => onConfirmAction()}
          onHideModal={onHideModal}
        />
      )}
      {children}
    </ConfirmationModalContext.Provider>
  );
};

export { ConfirmationModalContext, ConfirmationModalProvider };
