import React, { ReactNode, useEffect, useState } from "react";
import { OrderDetails } from "../../types/OrderDetailsType";
import * as styled from "./styled";
import { ALERT_ANIMATION_TIME_PERIOD } from "../../constants/intervals";
import useIsOnline from "../../hooks/useIsOnline";
import AnimatedOrderCard from "../AnimatedOrderCard";
import {
  useNetworkStatus,
  useOrderStatus,
  useStateDefinitions,
} from "../../hooks/useSharedContext";
import { LoadingText, LoadingWrapper } from "../PrivateRoute/styled";
import Spinner from "../Spinner";
import OrderCard from "../OrderCard/OrderCard";
import OrderGroupContainer from "../OrderGroupContainer";
import { createSortOrdersFunction } from "./helpers";
import { getAlertTimer } from "../../services/utils";
import {
  OrderGroupMapping,
  shouldRenderOrderGroup,
} from "../../services/orderGrouping";

interface OrderTrackerProps {
  orders: OrderDetails[];
  showingHistory: boolean;
  categoryTitle: string;
  setOrderDetailsOverlayData: (orderDetails: OrderDetails) => void;
  isSearching: boolean;
  orderGroups?: OrderGroupMapping;
}

const RERENDER_INTERVAL = 5000;

export default ({
  orders,
  showingHistory,
  categoryTitle,
  setOrderDetailsOverlayData,
  isSearching,
  orderGroups,
}: OrderTrackerProps) => {
  const isOnline = useIsOnline();
  const {
    stateDictionary,
    errorStates,
    stateDefinitions,
  } = useStateDefinitions();
  const { loadingFirstRequest } = useOrderStatus();
  const { networkStatus } = useNetworkStatus();
  const [rerenderTrigger, setRerenderTrigger] = useState(false);

  // When Hello Realtime is in use rerender evey 5 seconds
  // This will ensure that the alert timers are recalculated and updated while no other updates come through
  useEffect(() => {
    if (networkStatus === "REALTIME") {
      const timerId = setTimeout(
        () => setRerenderTrigger((prevState) => !prevState),
        RERENDER_INTERVAL
      );
      return () => clearTimeout(timerId);
    }
  }, [rerenderTrigger, networkStatus]);

  const calculateAnimationStartOffset = (cardIndex: number) => {
    // The reason for changing the offset time based on even or uneven indexes is to increase the
    // difference between the animations for adjacent orders. When there are a large amount of arrived orders
    // if just based on the index then adjacent orders appears to flash evenly as the difference between the indexes
    // is small. This way the first orders on the list will appear to flash more noticeably out of sync. This approach
    // does result in the same issue for orders in the middle of a large arrived orders list but these are less prominent.
    // In production this should not occur very often as orders should be cleared as they arrived so a large amount of
    // arrived orders should not build up.
    if (cardIndex % 2 === 0) {
      return 0 + (cardIndex / orders.length) * ALERT_ANIMATION_TIME_PERIOD;
    }
    return (
      ALERT_ANIMATION_TIME_PERIOD -
      (cardIndex / orders.length) * ALERT_ANIMATION_TIME_PERIOD
    );
  };

  const shouldCardTextBeGreyedOut = (order: OrderDetails) =>
    !showingHistory && (!isOnline || errorStates.includes(order.status));

  const orderSorter = createSortOrdersFunction(showingHistory, errorStates);
  const sortedOrders = orders.sort(orderSorter);

  const renderOrderTrackerContent = () => {
    const renderedGroups: string[] = [];
    const itemsToRender: ReactNode[] = [];
    const renderOrder = (orderDetail: OrderDetails, index: number) => {
      const key = orderDetail.orderId;
      const alertTimer =
        !showingHistory &&
        getAlertTimer(
          orderDetail,
          stateDictionary[orderDetail.status],
          stateDefinitions?.features
        );
      const shouldAnimate =
        stateDictionary[orderDetail.status].canAcknowledge &&
        !orderDetail.isAcknowledged;
      if (alertTimer) {
        return (
          <AnimatedOrderCard
            orderDetails={orderDetail}
            key={key}
            openOrderDetailsOverlay={() => {
              setOrderDetailsOverlayData(orderDetail);
            }}
            shouldAnimate={shouldAnimate}
            animationStartOffset={calculateAnimationStartOffset(index)}
            isOnline={isOnline}
            alertTimer={alertTimer}
            orderCardFields={stateDefinitions?.orderCardFields}
          />
        );
      }
      return (
        <OrderCard
          orderDetails={orderDetail}
          key={key}
          openOrderDetailsOverlay={() =>
            setOrderDetailsOverlayData(orderDetail)
          }
          isActive={!shouldCardTextBeGreyedOut(orderDetail)}
          orderCardFields={stateDefinitions?.orderCardFields}
          showDate={showingHistory}
        />
      );
    };
    let indexDisplacement = 0;
    sortedOrders.forEach((order, index) => {
      if (!orderGroups) {
        itemsToRender.push(renderOrder(order, index));
        return;
      }
      const groupId = order.hsFields && order.hsFields["orderGroupId"];
      if (!groupId || !shouldRenderOrderGroup(order, orderGroups)) {
        itemsToRender.push(renderOrder(order, index + indexDisplacement));
      } else if (renderedGroups.includes(groupId)) {
        return;
      } else {
        const targetGroup = orderGroups![groupId];
        // First order in a group detected. Find all other orders in this group and render together
        const groupOrders = sortedOrders.filter(
          (order) =>
            order.hsFields && order.hsFields["orderGroupId"] === groupId
        );
        const groupComponent = (
          <OrderGroupContainer
            groupName={targetGroup.groupName}
            groupNumber={targetGroup.groupNumber}
            key={groupId}
          >
            {groupOrders.map((order, groupIndex) => {
              return renderOrder(order, index + indexDisplacement + groupIndex);
            })}
          </OrderGroupContainer>
        );
        itemsToRender.push(groupComponent);
        if (groupOrders.length > 1) {
          indexDisplacement += groupOrders.length - 1;
        }
        renderedGroups.push(groupId);
      }
    });
    return itemsToRender;
  };

  return (
    <>
      <styled.OrderSectionHeader>
        <styled.OrderSectionTitle>
          {categoryTitle.toUpperCase()}
        </styled.OrderSectionTitle>
      </styled.OrderSectionHeader>
      {!orders.length && loadingFirstRequest ? (
        <LoadingWrapper>
          <Spinner />
          <LoadingText>Loading</LoadingText>
        </LoadingWrapper>
      ) : !orders.length ? (
        <styled.NoOrdersFoundText>
          {isSearching ? `No orders which match the search term` : `No Orders`}
        </styled.NoOrdersFoundText>
      ) : (
        <styled.OrderTrackerWrapper>
          {renderOrderTrackerContent()}
        </styled.OrderTrackerWrapper>
      )}
    </>
  );
};
