import styled from "styled-components";
import { IsActiveProps, IsMobileProps } from "../../types/sharedComponentProps";

// Show date indicates if the card should be enlarged to show the
// date of the last interaction as well as the time on the order card.
// This is only used for the history page and only used when the view is mobile
// as it can fit without enlarging the space otherwise
interface ShowDateProps extends IsActiveProps {
  showDate: boolean;
}

export const OrderCardWrapper = styled.div<ShowDateProps>`
  background-color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  height: ${(props) => (props.showDate ? "7rem" : "5rem")};
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  border: solid 0.1rem ${(props) => props.theme.colors.BORDER_COLOR};
  box-shadow: 0 0.2rem 0.2rem ${(props) => props.theme.colors.SHADOW_COLOR};
  ${(props) => {
    if (!props.isActive) {
      return `color: ${props.theme.colors.DISABLED_TEXT};`;
    }
  }}
`;

export const OrderStatusEdgeColor = styled.div<OrderStatusEdgeColorProps>`
  width: 0.5rem;
  transition: background-color 0.5s ease;
  background-color: ${(props) =>
    props.color || props.theme.colors.TERTIARY_ACCENT};
`;

export const EtaText = styled.p<IsMobileProps>`
  margin: 0;
  font-weight: ${(props) => (props.isMobile ? "700" : "400")};
`;

export const MobileWithOrderCardFieldsWrapper = styled(OrderCardWrapper)`
  ${OrderCardWrapper} {
    border: none;
    margin: 0;
    box-shadow: none;
    height: ${(props) => (props.showDate ? "5.7rem" : "4.2rem")};
    animation: none;
    transform: none;

    p {
      font-size: 1.2rem;
    }

    ${OrderStatusEdgeColor} {
      display: none;
    }

    ${EtaText} {
      ${(props) => {
        if (props.showDate) {
          return `padding-top: 0.5rem; font-size: 1rem;`;
        }
      }}
    }
  }

  height: ${(props) => (props.showDate ? "9rem" : "7.4rem")};
`;

export const InnerMobileWithOrderCardFieldsWrapper = styled.div`
  display: block;
  width: calc(100% - 0.5rem);
`;

interface OrderStatusEdgeColorProps {
  color: string;
}

export const EtaStatusWrapper = styled.div`
  display: block;
  padding: 0.3rem;
  margin: auto 0;
  padding-left: 2%;
  width: 33%;
`;

export const TimeRemainingText = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
`;

export const CustomerNameText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem;
  margin: auto;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: 33%;
  max-height: 100%;
`;

export const OrderNumberText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem;
  max-height: 100%;
  margin: auto 0;
  font-size: 1.3rem;
  font-weight: 500;
  padding-right: 1.5rem;
  text-align: right;
`;

export const OrderDetailsLink = styled.p<IsActiveProps>`
  margin: auto 1rem;
  padding-top: 6px;

  ${(props) => {
    if (!props.isActive) {
      return `& svg {
          fill: ${props.theme.colors.DISABLED_TEXT};
        }`;
    }
  }}
`;

export const OrderNumberWrapper = styled.div`
  width: 33%;
  display: inline-flex;
  justify-content: flex-end;
`;

export const CardMessageWrapper = styled.div`
  display: flex;
  margin-right: 2rem;
`;

export const CardMessage = styled.p`
  font-size: 2.5rem;
  margin: auto;
`;

export const OrderCardWithFieldsOverrideWrapper = styled.div`
  ${OrderCardWrapper} {
    height: 6rem;
  }

  ${CustomerNameText} {
    width: 23%;
  }

  ${OrderNumberWrapper} {
    width: 23%;
  }

  ${EtaStatusWrapper} {
    width: 23%;
  }
`;
