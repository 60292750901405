import { useEffect, useState, useCallback } from "react";
import * as persistenceManager from "../services/persistenceManager";
import * as api from "../services/api";
import * as errorFactory from "../services/errorFactory";
import { WAIT_TIME_POLLING_INTERVAL } from "../constants/intervals";
import { Region } from "../types/RegionType";

export default () => {
  const [waitTime, setWaitTime] = useState<number>(0);
  const [numberOfOrders, setNumberOfOrders] = useState<number>(0);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [, setError] = useState<Error | undefined>();
  const [disablePolling, setDisablePolling] = useState(false);

  useEffect(() => {
    const updateWaitTime = async () => {
      setShouldRefresh(false);
      const token = persistenceManager.getAuthToken();
      const region = persistenceManager.getRegion();

      if (token && region) {
        try {
          const timezoneOffset = new Date().getTimezoneOffset();
          const getWaitTimeResponse = await api.getWaitTime(
            token,
            region as Region,
            timezoneOffset
          );
          setWaitTime(getWaitTimeResponse.waitTimeAverage);
          setNumberOfOrders(getWaitTimeResponse.numberOfOrders);
          setError(undefined);
        } catch (err) {
          console.log(err);
          setError(errorFactory.createUnknownError(err));
        }
      } else {
        if (!token) {
          setError(errorFactory.createNoTokenError());
        } else if (!region) {
          setError(errorFactory.createNoRegionError());
        }
      }
    };
    if (shouldRefresh) {
      updateWaitTime();
    }

    if (!disablePolling) {
      const timerId = setInterval(
        () => updateWaitTime(),
        WAIT_TIME_POLLING_INTERVAL
      );

      return () => clearInterval(timerId);
    }
  }, [shouldRefresh, disablePolling]);

  const refreshWaitTime = useCallback(() => {
    setShouldRefresh(true);
  }, [setShouldRefresh]);

  return {
    waitTime,
    numberOfOrders,
    refreshWaitTime,
    setDisablePolling,
  };
};
