import React, { createContext, ReactNode, useState } from "react";

const defaultValue = {
  hideMainContent: false,
  setHideMainContent: (() =>
    console.log(
      "Placeholder aria hide main content function"
    ) as unknown) as React.Dispatch<React.SetStateAction<boolean>>,
  hideBanner: false,
  setHideBanner: (() =>
    console.log(
      "Placeholder aria hide banner function"
    ) as unknown) as React.Dispatch<React.SetStateAction<boolean>>,
};

const AriaHideMainContentContext = createContext(defaultValue);

/*
  This function creates a ContextProvider which is designed to live at the root level of the App, allowing for the
  same data to be used by all components in the app. It ensures the value mainContentHidden = true can be updated by the various
  modals and menus in the app without needing to keep track of these values for each modal

  Usage:

  const {hideMainContent, setHideMainContent} = useContext(AriaHideMainContentContext);
 */
const AriaHideMainContentProvider = ({ children }: { children: ReactNode }) => {
  const [hideMainContent, setHideMainContent] = useState(false);
  const [hideBanner, setHideBanner] = useState(false);
  const contextValue = {
    hideMainContent,
    setHideMainContent,
    hideBanner,
    setHideBanner,
  };

  return (
    <AriaHideMainContentContext.Provider value={contextValue}>
      {children}
    </AriaHideMainContentContext.Provider>
  );
};

export { AriaHideMainContentContext, AriaHideMainContentProvider };
