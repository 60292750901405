import styled, { keyframes } from "styled-components";
import tinycolor from "tinycolor2";
import * as baseStyles from "../OrderCard/styled";
import * as orderCardFieldStyles from "../OrderCardFieldsDisplay/styled";
import { ALERT_ANIMATION_TIME_PERIOD } from "../../constants/intervals";

const AlertAnimation = (props: any) => keyframes`
  0% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

interface AnimatedOrderCardOverrideWrapperProps {
  isOnline: boolean;
  color: string;
  hasMobileOrderCardFields: boolean;
}

export const AnimatedOrderCardOverrideWrapper = styled.div<
  AnimatedOrderCardOverrideWrapperProps
>`
  ${baseStyles.OrderStatusEdgeColor} {
    display: none;
  }

  ${baseStyles.OrderCardWrapper} {
    background-color: ${(props) =>
      props.isOnline
        ? props.color
        : tinycolor(props.color).darken().toString()};
    color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
    transform: scale(1);
    font-weight: bold;
    font-size: 1.6rem;
  }

  ${baseStyles.EtaText} {
    font-weight: bold;
    font-size: 19px;
  }

  ${baseStyles.TimeRemainingText} {
    font-weight: bold;
    font-size: 19px;
  }

  ${baseStyles.OrderNumberText} {
    font-weight: bold;
    font-size: 19px;
  }
    
  }

  ${baseStyles.CustomerNameText} {
    font-weight: bold;
    font-size: 19px;
  }

  ${orderCardFieldStyles.OrderCardFieldText} {
    color: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  }

  ${orderCardFieldStyles.MobileOrderCardFields} {
    border-color: ${(props) => props.theme.colors.SHADOW_COLOR};
  }

  ${orderCardFieldStyles.InlineOrderCardFieldWrapper} {
    background-color: ${(props) =>
      props.theme.colors.INVERTED_TEXT_NEUTRAL_PANEL};
  }

  &.animateOn {
    ${(props) =>
      props.hasMobileOrderCardFields
        ? baseStyles.MobileWithOrderCardFieldsWrapper
        : baseStyles.OrderCardWrapper} {
      animation: ${AlertAnimation} ${ALERT_ANIMATION_TIME_PERIOD}ms infinite;
  }}

  svg {
    fill: ${(props) => props.theme.colors.TEXT_BACKGROUND};
  }


  & ${baseStyles.MobileWithOrderCardFieldsWrapper} {
    & .main-card-text {
      font-size: 19px;
    }
    
  }
`;
