import styled from "styled-components";
import { NETWORK_STATUS } from "./ConnectionStatusContext";

export const StatusWrapper = styled.div`
  display: inline-flex;
  float: right;
`;

interface NetworkStatusProps {
  networkStatus: NETWORK_STATUS;
}

export const StatusTrafficLight = styled.div<NetworkStatusProps>`
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => {
    switch (props.networkStatus) {
      case "REALTIME":
        return props.theme.colors.SUCCESS;
      case "ONLINE":
        return props.theme.colors.SECONDARY_ACCENT;
      case "UNSTABLE":
        return props.theme.colors.WARNING;
      default:
        return props.theme.colors.TERTIARY_ACCENT;
    }
  }};
  border-radius: 1rem;
  margin: auto;
  margin-right: 1rem;
`;

export const StatusText = styled.p<NetworkStatusProps>`
  margin: auto;
  padding-top: 5px;
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => {
    switch (props.networkStatus) {
      case "OFFLINE":
        return props.theme.colors.TERTIARY_ACCENT;
      default:
        return props.theme.colors.PRIMARY_TEXT;
    }
  }};
`;
