import React from "react";
import Select from "react-select";
import * as styled from "./styled";

export default ({
  handleRegionChange,
  selectedRegion,
}: {
  handleRegionChange: (seletedOption: any) => void;
  selectedRegion: string | undefined;
}) => {
  const canvasRegions = [
    { label: "Asia Pacific", value: "au" },
    { label: "Europe", value: "eu" },
    { label: "North America", value: "us" },
  ];

  return (
    <styled.SelectWrapper>
      <styled.RegionSelectorLabel id="region-selector-label">
        Select your region:
      </styled.RegionSelectorLabel>
      <Select
        options={canvasRegions}
        className="region-selector"
        classNamePrefix="region-selector"
        placeholder="Select your region"
        onChange={handleRegionChange}
        value={canvasRegions.find((option) => option.value === selectedRegion)}
        aria-labelledby="region-selector-label"
      />
    </styled.SelectWrapper>
  );
};
