import React from "react";
import { useWaitTime, useStateDefinitions } from "../../hooks/useSharedContext";
import * as styled from "./styled";
import useIsMobile from "../../hooks/useIsMobile";
import { convertToMinutesAndSecondsString } from "../../services/utils";

export default () => {
  const { waitTime, numberOfOrders } = useWaitTime();
  const { stateDefinitions } = useStateDefinitions();
  const isMobile = useIsMobile();

  const waitTimeString = convertToMinutesAndSecondsString(waitTime);

  const waitTimeFeature = stateDefinitions?.features?.waitTime;

  if (!waitTimeFeature) {
    return null;
  }

  if (!waitTimeFeature.goal || !numberOfOrders) {
    return (
      <styled.WaitTimeDisplayWrapper isMobile={isMobile}>
        <styled.WaitTimeLabel isMobile={isMobile}>
          Wait Time Average
        </styled.WaitTimeLabel>
        {numberOfOrders ? (
          <styled.WaitTimeWithUnitsWrapper isMobile={isMobile}>
            <styled.WaitTimeNumber>{waitTimeString}</styled.WaitTimeNumber>
            <styled.WaitTimeUnit isMobile={isMobile}>
              Minutes
            </styled.WaitTimeUnit>
          </styled.WaitTimeWithUnitsWrapper>
        ) : (
          <styled.NoOrdersWrapper isMobile={isMobile}>
            <styled.NoOrdersText isMobile={isMobile}>
              No orders yet
            </styled.NoOrdersText>
          </styled.NoOrdersWrapper>
        )}
      </styled.WaitTimeDisplayWrapper>
    );
  }

  return (
    <styled.WaitTimeWithGoalDisplayWrapper isMobile={isMobile}>
      <styled.WaitTimeLabel isMobile={isMobile}>
        Wait Time Average
      </styled.WaitTimeLabel>
      {numberOfOrders ? (
        <styled.WaitTimeWrapper>
          <styled.ColoredWaitTimePerformance
            waitTime={waitTime}
            waitTimeGoal={waitTimeFeature.goal as number}
            waitTimeAlert={waitTimeFeature.alert as number}
          >
            {waitTimeString}
          </styled.ColoredWaitTimePerformance>
          <styled.WaitTimeGoalDivider>/</styled.WaitTimeGoalDivider>
          <styled.WaitTimeNumber>
            {convertToMinutesAndSecondsString(waitTimeFeature.goal as number)}
          </styled.WaitTimeNumber>
        </styled.WaitTimeWrapper>
      ) : (
        <styled.NoOrdersWrapper isMobile={isMobile}>
          <styled.NoOrdersText isMobile={isMobile}>
            No orders yet
          </styled.NoOrdersText>
        </styled.NoOrdersWrapper>
      )}
    </styled.WaitTimeWithGoalDisplayWrapper>
  );
};
