import React from "react";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import useIsMobile from "../../hooks/useIsMobile";
import * as styled from "./styled";

const LABEL_TEXT = "Filter orders by ID or name";

export default ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (newValue: string) => void;
}) => {
  const isMobile = useIsMobile();
  const onClearSearchTerm = () => {
    setSearchTerm("");
  };

  return (
    <styled.SearchBarAndLabelWrapper isMobile={isMobile}>
      <styled.SearchBarLabel htmlFor="orderSearch">
        {LABEL_TEXT}
      </styled.SearchBarLabel>
      <styled.SearchBarWrapper role="search">
        <styled.SearchIconWrapper>
          <Icon glyph="search" color="#4B4B4B" size="16" title="Search icon" />
        </styled.SearchIconWrapper>

        <styled.SearchInput
          type="text"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          role="searchbox"
          id="orderSearch"
        />
        {searchTerm && (
          <styled.ClearIconContainer>
            <Icon
              glyph="cross"
              onClick={onClearSearchTerm}
              size="16"
              title="Delete Icon"
              ariaLabel="Clear Search Button"
              role="button"
              onKeyUp={(keyEvent: KeyboardEvent) => {
                if (keyEvent.key === "Enter") {
                  onClearSearchTerm();
                }
              }}
              tabIndex={0}
            />
          </styled.ClearIconContainer>
        )}
      </styled.SearchBarWrapper>
    </styled.SearchBarAndLabelWrapper>
  );
};
