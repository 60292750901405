import React, { RefObject, useState } from "react";
import { Overlay } from "../../../LayoutComponents/styled";
import * as styled from "../../styled";
import {
  generateHeaderText,
  generateStatusDescriptionText,
} from "../../helpers";
import { OrderDetails } from "../../../../types/OrderDetailsType";
import useIsMobile from "../../../../hooks/useIsMobile";
import {
  StateDefinition,
  VerificationErrorCode,
} from "../../../../types/StateDefinitionType";
import {
  getGroupColor,
  getOrderGroup,
  OrderGroupMapping,
  shouldRenderOrderGroup,
} from "../../../../services/orderGrouping";
import { Icon } from "@bluedot-innovation/bluedot-ui";
import Button from "../../../Button";
import { OrderDetailsModalPageOverride } from "../../useOrderDetailsDisplayController";
import { VerificationPatch } from "../../../../services/api";
import { hasUsedQuickButton } from "../../../../services/utils";

export default ({
  orderDetails,
  hideOrderDetails,
  currentState,
  orderGroups,
  errorMessage,
  errorCodes,
  modalRef,
  setPageOverride,
  onUpdateOrderStatus,
  targetStatus,
  isUpdating,
}: {
  orderDetails: OrderDetails;
  hideOrderDetails: () => void;
  currentState: StateDefinition;
  orderGroups: OrderGroupMapping | undefined;
  errorMessage: string;
  errorCodes: VerificationErrorCode[];
  modalRef: RefObject<HTMLDivElement>;
  setPageOverride: (newStatus: OrderDetailsModalPageOverride) => void;
  onUpdateOrderStatus: (
    newOrderStatus: string,
    usedQuickButton: boolean,
    verificationPatch?: VerificationPatch
  ) => Promise<void>;
  targetStatus: string;
  isUpdating: boolean;
}) => {
  const isMobile = useIsMobile();
  const [selectedError, setSelectedError] = useState("");

  const hasGroup = shouldRenderOrderGroup(orderDetails, orderGroups);
  const targetGroup = getOrderGroup(orderDetails, orderGroups);

  return (
    <>
      <Overlay onClick={() => hideOrderDetails()} zIndex={4} />
      <styled.OrderDetailsWrapper
        isMobile={isMobile}
        ref={modalRef}
        role="dialog"
        aria-label="No Verification Reason Input"
      >
        <styled.OrderDetailsStatusHeader>
          <styled.HeaderText>
            {generateHeaderText(orderDetails, currentState)}
          </styled.HeaderText>
        </styled.OrderDetailsStatusHeader>
        <styled.StatusDescriptionText>
          {generateStatusDescriptionText(orderDetails, currentState)}
        </styled.StatusDescriptionText>
        <styled.OrderIdText>
          <b>Order ID:</b>
          {orderDetails.orderId}
        </styled.OrderIdText>
        {hasGroup && (
          <styled.OrderGroupText
            color={getGroupColor(targetGroup!.groupNumber)}
          >
            {targetGroup!.groupName}
          </styled.OrderGroupText>
        )}
        <styled.VerificationCodeHeader>
          Please select the reason the code can&apos;t be provided
        </styled.VerificationCodeHeader>
        <styled.ReasonButtonsWrapper>
          {errorCodes
            .filter((errorCode) => errorCode.shouldDisplay)
            .map((errorObject, index) => {
              return (
                <styled.ReasonButtonWrapper>
                  <styled.ReasonRadioButton
                    type="radio"
                    onChange={() => setSelectedError(errorObject.errorCode)}
                    className={index === 0 ? "menu-item" : ""}
                    name="no-verification-reason"
                    checked={errorObject.errorCode === selectedError}
                    id={errorObject.errorCode}
                  />
                  <styled.ReasonButtonLabel htmlFor={errorObject.errorCode}>
                    {errorObject.description}
                  </styled.ReasonButtonLabel>
                </styled.ReasonButtonWrapper>
              );
            })}
        </styled.ReasonButtonsWrapper>
        <styled.ErrorMessageWrapper hasErrorMessage={Boolean(errorMessage)}>
          {errorMessage && (
            <>
              {!isMobile && <Icon glyph="alert" size={28} />}
              <styled.UpdateErrorMessage role="status">
                {errorMessage}
              </styled.UpdateErrorMessage>
            </>
          )}
        </styled.ErrorMessageWrapper>
        <styled.ButtonWrapper>
          <Button
            onClick={() => {
              const usedQuickButton = hasUsedQuickButton(
                currentState,
                targetStatus
              );
              onUpdateOrderStatus(targetStatus, usedQuickButton, {
                op: "replace",
                path: "/verificationErrorCode",
                value: selectedError,
              });
            }}
            disabled={!selectedError || isUpdating}
            className="menu-item"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                const usedQuickButton = hasUsedQuickButton(
                  currentState,
                  targetStatus
                );
                onUpdateOrderStatus(targetStatus, usedQuickButton, {
                  op: "replace",
                  path: "/verificationErrorCode",
                  value: selectedError,
                });
              }
            }}
          >
            Submit Reason
          </Button>
        </styled.ButtonWrapper>
        <styled.ButtonWrapper>
          <styled.BackButton
            onClick={() => setPageOverride("VERIFICATION_CODE_INPUT")}
            className="menu-item"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                setPageOverride("VERIFICATION_CODE_INPUT");
              }
            }}
          >
            Back
          </styled.BackButton>
        </styled.ButtonWrapper>
      </styled.OrderDetailsWrapper>
    </>
  );
};
