import { myTheme } from "./theme";
import { OrderDetails } from "../types/OrderDetailsType";
import {
  AlertTimer,
  FeatureToggles,
  StateDefinition,
} from "../types/StateDefinitionType";
import createHash from "create-hash";

export const wait = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(undefined), milliseconds);
  });

export const isValidUrlCode = (urlCode: string) => {
  const regex = /^[1-9A-NP-Z]{7}$/;
  return regex.test(urlCode);
};

export const getAlertTimer = (
  order: OrderDetails,
  targetState: StateDefinition | undefined,
  stateFeatures: FeatureToggles | undefined
): AlertTimer | undefined => {
  if (!targetState) {
    return;
  }
  // Handle consolidation alerts in OnTheWay states
  if (
    targetState?.notificationTypes?.includes("consolidation") &&
    order.hasNotified?.includes("consolidation")
  ) {
    return {
      time: 0,
      color: myTheme.colors.PRIMARY_ACCENT,
      showSuccessMessage: Boolean(targetState.displayState?.showSuccessMessage),
    };
  }
  const elapsedTime =
    new Date().getTime() -
    new Date(order.waitTimeStart || order.lastTransitionTime).getTime();
  const elapsedSeconds = elapsedTime / 1000;
  if (targetState.displayState?.alertTimers?.length) {
    const alertTimers = targetState.displayState.alertTimers;
    const targetAlertTimer =
      alertTimers.find(
        (alert, index) =>
          index < alertTimers.length - 1 &&
          alertTimers[index + 1].time >= elapsedSeconds
      ) || alertTimers[alertTimers.length - 1];
    return targetAlertTimer;
  }

  // Arrived is a special state that alerts based on the wait time goals if present
  // There can now be multiple start states that share an alert timer start time based on waitTimeStart
  if (
    order.status === "Arrived" ||
    (Array.isArray(stateFeatures?.waitTime?.startState) &&
      stateFeatures?.waitTime?.startState.includes(order.status))
  ) {
    const targetWaitTime = stateFeatures?.waitTime;
    if (!targetWaitTime?.alert || elapsedSeconds < targetWaitTime.alert) {
      // This is the default alert state where it just flashes green on arrival
      return {
        time: 0,
        color: myTheme.colors.SUCCESS,
        showSuccessMessage: Boolean(
          targetState.displayState?.showSuccessMessage
        ),
      };
    }
    if (targetWaitTime.goal && elapsedSeconds < targetWaitTime.goal) {
      return {
        time: targetWaitTime!.alert,
        color: myTheme.colors.WARNING,
        showSuccessMessage: false,
      };
    }
    return {
      time: targetWaitTime.goal as number,
      color: myTheme.colors.ERROR,
      showSuccessMessage: false,
    };
  }
};

/*
  Below returns minutes and seconds in mm:SS format.
  If greater than 99:59 return 99:59 as maximum
 */
export const convertToMinutesAndSecondsString = (seconds: number) => {
  const minuteComponent = Math.floor(seconds / 60);
  const secondComponent = Math.floor(seconds) % 60;
  return minuteComponent > 99
    ? `99:59`
    : `${minuteComponent}:${
        secondComponent < 10 ? `0${secondComponent}` : secondComponent
      }`;
};

export const getScreenId = (authToken: string) => {
  const hash = createHash("sha256");
  const screenId = hash.update(authToken).digest("base64");
  return screenId;
};

export const hasUsedQuickButton = (
  currentState: StateDefinition,
  targetStatus: string
) => {
  return Boolean(
    currentState.displayState?.quickButtons?.find(
      (quickButton) => quickButton.action === targetStatus
    )
  );
};
