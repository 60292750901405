import Cookies from "js-cookie";
import { PersistenceKey } from "../types/PersistenceKeyTypes";
import { Region } from "../types/RegionType";
import { createPersistenceSyncError } from "./errorFactory";

const REGISTRATION_CODE_KEY = "registrationCode";
const AUTH_TOKEN_KEY = "authToken";
const REGION_KEY = "region";
const PROJECT_ID_KEY = "ssoProjectId";
const IDENTITY_PROVIDER_KEY = "ssoIdentityProvider";
const SSO_LOGIN_PAGE_URL = "ssoLoginPage";
const GET_ORDERS_RETRIES = "getOrdersRetries";
const UPDATE_ORDER_STATUS_RETRIES = "updateOrderStatusRetries";
const ACKNOWLEDGE_ORDERS_RETRIES = "acknowledgeOrdersRetries";
const ALERTED_CONSOLIDATION_ORDERS = "alertedConsolidationOrders";

const setCookieAndStorageValue = (key: PersistenceKey, value: string): void => {
  Cookies.set(key, value, { expires: 365 });
  localStorage.setItem(key, value);
};

const reSyncCookiesAndStorage = (): void => {
  const keys: PersistenceKey[] = [
    REGISTRATION_CODE_KEY,
    AUTH_TOKEN_KEY,
    REGION_KEY,
    PROJECT_ID_KEY,
    IDENTITY_PROVIDER_KEY,
    SSO_LOGIN_PAGE_URL,
    GET_ORDERS_RETRIES,
    UPDATE_ORDER_STATUS_RETRIES,
    ACKNOWLEDGE_ORDERS_RETRIES,
    ALERTED_CONSOLIDATION_ORDERS,
  ];
  keys.forEach((storageKey) => {
    const cookieValue = Cookies.get(storageKey);
    const localStorageValue = localStorage.getItem(storageKey);
    const value = localStorageValue || cookieValue;
    if (value) {
      setCookieAndStorageValue(storageKey, value);
    }
  });
};

export const getLocalStorageValueFromKey = (
  key: PersistenceKey
): string | null => {
  const cookieValue = Cookies.get(key);
  const localStorageValue = localStorage.getItem(key);
  if (cookieValue && localStorageValue && localStorageValue !== cookieValue) {
    console.log(
      createPersistenceSyncError(key, cookieValue, localStorageValue)
    );
  }
  if (!cookieValue && !localStorageValue) {
    return null;
  }
  if (cookieValue !== localStorageValue) {
    reSyncCookiesAndStorage();
  }
  return localStorage.getItem(key);
};

export const getAlertedConsolidationOrders = () => {
  const stringValue = getLocalStorageValueFromKey(ALERTED_CONSOLIDATION_ORDERS);
  if (!stringValue) {
    return [];
  }
  const alertedConsolidationOrders = stringValue?.split(",");
  return alertedConsolidationOrders;
};

const MAX_ALERTED_ORDERS_TO_STORE = 100;
export const setAlertedConsolidationOrders = (
  alertedOrdersHelloIds: string[]
) => {
  let ordersToStore = alertedOrdersHelloIds;
  if (alertedOrdersHelloIds.length > MAX_ALERTED_ORDERS_TO_STORE) {
    ordersToStore = alertedOrdersHelloIds.slice(-MAX_ALERTED_ORDERS_TO_STORE);
  }
  const idString = ordersToStore.toString();
  setCookieAndStorageValue(ALERTED_CONSOLIDATION_ORDERS, idString);
};

export const getRegistrationCode = (): any => {
  return getLocalStorageValueFromKey(REGISTRATION_CODE_KEY);
};

export const getAuthToken = (): any => {
  return getLocalStorageValueFromKey(AUTH_TOKEN_KEY);
};

export const storeRegistrationCode = (newCodeValue: string): void => {
  setCookieAndStorageValue(REGISTRATION_CODE_KEY, newCodeValue);
};

export const storeAuthToken = (newTokenValue: string): void => {
  setCookieAndStorageValue(AUTH_TOKEN_KEY, newTokenValue);
};

export const getRegion = (): any => {
  return getLocalStorageValueFromKey(REGION_KEY);
};

export const setRegion = (regionValue: Region): void => {
  setCookieAndStorageValue(REGION_KEY, regionValue);
};

export const setSsoDetails = (projectId: string, identityProvider: string) => {
  setCookieAndStorageValue(PROJECT_ID_KEY, projectId);
  setCookieAndStorageValue(IDENTITY_PROVIDER_KEY, identityProvider);
};

export const setSsoLink = (loginUrl: string) => {
  setCookieAndStorageValue(SSO_LOGIN_PAGE_URL, loginUrl);
};

export const getSsoProjectId = (): any => {
  return getLocalStorageValueFromKey(PROJECT_ID_KEY);
};

export const getSsoLoginPage = (): any => {
  return getLocalStorageValueFromKey(SSO_LOGIN_PAGE_URL);
};

export const setGetOrdersRetries = (retries: number) => {
  localStorage.setItem(GET_ORDERS_RETRIES, retries.toString());
};

export const setUpdateOrderStatusRetries = (retries: number) => {
  localStorage.setItem(UPDATE_ORDER_STATUS_RETRIES, retries.toString());
};

export const setAcknowledgeOrdersRetries = (retries: number) => {
  localStorage.setItem(ACKNOWLEDGE_ORDERS_RETRIES, retries.toString());
};

export const getGetOrderRetries = (): number => {
  const retries = localStorage.getItem(GET_ORDERS_RETRIES);
  if (!retries) {
    return 0;
  } else {
    return Number.parseInt(retries);
  }
};

export const getUpdateOrderStatusRetries = (): number => {
  const retries = localStorage.getItem(UPDATE_ORDER_STATUS_RETRIES);
  if (!retries) {
    return 0;
  } else {
    return Number.parseInt(retries);
  }
};

export const getAcknowledgeOrderRetries = (): number => {
  const retries = localStorage.getItem(ACKNOWLEDGE_ORDERS_RETRIES);
  if (!retries) {
    return 0;
  } else {
    return Number.parseInt(retries);
  }
};

export const deleteSsoLoginUrl = (): void => {
  localStorage.removeItem(SSO_LOGIN_PAGE_URL);
  Cookies.remove(SSO_LOGIN_PAGE_URL);
};

export const deleteRegisteredValues = (): void => {
  localStorage.removeItem(REGISTRATION_CODE_KEY);
  localStorage.removeItem(AUTH_TOKEN_KEY);
  Cookies.remove(REGISTRATION_CODE_KEY);
  Cookies.remove(AUTH_TOKEN_KEY);
};

export const deleteAllValues = (): void => {
  localStorage.removeItem(REGISTRATION_CODE_KEY);
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REGION_KEY);
  localStorage.removeItem(PROJECT_ID_KEY);
  localStorage.removeItem(IDENTITY_PROVIDER_KEY);
  localStorage.removeItem(GET_ORDERS_RETRIES);
  localStorage.removeItem(UPDATE_ORDER_STATUS_RETRIES);
  localStorage.removeItem(ACKNOWLEDGE_ORDERS_RETRIES);
  Cookies.remove(REGISTRATION_CODE_KEY);
  Cookies.remove(AUTH_TOKEN_KEY);
  Cookies.remove(REGION_KEY);
  Cookies.remove(PROJECT_ID_KEY);
  Cookies.remove(IDENTITY_PROVIDER_KEY);
  Cookies.remove(GET_ORDERS_RETRIES);
  Cookies.remove(UPDATE_ORDER_STATUS_RETRIES);
  Cookies.remove(ACKNOWLEDGE_ORDERS_RETRIES);
};
